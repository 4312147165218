<template>
    <div class="container">
        <div class="row justify-content-center h-100">
            <div class="col-lg-6">
                <div class="card  shadow">
                    <div class="card-header card-header-primary ">
                        <h4 class="card-title">{{$t('packages_report')}}</h4>
                    </div>
                    <div class="card-body ">
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group mb-2">
                                            <label for="">من تاريخ</label>
                                            <input v-model="packagesFromDate" type="date"  class="form-control" id="packages_from_date">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group mb-2">
                                             <label for="">الي تاريخ</label>
                                            <input  type="date"  v-model="packagesToDate" placeholder="الي تاريخ" class="form-control" id="packages_to_date">
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <button type="button" @click="createPackagesReport()" class="btn btn-vanex mb-2">إنشاء التقرير</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card  shadow">
                    <div class="card-header card-header-primary ">
                        <h4 class="card-title">{{$t('collects_reports')}}</h4>
                    </div>
                    <div class="card-body ">
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group mb-2">
                                            <label for="">من تاريخ</label>
                                            <input v-model="collectsFromDate" type="date"  class="form-control" id="collects_from_date">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group mb-2">
                                            <label for="">الي تاريخ</label>
                                            <input v-model="collectsToDate" type="date"  class="form-control" id="collects_to_date">
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <button type="button" @click="createCollectsReport()" class="btn btn-vanex mb-2">إنشاء التقرير</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6" v-if="user && user.type ==1">
                <div class="card  shadow">
                    <div class="card-header card-header-primary ">
                        <h4 class="card-title">{{$t('settelments_reports')}}</h4>
                    </div>
                    <div class="card-body ">
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group mb-2">
                                           
                                            <label for="">من تاريخ</label>
                                            <input v-model="fromDate" type="date"  class="form-control" id="from_date">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group mb-2">
                                            
                                            <label for="">الي تاريخ</label>
                                            <input v-model="toDate" type="date"  class="form-control" id="to_date">
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <button type="button" @click="createSettelmentsReport()" class="btn btn-vanex mb-2">إنشاء التقرير</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6"  v-if="user && user.type ==1">
                <div class="card  shadow">
                    <div class="card-header card-header-primary ">
                        <h4 class="card-title">{{$t('transactions_reports')}}</h4>
                    </div>
                    <div class="card-body ">
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group mb-2">
                                            
                                            <label for="">من تاريخ</label>
                                            <input v-model="transactionsFromDate" type="date"  class="form-control" id="transactions_from_date">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group mb-2">
                                            <label for="">الي تاريخ</label>
                                            <input v-model="transactionsToDate" type="date"  class="form-control" id="transactions_to_date">
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <button type="button" @click="createTransactionsReport()" class="btn btn-vanex mb-2">إنشاء التقرير</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <search-form />
            </div>
            
        </div>
    </div>
</template>

<script>
    import SearchForm from '../../components/SearchForm.vue'
    import moment from 'moment'
    export default {
        components: { SearchForm },
        data(){
            return {
                fromDate : null,
                toDate : null,
                user : null,
                transactionsFromDate : null,
                transactionsToDate : null,
                packagesFromDate : null,
                packagesToDate : null,
                collectsFromDate : null,
                collectsToDate : null,
            }
        },
        methods:{
            createSettelmentsReport(){
                
                if (!this.fromDate ) {
                    this.$toasted.show('لا يجب ترك حقل من تاريخ  فارغاً', {
                        type: "error",
                    });
                    return false;
                }
                if (!this.toDate ) {
                    this.$toasted.show('لا يجب ترك حقل الي تاريخ فارغاً', {
                        type: "error",
                    });
                    return false;
                }
                this.$router.push({path:`/settelments-report?from-date=${moment(this.fromDate).format('YYYY-MM-DD')}&to-date=${moment(this.toDate).format('YYYY-MM-DD')}`})
            },
            createTransactionsReport(){
                
                if (!this.transactionsFromDate ) {
                    this.$toasted.show('لا يجب ترك حقل من تاريخ  فارغاً', {
                        type: "error",
                    });
                    return false;
                }
                if (!this.transactionsToDate ) {
                    this.$toasted.show('لا يجب ترك حقل الي تاريخ فارغاً', {
                        type: "error",
                    });
                    return false;
                }
                this.$router.push({path:`/transactions-report?from-date=${moment(this.transactionsFromDate).format('YYYY-MM-DD')}&to-date=${moment(this.transactionsToDate).format('YYYY-MM-DD')}`})
            },
            createPackagesReport(){
                if (!this.packagesFromDate ) {
                    this.$toasted.show('لا يجب ترك حقل من تاريخ  فارغاً', {
                        type: "error",
                    });
                    return false;
                }
                if (!this.packagesToDate ) {
                    this.$toasted.show('لا يجب ترك حقل الي تاريخ فارغاً', {
                        type: "error",
                    });
                    return false;
                }
                this.$router.push({path:`/packages-report?from-date=${moment(this.packagesFromDate).format('YYYY-MM-DD')}&to-date=${moment(this.packagesToDate).format('YYYY-MM-DD')}`})
            },
            createCollectsReport(){
                if (!this.collectsFromDate ) {
                    this.$toasted.show('لا يجب ترك حقل من تاريخ  فارغاً', {
                        type: "error",
                    });
                    return false;
                }
                if (!this.collectsToDate ) {
                    this.$toasted.show('لا يجب ترك حقل الي تاريخ فارغاً', {
                        type: "error",
                    });
                    return false;
                }
                this.$router.push({path:`/collects-report?from-date=${moment(this.collectsFromDate).format('YYYY-MM-DD')}&to-date=${moment(this.collectsToDate).format('YYYY-MM-DD')}`})
            },
        },
        async mounted() {
            let user = this.$store.state.auth.user;
            this.user = user
        }

    }
</script>

<style>

</style>