import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vmodal from 'vue-js-modal'
import VueHtmlToPaper from 'vue-html-to-paper';
import VueSilentbox from 'vue-silentbox';
import VueCarousel from 'vue-carousel';
import * as VueGoogleMaps from 'vue2-google-maps'
// modal
Vue.use(vmodal)
// modal

// toast lib
import Toasted from 'vue-toasted';
Vue.use(Toasted, { position: 'top-center', duration: 800 })

// general components
import VanexLoading from './components/vanex-loading.vue'
import ListLoading from './components/listLoading.vue'
import CustomAlert from './components/alert.vue'
import CustomDate from './components/public/date.vue'
import EmptyShipments from './components/emptyShipmentMessage.vue'
import getStatus from './components/getStatus.vue'
import getCollectsStatus from './components/getCollectsStatus.vue'
import BlankModal from './components/modals/blank.vue'
import PageLoading from './components/public/PageLoading.vue'
import MapSelector from './components/public/MapSelector.vue'
import 'vue-select/dist/vue-select.css';
import VueQr from 'vue-qr'


// leaflet map 
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
// leaflet map 

/* GOOGLE MAPS INIT */
Vue.use(VueGoogleMaps, {
  load: {
    libraries: 'places,drawing,visualization',
    language: 'ar',
    region: 'LY',
    key: 'AIzaSyCEuCV_HrhBwnVGFWRcd_T5w529RrUvzoc',
  },
}
);
/* GOOGLE MAPS INIT */


Vue.component('vanex-loading', VanexLoading);
Vue.component('vue-qr', VueQr);
Vue.component('vanex-list-loading', ListLoading);
Vue.component('alert', CustomAlert);
Vue.component('empty-shipments', EmptyShipments);
Vue.component('get-status', getStatus);
Vue.component('custom-date', CustomDate);
Vue.component('get-collects-status', getCollectsStatus);
Vue.component('blank-modal', BlankModal);
Vue.component('page-loading', PageLoading);
Vue.component('map-selector', MapSelector);
// include default layout
import Default from './layouts/default.vue'
import Blank from './layouts/blank.vue'
import PrintLayout from './layouts/PrintLayout.vue'
Vue.component('default-layout', Default)
Vue.component('blank-layout', Blank)
Vue.component('print-layout', PrintLayout)

// vue search select
import vSelect from 'vue-select';
Vue.component('v-select', vSelect);

// data table
import VueGoodTablePlugin from 'vue-good-table';

// import the styles
import 'vue-good-table/dist/vue-good-table.css'

import i18n from './i18n'

Vue.use(VueGoodTablePlugin);


/* Light Box */
Vue.use(VueSilentbox)
/* Light Box */


/* Carousel */
Vue.use(VueCarousel);
/* Carousel */


/* print config */


const options = {
  name: '_blank',
  specs: [
    'fullscreen=no',
    'titlebar=1',
    'scrollbars=no'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://fonts.gstatic.com',
    "https://fonts.googleapis.com/css2?family=Cairo:wght@300&display=swap"
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title

}

Vue.use(VueHtmlToPaper, options);

/* print config */












Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
