<template>
    <div class="row justify-content-center">
        <div class="col-lg-12 col-md-12">
            <div class="card shadow">
                <div class="card-body">
                    <div class="text-center">
                        <img src="@/assets/images/icons-v2/success.svg" height="250" width="400" />
                    <h4 class="text-vanex text-bold ">{{$t('success_create_new_store')}}</h4>
                    <p class="text-muted ">{{$t('success_create_new_store_details')}}</p>
                    <router-link to="/profile"><button class="btn btn-vanex">{{$t('back_to_profile')}}</button></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
               isNew:false
            };
        },
        methods:{
            refreshPage(){
                var params = [
                    "is-reload=1"
                ];
                window.location.href = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + params.join('&');
            }
        },
        created(){
            if(!this.$route.query['is-reload']){
                this.refreshPage()
            }
        }
    };
</script>

<style></style>