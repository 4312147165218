<template>
  <div>
    <slot />
  </div>
</template>

<style lang="stylus" scoped>
@import '../assets/css/material-dashboard.min.css';
@import '../assets/css/material-dashboard-rtl.css';
</style>
<script>
export default {
  name: "blank",
};
</script>
