<template>
  <div class="">
    <div class="row">
      <div class="col-12 text-center loading-container">
        <img
          src="@/assets/images/loading.gif"
          alt="VANEX"
          :width="size !== null ? size : 30"
        />
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.loading-container{
	transform : scale(0.7);
}
</style>

<script>
export default {
  props: ["size"],
  name: "loading",
};
</script>
