<template>
  <div>
    <br/>
    <!-- <stories/> -->
    <!-- statistics -->
    <Statistics />
    
    <invitations-alert />
    <default-address />
    <!-- statistics -->
    <!-- statistics 2 -->
    <PackageStatistics />
    <!-- statistics 2 -->
   
    <div class="row">
      <!-- tables -->
      <div class="col-lg-6 col-md-12">
        <div class="row">
          <!--  shipments tables -->
          <StoreNewPackages />

          <CantDeliverPackages />

          <!--  end shipments table -->
        </div>
      </div>
      <!-- end tables -->
      
      <!-- shipments charts -->
      <MainChart />
      <!-- shipments charts -->
      
    </div>

    <div class="row">
      <!-- shipment Search -->
      <div class="col-lg-12 col-md-12">
        <SearchForm />
      </div>
      <!-- end shipment Search -->
    </div>
  </div>
</template>

<script>
import SearchForm from "../components/SearchForm";
import invitationsAlert from "../components/invitationsAlert";
import Statistics from "../components/statistics";
import PackageStatistics from "../components/PackageStatistics";
import StoreNewPackages from "../components/StoreNewPackages";
import CantDeliverPackages from "../components/CantDeliverPackages";
import MainChart from "../components/mainChart";
import Stories from './Stories.vue';
import DefaultAddress from '../components/DefaultAddress.vue';
export default {
  components: {
    invitationsAlert,
    SearchForm,
    Statistics,
    PackageStatistics,
    StoreNewPackages,
    CantDeliverPackages,
    MainChart,
    Stories,
    DefaultAddress,
  },
};
</script>