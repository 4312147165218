<template>
    <div class="row justify-content-center">
        <div class="col-lg-12 col-md-12">
            <div class="card shadow">
                <div class="card-header card-header-primary">
                 <h4 class="card-title">{{$t('create_new_store')}}</h4>
                </div>
                <div class="card-body">
                  <form @submit="createStore" novalidate>
                    <p class="text-muted text-right mt-2">{{$t('create_new_store_details')}}</p>

                    <div class="row">
                        <div class="col-lg-6"><Input :title="$t('name')" v-model="name" :error-message="errors" name="name" type="text" /></div>
                        <div class="col-lg-6"><Input :title="$t('cont_per_title')" v-model="cont_per_title" :error-message="errors" name="cont_per_title" type="text" /></div>
                        <div class="col-lg-4"><Input :title="$t('phone')" v-model="phone" :error-message="errors" name="phone" type="number" /></div>
                        <div class="col-lg-4"><Input :title="$t('address')" v-model="address" :error-message="errors" name="address" type="text" /></div>
                        <div class="col-lg-4"><Input :title="$t('website')" v-model="website" :error-message="errors" name="website" type="text" /></div>
                        <div class="col-lg-12"><Input :title="$t('notes')" v-model="notes" :error-message="errors" name="notes" type="text" /></div>
                    </div>
                    <button class="btn btn-vanex" type="submit">
                        <Spinner v-if="isLoading" type="2" size="20" />
                        <span class="text-white" v-if="!isLoading">{{$t('create')}}</span>
                    </button>
                  </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Input from "../../components/public/Input.vue";
    import Spinner from "../../components/public/Spinner.vue";
import { cryptText, decryptText } from '../../modules/crypt';
    import { instance } from "../../modules/instance";

    export default {
        components: { Input, Spinner },
        data() {
            return {
                isLoading: false,
                name: '',
                cont_per_title: '',
                phone: '',
                address: '',
                website: '',
                notes: '',
                errors: {
                    name: '',
                    cont_per_title: '',
                    phone: '',
                    address: '',
                    website: '',
                    notes: '',
                }
            };
        },
        methods: {
            createStore(e) {
                e.preventDefault()
                let errors = 0;
                if (!this.name) {
                    errors++
                    this.errors['name'] = this.$t('required', { title: this.$t('name') })
                } else {
                    this.errors['name'] = ""
                }
                if (!this.cont_per_title) {
                    errors++
                    this.errors['cont_per_title'] = this.$t('required', { title: this.$t('cont_per_title') })
                } else {
                    this.errors['cont_per_title'] = ""
                }
                if (!this.phone) {
                    errors++
                    this.errors['phone'] = this.$t('required', { title: this.$t('phone') })
                } else {
                    this.errors['phone'] = ""
                }
                if (!this.address) {
                    errors++
                    this.errors['address'] = this.$t('required', { title: this.$t('address') })
                } else {
                    this.errors['address'] = ""
                }
                if (!this.website) {
                    errors++
                    this.errors['website'] = this.$t('required', { title: this.$t('website') })
                } else {
                    this.errors['website'] = ""
                }
                if (errors <= 0) {
                    let _this = this;
                    _this.isLoading = true;
                    instance
                        .post("/create-store", {
                            name: _this.name,
                            cont_per_title: _this.cont_per_title,
                            phone: _this.phone,
                            address: _this.address,
                            website: _this.website,
                        })
                        .then(async function  (response) {
                           
                            if(response.data.status_code == 201 && !response.data.errors){
                                let user = JSON.parse(decryptText(await localStorage.getItem("user")));
                                await localStorage.removeItem("user");
                                console.log(user);
                                if(user){
                                 user.type = 1;  
                                 await localStorage.setItem("user",cryptText(JSON.stringify(user)));
                                }
                                
                                _this.$router.push({path:"/success-create-store"})
                            }
                        })
                        .catch(function (error) {
                            if (error.response && error.response.data && error.response.data.errors) {

                                for (const [key, value] of Object.entries(error.response.data.errors)) {
                                    _this.errors[key] = value[0]
                                }

                                _this.needResendOtpCode = (error.response.data.resend_otp_code) ? true : false
                            }
                        })
                        .then(function () {
                             
                            _this.isLoading = false;
                        });

                }
            }
        },
        created() { },
    };
</script>

<style></style>