var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card shadow"},[_c('div',{staticClass:"card-header card-header-primary"},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t("ban_list")))])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row justify-content-center"},[(_vm.rows && !_vm.isLoading)?_c('div',{staticClass:"col-lg-12 col-md-12"},[_c('div',{staticClass:"table-responsive"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"rtl":true,"styleClass":"vgt-table striped","pagination-options":{
              enabled: true,
              mode: 'pages',
              perPageDropdownEnabled: false,
              perPage: 5,
              position: 'bottom',
              perPageDropdown: [3, 7, 9],
              dropdownAllowAll: true,
              setCurrentPage: 1,
              nextLabel: 'التالي',
              prevLabel: 'السابق',
              rowsPerPageLabel: 'عدد الصفوف في الصفحة',
              ofLabel: 'من',
              pageLabel: 'صفحة',
              allLabel: 'الكل',
            },"search-options":{
              enabled: false,
              placeholder: 'ابحث من هنا...',
            },"sort-options":{
              enabled: true,
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_vm._t("default",null,{"props":props}),(props.column.field == 'status')?_c('div',[(props.row.status == 'pending')?_c('span',[_vm._v("بإنتظار الموافقة")]):_vm._e(),(props.row.status == 'rejected')?_c('span',{staticClass:"text-danger"},[_vm._v("مرفوض")]):_vm._e(),(props.row.status == 'approved')?_c('span',[_vm._v("تمت الموافقة")]):_vm._e()]):(props.column.field == 'notes')?_c('div',[_c('small',[_vm._v(_vm._s(props.row.notes))])]):(props.column.field == 'created_at')?_c('div',[_c('custom-date',{attrs:{"date":props.row.created_at}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,true)},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticClass:"form-inline"},[_c('div',{staticClass:"form-group"},[_c('router-link',{attrs:{"to":"/ban/new"}},[_c('button',{staticClass:"btn btn-vanex btn-sm btn-ticket"},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('new_ban_customer')))])])],1)])])])],1)]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"col-12"},[_c('vanex-list-loading',{attrs:{"rowcount":10}})],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }