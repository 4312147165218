import Home from "../views/index.vue";
import AddressesHome from "../views/addresses/index.vue";
import AddressNew from "../views/addresses/new.vue";
import editAddress from "../views/addresses/edit.vue";
import Price from "../views/prices.vue";
import Terms from "../views/terms.vue";
import Hints from "../views/cms/hints.vue";
import ShowHint from "../views/cms/hint_show.vue";
import Services from "../views/cms/services.vue";
import userManual from "../views/cms/manual.vue";
import ticketsHome from "../views/support/tickets/index.vue";
import ticketNew from "../views/support/tickets/new.vue";
import PageNotFound from "../views/PageNotFound.vue";
import ticketsShow from "../views/support/tickets/show.vue";
import ShowService from "../views/cms/services_show.vue";
import Contact from "../views/contact.vue";
import Profile from "../views/profile.vue";
import DeliveryCalculation from "../views/DeliveryCalculation.vue";
import AboutUs from "../views/About.vue";
import createNewStore from "../views/profile/create_new_store.vue";
import successCreateStore from "../views/profile/success_create_store.vue";
import successAcceptStore from "../views/profile/success_accept_store.vue";
import ChangePassword from "../views/change_password.vue";
import PackagesShow from '../views/packages/index.vue';
import PackagesNew from '../views/packages/new.vue';
import PackagesEdit from '../views/packages/edit.vue';
import PackagesSuccess from '../views/packages/success.vue';
import PackageShow from '../views/packages/show.vue';
import NewCollect from "../views/collects/new.vue";
import EditCollect from "../views/collects/edit.vue";
import ShowCollect from "../views/collects/show.vue";
import Collects from "../views/collects/index.vue";
import SupportCenter from "../views/support/index.vue";
import UnderSettlement from "../views/accounts/under_settlement.vue";
import CustomerStatement from "../views/accounts/customer_statement.vue";
import StoreStatements from "../views/accounts/store_settelments.vue";
import BanIndex from "../views/ban/index.vue";
import Invitations from "../views/invitations.vue";
import BanNew from "../views/ban/new.vue";
import Reports from "../views/reports/index.vue";
import SettlementReports from "../views/reports/settelments.vue";
import transactionsReports from "../views/reports/transactions.vue";
import packagesReports from "../views/reports/packages.vue";
import collectsReports from "../views/reports/collects.vue";
import AuthMiddleWare from './middleware/auth';
import { logout } from "../modules/helpers";


const dashBoardMeta = { layout: 'default', middleware: [AuthMiddleWare] };
export const dashboardRouter = [
  {
    path: "/",
    name: "الصفحة الرئيسية",
    component: Home,
    meta: dashBoardMeta
  },

  {
    path: "/prices",
    name: "أسعار الشحن",
    component: Price,
    meta: dashBoardMeta
  },
  {
    path: "/reports",
    name: "التقارير",
    component: Reports,
    meta: dashBoardMeta
  },
  {
    path: "/settelments-report",
    name: "تقرير التسويات المالية",
    component: SettlementReports,
    meta: dashBoardMeta
  },
  {
    path: "/transactions-report",
    name: "تقرير التسويات المالية",
    component: transactionsReports,
    meta: dashBoardMeta
  },
  {
    path: "/packages-report",
    name: "تقرير الشحنات",
    component: packagesReports,
    meta: dashBoardMeta
  },
  {
    path: "/collects-report",
    name: "تقرير طلبات التجميع",
    component: collectsReports,
    meta: dashBoardMeta
  },
  {
    path: "/invitations",
    name: "الدعوات",
    component: Invitations,
    meta: dashBoardMeta
  },
  {
    path: "/terms",
    name: "المسؤوليات",
    component: Terms,
    meta: dashBoardMeta
  },
  {
    path: "/support-center",
    name: "مركز الدعم",
    component: SupportCenter,
    meta: dashBoardMeta
  },
  {
    path: "/hints",
    name: "التلميحات",
    component: Hints,
    meta: dashBoardMeta
  },
  {
    path: "/hints/:id/show",
    name: "عرض التلميحة",
    component: ShowHint,
    meta: dashBoardMeta
  },
  {
    path: "/services",
    name: "الخدمات",
    component: Services,
    meta: dashBoardMeta
  },
  {
    path: "/services/:id/show",
    name: "عرض الخدمة",
    component: ShowService,
    meta: dashBoardMeta
  },
  {
    path: "/contact-us",
    name: "إتصل بنا",
    component: Contact,
    meta: dashBoardMeta
  },
  /*  packages routers */
  {
    path: "/packages",
    name: "عرض الشحنات",
    component: PackagesShow,
    meta: dashBoardMeta
  },
  {
    path: "/packages/new",
    name: "إضافة شحنة جديدة",
    component: PackagesNew,
    meta: dashBoardMeta
  },
  {
    path: "/packages/:code/edit",
    name: "تعديل بيانات الشحنة",
    component: PackagesEdit,
    meta: dashBoardMeta
  },
  {
    path: "/packages/:code/success",
    name: "تم إضافة الشحنة بنجاح",
    component: PackagesSuccess,
    meta: dashBoardMeta
  },
  {
    path: "/packages/:code/show",
    name: "عرض بيانات الشحنة",
    component: PackageShow,
    meta: dashBoardMeta
  },

  {
    path: "/delivery-calculation",
    name: "الحاسبة البريدية",
    component: DeliveryCalculation,
    meta: dashBoardMeta
  },
  {
    path: "/about-us",
    name: "عن الشركة",
    component: AboutUs,
    meta: dashBoardMeta
  },
  {
    path: "/ban",
    name: "حظر الزبائن",
    component: BanIndex,
    meta: dashBoardMeta
  },
  {
    path: "/ban/new",
    name: "حظر زبون جديد",
    component: BanNew,
    meta: dashBoardMeta
  },
  /*  packages routers */


  /*  shipments routers */
  // {
  //   path: "/shipments/new",
  //   name: "شحنة جديدة",
  //   component: NewShipment,
  //   meta: dashBoardMeta
  // },
  // {
  //   path: "/shipments/:id/edit",
  //   name: "تعديل شحنة",
  //   component: EditShipment,
  //   meta: dashBoardMeta
  // },
  // {
  //   path: "/shipments/in-progress",
  //   name: "الشحنات - قيد الإجراء",
  //   component: InProgressShipments,
  //   meta: dashBoardMeta
  // },
  // {
  //   path: "/shipments/on-track",
  //   name: "الشحنات - بالمسار الصحيح",
  //   component: OnTrackShipments,
  //   meta: dashBoardMeta
  // },
  // {
  //   path: "/shipments/pending-journey",
  //   name: "الشحنات - المعتذر توصيلها",
  //   component: PendingJourney,
  //   meta: dashBoardMeta
  // },
  // {
  //   path: "/shipments/pending-return",
  //   name: "الشحنات - قيد الإستيرداد",
  //   component: PendingReturn,
  //   meta: dashBoardMeta
  // },
  // {
  //   path: "/shipments/returned-requests",
  //   name: "الشحنات - المستردة",
  //   component: ReturnedRequests,
  //   meta: dashBoardMeta
  // },
  /*  end shipments routers */

  /* collects routers */
  {
    path: "/collects/new",
    name: "طلب تجميع جديد",
    component: NewCollect,
    meta: dashBoardMeta
  },
  {
    path: "/collects/:id/edit",
    name: "تعديل بيانات طلب التجميع",
    component: EditCollect,
    meta: dashBoardMeta
  },
  {
    path: "/collects/:id/show",
    name: "عرض بيانات طلب التجميع",
    component: ShowCollect,
    meta: dashBoardMeta
  },
  {
    path: "/collects",
    name: " طلبات التجميع",
    component: Collects,
    meta: dashBoardMeta
  },
  /* end collects routers */
  /* accounts */
  {
    path: "/accounts/under-settlement",
    name: "الشحنات - تحت التسوية",
    component: UnderSettlement,
    meta: dashBoardMeta
  },
  {
    path: "/accounts/transactions",
    name: "كشف حساب المتجر",
    component: CustomerStatement,
    meta: dashBoardMeta
  },
  {
    path: "/accounts/store-settelments",
    name: "التسويات المالية",
    component: StoreStatements,
    meta: dashBoardMeta
  },
  /* end accounts */
  /* Profile routers */
  {
    path: "/profile",
    name: "الملف الشخصي",
    component: Profile,
    meta: dashBoardMeta
  },
  {
    path: "/create-store",
    name: "إضافة متجر جديد",
    component: createNewStore,
    meta: dashBoardMeta
  },
  {
    path: "/success-create-store",
    name: "تم إضافة المتجر بنجاح",
    component: successCreateStore,
    meta: dashBoardMeta
  },
  {
    path: "/success-accept-store",
    name: "تم الإنضمام الي  المتجر بنجاح",
    component: successAcceptStore,
    meta: dashBoardMeta
  },
  {
    path: "/change-password",
    name: "تغيير كلمة المرور",
    component: ChangePassword,
    meta: dashBoardMeta
  },
  /* end Profile routers */
  /* start addresses routers */
  {
    path: "/addresses",
    name: "قائمة العناوين",
    component: AddressesHome,
    meta: dashBoardMeta
  },
  {
    path: "/addresses/new",
    name: "إضافة عنوان بريدي جديد",
    component: AddressNew,
    meta: dashBoardMeta
  },
  {
    path: "/addresses/:code/edit",
    name: "تعديل بيانات عنوان البريدي",
    component: editAddress,
    meta: dashBoardMeta
  },

  /* end addresses routers */
  /* tickets */
  {
    path: "/tickets",
    name: "تذاكر الدعم الفني",
    component: ticketsHome,
    meta: dashBoardMeta
  },
  {
    path: "/tickets/:id/show",
    name: "عرض التذكرة",
    component: ticketsShow,
    meta: dashBoardMeta
  },
  {
    path: "/tickets/new",
    name: "إضافة تذكرة جديدة",
    component: ticketNew,
    meta: dashBoardMeta
  },
  {
    path: "/manual",
    name: "دليل الأستخدام",
    component: userManual,
    meta: dashBoardMeta
  },
  { path: "*", component: PageNotFound ,name: "عفوًا ، الصفحة التي تبحث عنها غير موجودة ..", meta: dashBoardMeta},
  /* tickets */
  {
    path: "/logout",
    beforeEnter: () => {
      logout()
    }
  },

]