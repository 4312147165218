<template>
    <div>
        <button @click="selectMapLocation()" type="button" class="btn btn-light text-primary btn-sm py-0 mx-2">
            <img src="@/assets/images/icons-v2/gmap.png" width="10" alt="" /> تحديد موقع علي الخريطة
        </button>
        <Modal withoutbtn name="select_map_modal" id="select_map_modal" :title="$t('select_map')" type="none"
            size="large">
            <div slot="btn"></div>
            <div slot="body" class="py-2 text-center">
                <GmapMap @click="setMarker" ref="mapRef" :center="{ lat: 32.625148937407864, lng: 17.414175040074806 }"
                    :zoom="6" map-type-id="terrain" style="width: 100%; height: 400px">
                    <GmapMarker @dragend="updateMaker" :position="marker" :clickable="false" :draggable="true"
                        :icon="require('@/assets/images/icons-v2/pin.png')" ></GmapMarker>
                </GmapMap>
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from './Modal.vue';
    export default {
  components: { Modal },
        data(){
            return {
                marker : { lat: 32.625148937407864, lng: 17.414175040074806 }
            }
        },
        methods: {
            async selectMapLocation() {
                $("#select_map_modal").modal("show");
            },
            setMarker(event) {
                this.marker = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                };
                this.setMark();
            },
            updateMaker: function (event) {
                this.marker = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                };
                this.setMark();
            },
            setMark() {
                this.$toasted.show('تم تحديد الموقع بنجاح', { type: "success" });
                this.$emit('change',{marker : this.marker})
            },
        }
    }
</script>

<style>

</style>