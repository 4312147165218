<template>
    <div class="row justify-content-center">
        <div class="col-lg-12 col-md-12">

            <div class="card shadow">
                <div class="card-header card-header-primary">
                    <h4 class="card-title">{{$t('faq')}}</h4>
                </div>
                <img src="@/assets/images/faq.png" class="card-img-top d-block d-sm-none" alt="">
                <div class="card-body " dir="rtl">
                    <div class="text-center my-2" v-if="isCategoriesLoding">
                        <Spinner :size="20" :type="1" />
                    </div>
                    <div class="row" v-else>
                        <div class="col-12 table-responsive scollbal-hide" dir="rtl">
                            <span v-for="category in categories" :key="category.id"
                                @click="getCategoryData(category.id)"
                                class="btn badge badge-secondary badge-light-vanex mx-2 d-inline">
                                <img :src="category.icon_path" class="d-inline mx-1" height="15" alt="">
                                {{category.title}}
                            </span>
                        </div>
                    </div>
                    <div v-if="!isCategoriesLoding">
                        <div class="text-center my-2" v-if="isLoading">
                            <vanex-list-loading :rowcount="10" />
                        </div>
                        <div class="row" v-else-if="!isLoading">
                            <div class="col-12" dir="rtl">
                                <div class="accordion pt-4" id="accordionExample">

                                    <div class="card my-0 py-0" v-for="(question,index)  in categoryData" :key="question.id">
                                        <div class="card-header my-0 py-0" :id="`show__qa_${question.id}`">
                                            <h2 class="mb-0">
                                                <button
                                                    class="btn btn-link btn-block text-right text-click text-vanex list-group-item list-group-item-manual text-click"
                                                    type="button" data-toggle="collapse"
                                                    :data-target="`#show_qa_${question.id}`" aria-expanded="true"
                                                    aria-controls="collapseOne">
                                                    <strong
                                                        class="text-right text-click text-vanex">{{question.question}}</strong>
                                                </button>
                                            </h2>
                                        </div>

                                        <div :id="`show_qa_${question.id}`" class="collapse my-0 py-0 "
                                            :class="{'show':index == 0}"
                                            :aria-labelledby="`show__qa_${question.id}`"
                                            data-parent="#accordionExample">
                                            <div class="card-body  text-vanex">
                                                <div v-html="question.answer"></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { instance } from "../../modules/instance";

    import Spinner from "../../components/public/Spinner.vue";
    export default {
        components: { Spinner },
        data() {
            return {
                categories: [],
                categoryData: [],
                isCategoriesLoding: true,
                isLoading: true,
            };
        },
        methods: {
            getCategories() {
                let _this = this;
                let id = 0;
                _this.isCategoriesLoding = true;
                instance
                    .get("/ar/faq/category")
                    .then(function (response) {
                        _this.categories = response.data.data;
                        id = response.data.data[0].id
                    })
                    .catch(function (error) {

                        // _this.$router.push({ path: "/register" });
                    }).then(() => {
                        _this.isCategoriesLoding = false;
                        _this.getCategoryData(id)
                    })

            },
            getCategoryData(id) {

                let _this = this;
                _this.isLoading = true;
                _this.categoryData = [];
                instance
                    .get(`/ar/faq/category/${id}`)
                    .then(function (response) {
                        _this.categoryData = response.data.FaqCategory.faq;
                    })
                    .catch(function (error) {

                        // _this.$router.push({ path: "/register" });
                    }).then(() => {
                        _this.isLoading = false;
                    })
            },
        },
        mounted() {
            this.getCategories();
        },
    };
</script>

<style>
</style>