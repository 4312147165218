<template>
 <div class="modal fade" :id="id" tabindex="-1" :aria-labelledby="`${id}-title`" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" :id="`${id}-title`">{{title}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
       {{body}}
      </div>
      
    </div>
  </div>
</div>
  
</template>

<script>
export default {
    props: ['id','title','body']
};
</script>

<style></style>
