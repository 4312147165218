<template>
 <div class="container">
      <div class="row justify-content-center h-100">
        <div class="col-lg-12">
          <div class="card  ">
            <div class="card-body ">
              <form @submit="resetPassword" novalidate>
              <div class="row justify-content-center">
                <div class="col-lg-12 bg-white p-4">
                  
                  <div class="text-right">
                    <h4 class="text-vanex text-bold my-3">{{$t('reset_password')}}</h4>
                    <small class="text-muted">{{$t('reset_password_notes')}}</small>
                  </div>
                  
                  <div class="row">
                    <div class="col-lg-6">
                      <Input title="كلمة المرور" v-model="password" :error-message="errors" name="password"
                    type="password" />
                    </div>
                    <div class="col-lg-6">
                      <Input title="تأكيد كلمة المرور" v-model="password_confirmation" :error-message="errors"
                    name="password_confirmation" type="password" />
                    </div>
                    <div class="col-12">
                       <CheckBox :error-message="errors" name="kill_session" v-model="killSession">  <a class="text-muted">تسجيل الخروج من كل الأجهزة</a> </CheckBox>
                    </div>
                  </div>
                  <button class="btn btn-vanex" >
                    <Spinner v-if="isLoading" type="2" size="20" />
                    <span class="text-white" v-if="!isLoading">تعيين</span>
                  </button>
                </div>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import CheckBox from '../components/public/CheckBox.vue';
import Input from '../components/public/Input.vue';
import Spinner from '../components/public/Spinner.vue';
import { instance } from '../modules/instance';
export default {
  components: { Input, Spinner, CheckBox },
  data(){
    return {
      isLoading : false, 
      killSession : false, 
      password : '', 
      password_confirmation : '' ,
      errors :{
      kill_session : '', 
      password : '', 
      password_confirmation : '' ,
      }

    }
  },
  methods:{
    resetPassword(e) {
      e.preventDefault()
        let errors = 0;
        if (!this.password) {
          errors++
          this.errors['password'] = "حقل كلمة المرور مطلوب ."
        } else {
          this.errors['password'] = ""
        }
        if (!this.password_confirmation) {
          errors++
          this.errors['password_confirmation'] = "حقل تأكيد كلمة المرور مطلوب ."
        } else {
          this.errors['password_confirmation'] = ""
        }
        if(this.password_confirmation != this.password && this.password  && this.password_confirmation  ){
          errors++
          this.errors['password'] = "يجب أن يكون حقل تأكيد كلمة المرور مطابق لحقل كلمة المرور"
        }
        if (errors <= 0) {
          let _this = this;
          _this.isLoading = true;
          instance
            .post("/change-passowrd", {
              id: _this.$route.query.uid,
              token: _this.$route.query.key,
              password: _this.password,
              kill_session: _this.kill_session ? 1 : 0,
              password_confirmation: _this.password_confirmation,
              from: 'web',
            })
            .then(function (response) {
              if (response.data.status_code == 200) {
                _this.$toasted.show(response.data.message, {
                  type: "success",
                });
                window.localStorage.removeItem('access_token')
                window.localStorage.removeItem('user')
                setTimeout(() => {
                  location.reload();
                }, 230);
              }
              
            })
            .catch(function (error) {
              if (error.response && error.response.data && error.response.data.errors) {
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                  _this.errors[key] = value
                }
              }
            })
            .then(function () {
              _this.isLoading = false;
            });

        }
      }
  }
  };
</script>

<style></style>
