<template>
  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12">
      <div class="card shadow">
        <div class="card-header card-header-primary">
          <h4 class="card-title">{{ $t("contact_us") }}</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12">

              <div class="my-2" v-if="isLoading">
                <div class="col-12 text-center">
                   <vanex-list-loading :rowcount="5" />
                </div>
              </div>
              <div class="row mb-2 p-2" v-else>

                <div class="col-lg-3" v-for="contactDetail in contactDetails" :key="contactDetail.id">
                  <div class="row justify-content-center mt-3">
                    <div class="col-3 my-auto">
                      <img v-if="contactDetail.icon == 'calendar'" src="@/assets/images/icons-v2/calendar.svg"
                        width="55" alt="" />
                      <img v-if="contactDetail.icon == 'map'" src="@/assets/images/icons-v2/location.svg" width="55"
                        alt="" />
                      <img v-if="contactDetail.icon == 'phone'" src="@/assets/images/icons-v2/phone.svg" width="55"
                        alt="" />
                      <img v-if="contactDetail.icon == 'email'" src="@/assets/images/icons-v2/email.svg" width="55"
                        alt="" />
                    </div>
                    <div class="col-9 my-auto">
                      <h6 class="text-bold text-dark mb--3">
                        {{contactDetail.title}}
                      </h6>
                      <a v-if="contactDetail.icon == 'phone'" :href="`tel:${contactDetail.description}`"><small  class="text-muted" v-html="contactDetail.description"></small></a>
                      <a v-else-if="contactDetail.icon == 'email'" :href="`mailto:${contactDetail.description}`"><small  class="text-muted" v-html="contactDetail.description"></small></a>
                      <small v-else class="text-muted" v-html="contactDetail.description"></small>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 mt-4" dir="ltr">

                  <l-map style="height: 400px" :zoom="zoom" :center="center">
                    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                    <l-marker v-for="(branch,index) in branches" :key="index" :lat-lng="branch" :icon="VanexIcon">
                    </l-marker>
                  </l-map>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Input from "../components/public/Input.vue";
  import Spinner from "../components/public/Spinner.vue";
  import Select from "../components/public/Select.vue";
  import { instance } from "../modules/instance";
  import Modal from "../components/public/Modal.vue";
  import { LTooltip, LPopup } from "vue2-leaflet";

  import L from "leaflet";
  export default {
    components: { Input, Spinner, Select, Modal, LTooltip, LPopup },
    data() {
      return {
        isLoading: true,
        title: "",
        directed: "",
        message: "",
        contactDetails: [],
        errors: {
          title: "",
          directed: "",
          message: "",
        },
        VanexIcon: L.icon({
          iconUrl: "static/images/map-marker-1.png",
          iconSize: [62, 52],
          iconAnchor: [16, 37],
        }),

        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution: "",
        zoom: 6,
        center: [32.625148937407864, 17.414175040074806],
        branches: {},
      };
    },
    methods: {
      sendMessage() {
        var errors = 0;
        if (!this.title) {
          errors++;
          this.errors["title"] = this.$t("required", { title: this.$t("title") });
        } else {
          this.errors["title"] = "";
        }
        if (!this.directed) {
          errors++;
          this.errors["directed"] = this.$t("required", {
            title: this.$t("directed"),
          });
        } else {
          this.errors["directed"] = "";
        }
        if (!this.message) {
          errors++;
          this.errors["message"] = this.$t("required", {
            title: this.$t("message"),
          });
        } else {
          this.errors["message"] = "";
        }
        if (errors === 0) {
          let _this = this;
          _this.isLoading = true;

          let data = {
            title: this.title,
            subject: this.directed,
            message: this.message,
          };

          instance
            .post("/customer/contact/send", data)
            .then(function (response) {
              _this.$toasted.show(response.data.message, {
                type: "success",
              });
            })
            .catch(function (error) {
              if (
                error.response &&
                error.response.data &&
                error.response.data.errors
              ) {
                for (const [key, value] of Object.entries(
                  error.response.data.errors
                )) {
                  _this.errors[key] = value[0];
                }
                _this.needResendOtpCode = error.response.data.resend_otp_code
                  ? true
                  : false;
              }
            })
            .then(function () {
              _this.isLoading = false;
              _this.title = "";
              _this.directed = "";
              _this.message = "";
            });
        }
      },
      getContactDetails() {
        let _this = this;
        instance
          .get(`/contact-details`)
          .then(function (response) {
            _this.contactDetails = response.data.data;
          })
          .catch(function (error) {
            // handle error
          })
          .then(function () {
            _this.isLoading = false;
          });
        _this.isLoading = true;

        instance
          .get(`/branches-coordinates`)
          .then(function (response) {
            console.log(response.data.data);
            _this.branches = response.data.data;
          })
          .catch(function (error) {
            // handle error
          })
          .then(function () {
            _this.isLoading = false;
          });
      },
    },
    mounted() {
      this.getContactDetails();
    },
  };
/*
  <l-popup>
                      <a href="tel:0900000000">0900000000</a><br />
                      <a href="tel:0900000000">0900000000</a>
                    </l-popup>
  */
</script>

<style></style>