<template>
  <div>
    <div class="ph-item">
            
         
    <div class="ph-col-12">
        
        <div class="ph-row">
          <div class="ph-col-12 big" v-for="n in count" :key="n"></div>
        </div>
      </div>
       </div>
  </div>
</template>

<script>
export default {
  props:['rowcount'],
  data() {
    return {
      count : 5,
    }
  },
  mounted() {
    if (this.rowcount > 0) {
      this.count = this.rowcount
    }
  },
};
</script>

<style lang="stylus" scoped>


@import '../assets/css/placeholder-loading.min.css';

</style>
