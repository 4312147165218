<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card shadow">
          <div class="card-header card-header-primary ">
            <h4 class="card-title">{{$t('packages_report')}}</h4>
          </div>
          <div class="card-body">
            <div class="row justify-content-center">
              <div class="col-lg-12 col-md-12" v-if="rows && !isLoading">
                <div class="table-responsive">
                  <vue-good-table :columns="columns" :rows="rows" :rtl="true" styleClass="vgt-table  bordered"
                    :pagination-options="{
                    enabled: true,
                    mode: 'pages',
                    perPageDropdownEnabled: false,
                    perPage: 10,
                    position: 'bottom',
                    perPageDropdown: [3, 7, 9],
                    dropdownAllowAll: true,
                    setCurrentPage: 1,
                    nextLabel: 'التالي',
                    prevLabel: 'السابق',
                    rowsPerPageLabel: '',
                    ofLabel: 'من',
                    pageLabel: 'صفحة',
                    allLabel: 'الكل',
                  }" :search-options="{
                    enabled: true,
                  }" :sort-options="{
                    enabled: true,
                  }">
                    <div slot="emptystate">
                      <alert message="لا توجد شحنات لعرضها" type="vanex" />
                    </div>
                    <template slot="table-row" slot-scope="props">
                      <slot :props="props" />
                      <div v-if="props.column.field == 'package_code'">
                        <router-link :to="`/packages/${props.row.package_code}/show`"> <span
                            dir="ltr">{{props.row.package_code}}</span></router-link>
                      </div>
                      <div v-else-if="props.column.field == 'dimensions'">
                        <span dir="ltr">{{props.row.dimensions}}</span>
                      </div>
                    </template>
                  </vue-good-table>
                  <!-- <div class="row">
                    <div class="col-lg-4">
                      <div class="card bg-primary">
                        <div class="card-body">
                          <small>إجمالي عدد الشحنات</small><br />
                          <h3 class="d-inline">{{totalCount}}</h3> <small class="mx-1 d-inline">شحنة</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="card bg-success">
                        <div class="card-body">
                          <small>إجمالي قيمة الشحنات</small><br />
                          <h3 class="d-inline">{{totalPrice}}</h3> <small class="mx-1 d-inline">د.ل</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="card bg-warning">
                        <div class="card-body">
                          <small>إجمالي سعر الشحن</small><br />
                          <h3 class="d-inline">{{totalShippment}}</h3> <small class="mx-1 d-inline">د.ل</small>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="col-12" v-if="isLoading">
                <vanex-list-loading :rowcount="10" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Modal from '../../components/public/Modal.vue';
  import Spinner from "../../components/public/Spinner.vue";
  import { by, isValidDate, totop } from '../../modules/helpers';
  import { instance } from "../../modules/instance";
  export default {
    components: { Spinner, Modal },
    data: function () {
      return {
        totalCount : 0,
        totalPrice : 0,
        totalShippment : 0,
        
        packageCategory: { title: this.$t('sent'), key: 'sent' },
        columns: [
          {
            label: "#",
            field: "package_code",
          },
          {
            label: "نوع الشحن",
            field: "type",
          },
          {
            label: "الوصف",
            field: "description",
          },
          {
            label: "المستلم",
            field: "reciever",
          },
          {
            label: "الهاتف",
            field: "phone",
          },
          {
            label: "الهاتف الإحتياطي",
            field: "phone_b",
          },
          {
            label: "المدينة",
            field: "city",
          },
          {
            label: "المنطقة",
            field: "address",
          },
          {
            label: "الأبعاد",
            field: "dimensions",
          },
          {
            label: "المستحق",
            field: "price",
          },
          {
            label: "الشحن",
            field: "shippment",
          },
          {
            label: "الاجمالي",
            field: "total",
          },
          {
            label: "علي حساب",
            field: "paid_by",
          },
          {
            label: "الحجم على حساب",
            field: "extra_size_by",
          },
          {
            label: "التحصيل على حساب",
            field: "commission_by",
          },
          {
            label: "الحالة",
            field: "status_text",
          },
          {
            label: "الإضافة",
            field: "created",
          },
        ],
        rows: [],
        user: null,
        fromDate: null,
        toDate: null,
        packageCategories: [
          { title: this.$t('sent'), key: 'sent' },
          { title: this.$t('received'), key: 'received' },
        ],
        isLoading: false,
        totalRecords: 0,
        serverParams: {
          currentPage: 1, // what page I want to show
          page: 1, // what page I want to show
          perPage: 10, // how many items I'm showing per page
          category: 'sent', // how many items I'm showing per page
        },
      }
    },

    methods: {
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems();
      },
      onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage });
        this.loadItems();
      },
      changeCategory() {
        this.serverParams.category = this.packageCategory.key
        this.loadItems()
      },
      deletePackage(code) {
        $(`#delete_modal_${code}`).modal("hide");
        //  TODO delete package route
        // instance.delete(`/user/info/${code}`).then(function (response) {
        //         }).then(()=>{
        //             _this.isLoading = false;
        //             
        //             _this.$toasted.show(this.$t("package_delete_success_msg"), {
        //              type: "success",
        //             });
        //         }).catch((err)=>{
        //             _this.isLoading = false;
        //         })       
      },
      // load items is what brings back the rows from server
      loadItems() {
        this.fromDate = this.$route.query['from-date'];
        this.toDate = this.$route.query['to-date'];
        if (!this.fromDate || !this.toDate || !isValidDate(this.$route.query['from-date']) || !isValidDate(this.$route.query['to-date'])) {
          this.$router.push({ path: '/reports' })
        }
        let _this = this;
        _this.isLoading = true;
        instance
          .get(`/customer/package/sent?fd=${_this.fromDate}&td=${_this.toDate}`)
          .then(function (response) {
            _this.rows = []
            _this.totalCount = 0;
            _this.totalPrice = 0;
            _this.totalShippment = 0;
            response.data.data.data.forEach(packageData => {
              _this.rows.push({
                package_code: packageData['package-code'],
                type: (packageData.package_type) ? packageData.package_type.name : '',
                description: packageData.description,
                reciever: packageData.reciever,
                phone: packageData.phone,
                phone_b: packageData.phone_b,
                city: (packageData.City) ? packageData.City.name : '',
                address: packageData.address,
                dimensions: `${packageData.leangh} x ${packageData.width}  x ${packageData.height}`,
                price: `${packageData.price}`,
                shippment: `${packageData.shippment}`,
                total: `${packageData.total}`,
                paid_by: by(packageData.paid_by),
                extra_size_by: by(packageData.extra_size_by),
                commission_by: by(packageData.commission_by),
                status_object: packageData.status_object,
                status_text: packageData.status,
                cid: packageData.cid,
                created: packageData.update_date ? packageData.update_date : packageData.updated_at,
                setting: 1,
              })
              _this.totalCount += 1;
              _this.totalPrice += packageData.price;
              _this.totalShippment += packageData.shippment;

            });
            _this.totalPrice = parseFloat(_this.totalPrice).toFixed(1)
            _this.totalShippment = parseFloat(_this.totalShippment).toFixed(1)


          })
          .catch(function (error) {

          })
          .then(function () {
            _this.isLoading = false;
            totop()
          });
      },
    },
    mounted() {
      this.loadItems()
    }
  }
</script>

<style>

</style>