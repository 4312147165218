import Vue from "vue";
import Vuex from "vuex";
import auth from './auth'
Vue.use(Vuex);

export default new Vuex.Store({
  state :{
     /* general */
     public: process.env.BASE_URL,
     /* general */
  },
  modules:{
    auth
  }
});
