<template>
    <div class="container">
        <div class="row justify-content-center h-100">
            <div class="col-lg-12">
                <div class="card  ">
                    <div class="card-header card-header-primary">
                        <h4 class="card-title">{{$t('edit_packages')}}</h4>
                    </div>
                    <div class="card-body ">
                        <form @submit="createPackage" novalidate>
                            <div class="row justify-content-center">
                                <div class="col-12" v-if="isEditLoading">
                                    <vanex-list-loading :rowcount="5" />
                                </div>
                                <div class="col-lg-12 bg-white p-4 " v-else>
                                    <div class="text-right">
                                        <h4 class="text-vanex text-bold my-3 ">{{$t('edit_packages')}} - {{typeTitle}}
                                        </h4>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 my-2">
                                            <h4 class="text-vanex ml-2 heading-info"> {{$t('package_details')}} </h4>
                                        </div>
                                        <div class="col-lg-6" >
                                            <Input :title="$t('description')" v-model="description"
                                                :error-message="errors" name="description" type="text" />
                                        </div>
                                        <div class="col-lg-6" v-if="type == 3">
                                            <div class="form-group">
                                                <small class="text-right text-muted">{{$t('paper_mail_type')}}</small>
                                                <v-select v-model="mail_type" dir="rtl" label="name"
                                                    :options="mail_types"></v-select>
                                                <small v-if="errors && errors['mail_type']"
                                                    class="text-right text-danger d-inline"><i
                                                        class="fa fa-exclamation-circle mx-2"></i>
                                                    {{errors['mail_type']}}
                                                </small>

                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <Input :title="$t('qty')" v-model="qty" :error-message="errors" name="qty"
                                                type="number" />
                                        </div>
                                        <div class="col-lg-6">
                                            <Input :title="$t('sticker_notes')" v-model="sticker_notes"
                                                :extra-text="$t('35_char')" :error-message="errors" name="sticker_notes"
                                                type="text" />
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="form-group" v-if="!photo">
                                                <small class="text-right text-muted">{{$t('package_photo')}}</small>
                                                <input type="button"
                                                    class="btn btn-light btn-block text-center border-me"
                                                    :value=" $t('input_placeholder', { title:$t('package_photo') })"
                                                    onclick="document.getElementById('file').click();" />
                                                <input type="file" id="file" name="photo" required
                                                    @change="onSelectFile" accept="image/*" style="display:none;" />
                                                <small v-if="errors && errors['photo']"
                                                    class="text-right text-danger d-inline"><i
                                                        class="fa fa-exclamation-circle mx-2"></i>
                                                    {{errors['photo']}}
                                                </small>

                                            </div>
                                            <div class="form-group" v-if="photo">
                                                <div class="row">
                                                    <div class="col-8">
                                                        <alert :message="$t('success_image_selectred')" type="vanex" />
                                                    </div>
                                                    <div class="col-4"><button @click="photo = null"
                                                            class="btn btn-light text-danger btn-block" type="button"><i
                                                                class="fa fa-times-circle mx-1 "></i> {{$t('delete')}}
                                                        </button></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12" v-if="type != 3">
                                            <div class="row">
                                                <div class="col-lg-12 mt-4 mb-2">
                                                    <h4 class="text-vanex ml-2 heading-info">
                                                        {{$t('package_size_details')}}
                                                    </h4>
                                                </div>
                                                <div class="col-lg-4">
                                                    <Input :title="$t('leangh')" v-model="leangh"
                                                        :error-message="errors" name="leangh" type="number" />
                                                </div>
                                                <div class="col-lg-4">
                                                    <Input :title="$t('width')" v-model="width" :error-message="errors"
                                                        name="width" type="number" />
                                                </div>
                                                <div class="col-lg-4">
                                                    <Input :title="$t('height')" v-model="height"
                                                        :error-message="errors" name="height" type="number" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <alert :message="$t('size_alert')" type="vanex" />
                                        </div>
                                        <div class="col-lg-12 mt-4 mb-2">
                                            <h4 class="text-vanex ml-2 heading-info"> {{$t('recipient_details')}} </h4>
                                        </div>
                                        <div class="col-12">
                                            <small class="text-right text-muted">{{$t('recipient_type')}}</small>
                                            <select v-model="recipient_type" name="recipient_type" class="form-control">
                                                <option value="1">{{$t('recipient_type_1')}}</option>
                                                <option value="2">{{$t('recipient_type_2')}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-12" v-if="recipient_type==2">
                                            <div class="row">
                                                <div class="col-12">
                                                    <Input :title="$t('address_code')" v-model="address_code"
                                                        :error-message="errors" name="address_code" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 row" v-if="recipient_type==1">
                                            <div class="col-lg-4">
                                                <Input :title="$t('reciever')" v-model="reciever"
                                                    :error-message="errors" name="reciever" type="text" />
                                            </div>
                                            <div class="col-lg-4">
                                                <Input :title="$t('phone')" v-model="phone" :error-message="errors"
                                                    name="phone" type="number" />
                                            </div>
                                            <div class="col-lg-4">
                                                <Input :title="$t('phone_b')" v-model="phone_b" :error-message="errors"
                                                    name="phone_b" type="number" />
                                            </div>
                                            <div class="col-lg-6">
                                                <small for="city" class="text-right text-muted">{{$t('city')}}</small>
                                                <v-select @input="getSubCities()" v-model="city" dir="rtl" label="name"
                                                    :options="cities">
                                                    <template slot="option" slot-scope="option">
                                                        {{ option.name }} - {{ option.price }} د.ل
                                                    </template>
                                                </v-select>
                                                <small v-if="errors && errors['city']"
                                                    class="text-right text-danger d-inline"><i
                                                        class="fa fa-exclamation-circle mx-2"></i>
                                                    {{errors['city']}}
                                                </small>
                                            </div>
                                            <div class="col-lg-6" v-if="subCities.length>0 && city">
                                                <label for="address_child">{{$t('address_child')}}</label>
                                                <v-select v-model="address_child" dir="rtl" label="name"
                                                    :options="subCities">
                                                    <template slot="option" slot-scope="option">
                                                        {{ option.name }} - {{ option.price }} د.ل
                                                    </template>
                                                </v-select>
                                                <small v-if="errors && errors['address_child']"
                                                    class="text-right text-danger d-inline"><i
                                                        class="fa fa-exclamation-circle mx-2"></i>
                                                    {{errors['address_child']}}
                                                </small>
                                            </div>
                                            <div class="col-lg-6">
                                                <Input :title="$t('address')" v-model="address" :error-message="errors"
                                                    name="address" type="text" />
                                            </div>
                                            <div
                                                :class="{ 'col-lg-6': subCities.length>0 , 'col-lg-12': subCities.length<=0 }">
                                                <Input :title="$t('map')" v-model="map" :error-message="errors"
                                                    name="map" type="text" />
                                                <div class="btn-group">
                                                   
                                                    <map-selector @change="mapChange"></map-selector>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" v-if="type != 2">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-lg-12 mt-4 mb-2">
                                                        <h4 class="text-vanex ml-2 heading-info">
                                                            {{$t('financial_details')}} </h4>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <Input :title="$t(((type!=3) ? 'price' : 'paper_value'))"
                                                            v-model="price" :error-message="errors" name="price"
                                                            type="number" />
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <small
                                                            class="text-right text-muted">{{$t('payment_methode')}}</small>
                                                        <select v-model="payment_methode" name="payment_methode"
                                                            class="form-control">
                                                            <option value="cash">{{$t('cash')}}</option>
                                                            <option value="cheque">{{$t('cheque')}}</option>
                                                        </select>
                                                        <small v-if="errors && errors['payment_methode']"
                                                            class="text-right text-danger d-inline"><i
                                                                class="fa fa-exclamation-circle mx-2"></i>
                                                            {{errors['payment_methode']}}
                                                        </small>

                                                    </div>
                                                    <div class="col-12">
                                                        <alert v-if="type != 3" type="vanex"
                                                            :message="$t('payment_methode_notes')"></alert>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <hr />
                                        </div>
                                        <div class="col-md-4" v-if="type != 2">
                                            <label class="text-primary text-bold">{{$t('delivery_on')}} </label>
                                            <div class="col-md-12">
                                                <input type="radio" id="paid_by_1" v-model="paid_by" name="paid_by"
                                                    value="market" />
                                                <label for="paid_by_1"
                                                    class="d-inline text-dark mx-1">{{$t('on_market')}}</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="radio" id="paid_by_2" name="paid_by" v-model="paid_by"
                                                    value="customer" />
                                                <label for="paid_by_2"
                                                    class="d-inline text-dark mx-1">{{$t('on_customer')}}</label>
                                            </div>
                                            <small v-if="errors && errors['paid_by']"
                                                class="text-right text-danger d-inline"><i
                                                    class="fa fa-exclamation-circle mx-2"></i>
                                                {{errors['paid_by']}}
                                            </small>
                                        </div>
                                        <div class="col-md-4"
                                            v-if="((parseInt(leangh) > 35 || parseInt(width) > 35 || parseInt(height) > 35) && (parseInt(leangh)+parseInt(width)+parseInt(height) >105) ) && type != 2">
                                            <label class="text-primary text-bold">{{$t('extra_price_on')}}</label>
                                            <div class="col-md-12">
                                                <input type="radio" id="extra_size_by_1" v-model="extra_size_by"
                                                    name="extra_size_by" value="market" checked />
                                                <label for="extra_size_by_1"
                                                    class="d-inline text-dark mx-1">{{$t('on_market')}}</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="radio" id="extra_size_by_2" v-model="extra_size_by"
                                                    name="extra_size_by" value="customer" />
                                                <label for="extra_size_by_2"
                                                    class="d-inline text-dark mx-1">{{$t('on_customer')}}</label>
                                            </div>
                                            <small v-if="errors && errors['extra_size_by']"
                                                class="text-right text-danger d-inline"><i
                                                    class="fa fa-exclamation-circle mx-2"></i>
                                                {{errors['extra_size_by']}}
                                            </small>
                                        </div>
                                        <div class="col-md-4" v-if="parseInt(price) >= 1000 && type != 2">
                                            <label class="text-primary text-bold">{{$t('commission_on')}}</label>
                                            <div class="col-md-12">
                                                <input type="radio" id="commission_by_1" v-model="commission_by"
                                                    name="commission_by" value="market" checked />
                                                <label for="commission_by_1"
                                                    class="d-inline text-dark mx-1">{{$t('on_market')}}</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="radio" v-model="commission_by" id="commission_by_2"
                                                    name="commission_by" value="customer" />
                                                <label for="commission_by_2"
                                                    class="d-inline text-dark mx-1">{{$t('on_customer')}}</label>
                                            </div>
                                            <small v-if="errors && errors['commission_by']"
                                                class="text-right text-danger d-inline"><i
                                                    class="fa fa-exclamation-circle mx-2"></i>
                                                {{errors['commission_by']}}
                                            </small>
                                        </div>
                                        <div class="col-lg-12 mt-2">
                                            <Button :text="$t('update')" :isLoading="isLoading" type="submit" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from '../../components/public/Button.vue';
    import CheckBox from '../../components/public/CheckBox.vue';
    import Input from '../../components/public/Input.vue';
    import { totop } from '../../modules/helpers';
    import { instance } from "../../modules/instance";
    import Spinner from "../../components/public/Spinner.vue";
    import Select from "../../components/public/Select.vue";
    export default {
        components: { Input, Button, CheckBox, Select, Spinner },
        data() {
            return {
                isLoading: 1,
                type: 1,
                typeTitle: "",
                recipient_type: 1,
                description: "",
                address_code: "",
                qty: '',
                reciever: '',
                phone: '',
                phone_b: '',
                sticker_notes: '',
                leangh: 35,
                width: 35,
                height: 35,
                address: '',
                price: 0,
                saveAddress: false,
                photo: "",
                map: "",
                paid_by: 'customer',
                extra_size_by: 'customer',
                commission_by: 'customer',
                payment_methode: 'cash',
                isLoading: false,
                isEditLoading: true,
                isAddressLoading: false,
                city: "",
                address_child: "",
                subCities: [],
                types: [],
                citiesLoading: false,
                mail_type: null,
                user: null,
                cities: [],
                errors: {
                    type: '',
                    description: '',
                    address_code: '',
                    qty: '',
                    reciever: '',
                    payment_methode: '',
                    phone: '',
                    phone_b: '',
                    sticker_notes: '',
                    leangh: '',
                    width: '',
                    height: '',
                    address: '',
                    price: '',
                    photo: '',
                    map: '',
                    paid_by: '',
                    extra_size_by: '',
                    commission_by: '',
                    city: '',
                    address_child: '',
                    mail_type: '',
                    saveAddress: '',
                },
            };
        },
        methods: {
            async getUserLocation() {
                let _this = this;
                await navigator.geolocation.getCurrentPosition(function (position) {
                    let lat = position.coords.latitude;
                    let long = position.coords.longitude;
                    document.getElementById('map').value = `https://maps.google.com/?q=${lat},${long}`;
                });
            },
            getCities() {
                let _this = this;
                _this.cities = [];
                _this.citiesLoading = true;
                instance
                    .get("/city/names")
                    .then(function (response) {
                        let cities = response.data.data;
                        _this.cities = cities;
                    })
                    .catch(function () {

                    })
                    .then(function () {

                        _this.citiesLoading = false;

                    });
            },
            getSubCities() {
                let _this = this;
                let cityId = this.city.id;
                _this.citiesLoading = true;
                _this.address_child = null;
                instance.get(`/city/${cityId}/subs`).then(function (response) {
                    let prices = response.data.data.data;
                    _this.subCities = prices;
                }).then(() => {
                    _this.citiesLoading = false;
                })
            },
            onSelectFile(event) {
                this.photo = event.target.files[0];
            },
            getTypes() {
                let _this = this;
                _this.type = null;
                _this.types = null;
                instance.get(`/customer/package/types`).then((res) => {
                    let userType = _this.user.type;
                    _this.types = [];
                    res.data.data.forEach(type => {

                        if (userType == 1) {
                            _this.types.push(type)
                        }

                        if (userType == 2) {
                            if (type.id != 1) {
                                _this.types.push(type)
                            }
                        }
                    });

                });
            },
            setType(type) {
                this.type = type.id;
                this.typeTitle = type.name;
                if (type.id == 3) {
                    this.getmail_types();
                }
            },
            getmail_types() {
                // TODO get paper mail types
                this.mail_types = [
                    {
                        id: 4,
                        name: 'البريد الورقي القانوني'
                    },
                    {
                        id: 5,
                        name: 'البريد الورقي الشخصي'
                    },
                ]
            },
            createPackage(e) {
                e.preventDefault()
                let errors = 0;
                if (!this.type) {
                    errors++
                    this.errors['type'] = this.$t('required', { title: this.$t('type') })
                } else {
                    this.errors['type'] = ""
                }
                if (this.type == 3) {
                    if (!this.mail_type) {
                        errors++
                        this.errors['mail_type'] = this.$t('required', { title: this.$t('paper_mail_type') })
                    } else {
                        this.errors['mail_type'] = ""
                    }
                } else if (this.type != 3) {
                    if (!this.description) {
                        errors++
                        this.errors['description'] = this.$t('required', { title: this.$t('description') })
                    } else {
                        this.errors['description'] = ""
                    }
                }

                if (!this.qty) {
                    errors++
                    this.errors['qty'] = this.$t('required', { title: this.$t('qty') })
                } else {
                    this.errors['qty'] = ""
                }
                if (this.recipient_type != 2) {
                    if (!this.reciever) {
                        errors++
                        this.errors['reciever'] = this.$t('required', { title: this.$t('reciever') })
                    } else {
                        this.errors['reciever'] = ""
                    }
                    if (!this.phone) {
                        errors++
                        this.errors['phone'] = this.$t('required', { title: this.$t('phone') })
                    } else {
                        this.errors['phone'] = ""
                    }
                    if (!this.address && !this.address_child) {
                        errors++
                        this.errors['address'] = this.$t('required', { title: this.$t('address') })
                    } else {
                        this.errors['address'] = ""
                    }

                    if (!this.city) {
                        errors++
                        this.errors['city'] = this.$t('required', { title: this.$t('city') })
                    } else {
                        this.errors['city'] = ""
                        if ((this.city.id == 2 || this.city.id == 1) && !this.address_child) {
                            errors++
                            this.errors['address_child'] = this.$t('required', { title: this.$t('address_child') })
                        } else {
                            this.errors['address_child'] = ""
                        }
                    }
                } else {
                    if (!this.address_code) {
                        errors++
                        this.errors['address_code'] = this.$t('required', { title: this.$t('address_code') })
                    } else {
                        this.errors['address_code'] = ""
                    }
                }

                if (this.type != 3) {

                    if (!this.leangh) {
                        errors++
                        this.errors['leangh'] = this.$t('required', { title: this.$t('leangh') })
                    } else {
                        this.errors['leangh'] = ""
                    }
                    if (!this.width) {
                        errors++
                        this.errors['width'] = this.$t('required', { title: this.$t('width') })
                    } else {
                        this.errors['width'] = ""
                    }
                    if (!this.height) {
                        errors++
                        this.errors['height'] = this.$t('required', { title: this.$t('height') })
                    } else {
                        this.errors['height'] = ""
                    }

                }


                if (!this.payment_methode) {
                    errors++
                    this.errors['payment_methode'] = this.$t('required', { title: this.$t('payment_methode') })
                } else {
                    this.errors['payment_methode'] = ""
                }
                if (!this.price && this.type != 2) {
                    errors++
                    this.errors['price'] = this.$t('required', { title: this.$t(((this.type != 3) ? 'price' : 'paper_value')) })
                } else {
                    this.errors['price'] = ""
                }
                if (!this.paid_by && this.type != 2) {
                    errors++
                    this.errors['paid_by'] = this.$t('required', { title: this.$t('paid_by') })
                } else {
                    this.errors['paid_by'] = ""
                }
                if (parseInt(this.leangh) > 35 || parseInt(this.width) > 35 || parseInt(this.height) > 35 && this.type != 2) {
                    if (!this.extra_size_by) {
                        errors++
                        this.errors['extra_size_by'] = this.$t('required', { title: this.$t('extra_size_by') })
                    } else {
                        this.errors['extra_size_by'] = ""
                    }

                }
                if (!this.commission_by && this.type != 2) {
                    errors++
                    this.errors['commission_by'] = this.$t('required', { title: this.$t('commission_by') })
                } else {
                    this.errors['commission_by'] = ""
                }

                if (errors == 0) {
                    let _this = this;
                    _this.isLoading = true;
                    const fd = new FormData();
                    fd.append('type', _this.type);
                    fd.append('description', _this.description);
                    fd.append('qty', _this.qty);
                    fd.append('leangh', _this.leangh);
                    fd.append('width', _this.width);
                    fd.append('height', _this.height);
                    if (this.recipient_type != 2) {
                        fd.append('address', _this.address);
                        fd.append('reciever', _this.reciever);
                        fd.append('phone', _this.phone);
                        if (_this.phone_b) {
                            fd.append('phone_b', _this.phone_b);
                        }
                        fd.append('city', _this.city.id);
                        if (_this.city.id == 1 || _this.city.id == 2) {
                            fd.append('address_child', _this.address_child.id);
                        }
                    } else {
                        fd.append('address_code', _this.address_code);
                    }
                    fd.append('price', _this.price);
                    fd.append('sticker_notes', _this.sticker_notes);
                    fd.append('paid_by', (_this.paid_by) ? _this.paid_by : 'customer');
                    fd.append('extra_size_by', (_this.extra_size_by) ? _this.extra_size_by : 'customer');
                    fd.append('commission_by', (_this.commission_by) ? _this.commission_by : 'customer');

                    fd.append('save_address', _this.saveAddress);
                    fd.append('payment_methode', _this.payment_methode);

                    if (this.type == 3) {
                        fd.append('sub_type', _this.mail_type.id);
                    }
                    fd.append('map', _this.map);

                    if (this.photo) {
                        fd.append('photo', this.photo, this.photo.name);
                    }


                    instance
                        .post("/customer/package/" + _this.code + "?_method=put", fd)
                        .then(function (response) {
                            _this.$toasted.show(response.data.message, {
                                type: "success",
                            });
                            _this.$router.push({ path: '/packages' })
                        })
                        .catch(function (error) {

                            if (error.response && error.response.data && error.response.data.errors) {

                                for (const [key, value] of Object.entries(error.response.data.errors)) {
                                    _this.errors[key] = value[0]
                                }
                                if (error.response.data.errors['message']) {
                                    _this.$toasted.show(error.response.data.errors['message'], {
                                        type: "warning",
                                    });
                                }
                            }
                        })
                        .then(function () {
                            totop()
                            _this.isLoading = false;
                        });
                } else {
                    totop()
                }
            },
            initAddressCode(code) {
                this.type = 1;
                this.recipient_type = 2;
                this.address_code = code;
                document.getElementById('address_code').value = code;
                totop()
            },
            getPackageData() {
                if (!this.$route.params.code) {
                    this.$router.push({ path: '/packages' })
                    return false;
                }
                let _this = this
                let code = this.$route.params.code
                this.code = code
                this.isEditLoading = true;
                instance
                    .get(`/customer/package/${code}`)
                    .then(async (response) => {
                        // TODO get sticker_notes 
                        let packageData = response.data.data
                        if(packageData.status_object && packageData.status_object.status_value != 'store_new'){
                             _this.$router.push({ path: '/packages' })
                        }
                        _this.type = packageData.package_type.id
                        _this.typeTitle = packageData.package_type.name
                        _this.description = packageData.description
                        _this.qty = packageData.qty

                        _this.sticker_notes = (packageData.sticker_notes && packageData.sticker_notes != 'null') ? packageData.sticker_notes : null
                        _this.map = (packageData.map && packageData.map != 'null') ? packageData.map : null
                        _this.leangh = packageData.leangh
                        _this.width = packageData.width
                        _this.height = packageData.height

                        _this.price = packageData.origin_price

                        _this.paid_by = packageData.paid_by
                        _this.extra_size_by = packageData.extra_size_by
                        _this.commission_by = packageData.commission_by
                        _this.payment_methode = packageData.payment_methode
                        _this.city = packageData.City
                        if (packageData.address_code) {
                            _this.recipient_type = 2;
                            _this.address_code = packageData.address_code;
                        } else {
                            _this.recipient_type = 1;
                            _this.reciever = packageData.reciever
                            _this.phone = packageData.phone
                            _this.phone_b = packageData.phone_b
                            _this.address = packageData.address
                        }
                        if (packageData.sub_city) {
                            await _this.getSubCities()
                            _this.address_child = packageData.sub_city
                        }
                        if (packageData.package_sub_type) {
                            _this.getmail_types();
                            _this.mail_type = { id: packageData.package_sub_type.id, name: packageData.package_sub_type.name };
                        }


                    })
                    .catch(function (e) {
                        console.warn(e);
                        _this.$router.push({ path: '/packages' })
                    })
                    .then(function () {
                        _this.isEditLoading = false;
                        if (_this.recipient_type == 1) {
                            document.getElementById('reciever').value = _this.reciever
                            document.getElementById('phone').value = _this.phone
                            document.getElementById('phone_b').value = _this.phone_b
                            document.getElementById('address').value = _this.address
                            document.getElementById('map').value = (_this.map && _this.map != 'null') ? _this.map : null
                        }
                    });
            },
            mapChange(e){
                let _this = this;
                let data = `https://maps.google.com/?q=${e.marker.lat},${e.marker.lng}`;
                _this.map = data;
                document.getElementById('map').value = data;
            }

        },
        async mounted() {
            let user = this.$store.state.auth.user;
            this.user = user
            this.getTypes()
            this.getCities()
            await this.getPackageData()
        }

    };
</script>

<style></style>