<template>
  <div class="row justify-content-center h-100">
    <div class="col-lg-12">
      <div class="card shadow">
        <div class="card-header card-header-primary">
             <h4 class="card-title">{{$t('show_collects')}}</h4>
        </div>
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-lg-12 bg-white pt-2">
              <ul class="nav mb-3 justify-content-center nav-justified">
                <li @click="getCollects(2)" class="tab-title">
                  <a href="#complete" data-toggle="tab">مكتمل</a>
                </li>
                <li @click="getCollects(1)" class="tab-title">
                  <a href="#continuous" class="active show" data-toggle="tab"
                    >قائم</a
                  >
                </li>
                <li @click="getCollects(3)" class="tab-title">
                  <a href="#canceled" data-toggle="tab">ملغي</a>
                </li>
              </ul>

              <div class="tab-content clearfix"  v-if="!isLoading" >
                <vue-good-table
                    :columns="columns"
                    :rows="rows"
                    :rtl="true"
                    styleClass="vgt-table "
                    mode="remote"
                    :isLoading.sync="isLoading"
                    :totalRows="totalRecords"
                    @on-per-page-change="onPerPageChange"
                    @on-page-change="onPageChange"
                    :pagination-options="{
                      enabled: true,
                      mode: 'pages',
                      perPageDropdownEnabled: false,
                      perPage: 10,
                      position: 'bottom',
                      perPageDropdown: [3, 7, 9],
                      dropdownAllowAll: true,
                      setCurrentPage: this.serverParams.page,
                      nextLabel: 'التالي',
                      prevLabel: 'السابق',
                      rowsPerPageLabel: 'عدد الصفوف في الصفحة',
                      ofLabel: 'من',
                      pageLabel: 'صفحة',
                      allLabel: 'الكل',
                    }"
                    :search-options="{
                      enabled: false,
                    }"
                    :sort-options="{
                      enabled: false,
                    }"
                  >
                    <template slot="table-row" slot-scope="props">
                      <slot :props="props" />
                      <div v-if="props.column.field == 'setting'">
                        <div
                          class="btn-group"
                          role="group"
                          aria-label=""
                          v-if="props.row.status == 'active'"
                        >
                        <router-link :to="`/collects/${props.row.id}/show`"
                            ><button type="button" class="btn btn-link text-success">
                              <i class="material-icons">remove_red_eye</i>
                            </button>
                          </router-link>
                          <router-link :to="`/collects/${props.row.id}/edit`"
                            ><button type="button" class="btn btn-link text-primary">
                              <i class="material-icons">border_color</i>
                            </button>
                          </router-link>

                          

                          <Modal
                            :id="`delete_modal_${props.row.id}`"
                            :title="$t('collect_delete_confirm')"
                            type="link"
                            size="small"
                          >
                            <a slot="btn" class="text-danger"
                              ><i class="material-icons">delete</i>
                            </a>
                            <div slot="body">
                              <button
                                @click="deleteCollect(props.row.id)"
                                class="btn btn-danger btn-sm btn-block"
                              >
                                {{ $t("confirm") }}
                              </button>
                              <a
                                class="
                                  btn btn-block btn-secondary btn-sm
                                  text-dark
                                "
                                data-dismiss="modal"
                                >{{ $t("close") }}</a
                              >
                            </div>
                          </Modal>
                        </div>
                      </div>

                      <div v-else-if="props.column.field == 'id'">
                        <router-link :to="`/collects/${props.row.id}/show`"> <span dir="ltr">{{props.row.id}}</span></router-link>
                      </div>
                      <div v-else-if="props.column.field == 'returns_request'">
                        <div
                          v-if="props.row.returns_request == 1"
                          class="text-right"
                        >
                          <i class="material-icons text-success">task_alt</i>
                        </div>
                       
                      </div>
                      <div v-else-if="props.column.field == 'order'">
                        {{getPaginateIndex(props.row.originalIndex, serverParams.page, serverParams.perPage)}}
                      </div>

                      <div v-else-if="props.column.field == 'sett'">
                        <div v-if="props.row.sett == 1" class="text-right">
                          <i class="material-icons text-success">task_alt</i>
                        </div>
                        
                      </div>

                      <div v-else-if="props.column.field == 'ready'">
                        <div v-if="props.row.ready == 1" class="text-right">
                          <i class="material-icons text-success">task_alt</i>
                        </div>
                        
                      </div>
                      <div v-else-if="props.column.field == 'packaging'">
                        <div v-if="props.row.packaging == 1" class="text-right">
                          <i class="material-icons text-success">task_alt</i>
                        </div>
                        
                      </div>
                      <div v-else-if="props.column.field == 'status'">
                        <div class="text-right">
                          <get-collects-status v-if="props.row.status" :status="props.row.status" />
                        </div>
                      </div>
                      <div v-else-if="props.column.field == 'map'">
                        <div
                          v-if="props.row.map !== null && props.row.map !== ''"
                        >
                          <a
                            :href="props.row.map"
                            target="_blank"
                            rel="noopener noreferrer"
                            ><i class="material-icons text-success"
                              >remove_red_eye</i
                            ></a
                          >
                        </div>
                      </div>
                      <div v-else-if="props.column.field == 'notes'">
                        <small>{{ props.row.notes }}</small>
                      </div>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
              </div>
              <div class="text-center my-2" v-if="isLoading">
                <vanex-list-loading :rowcount="10" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "../../components/public/Spinner.vue";
import { instance } from "../../modules/instance";
import Modal from "../../components/public/Modal.vue";
import { totop ,getPaginateIndex} from '../../modules/helpers';
export default {
  components: { Spinner, Modal },
  data() {
    return {
      rows: [],
      page: 1,
      type: 1,
      columns: [
          {
            label: "#",
            field: "order",
          },
          {
            label: "رقم الطلب",
            field: "id",
          },
          {
            label: "العنوان",
            field: "address",
          },
          {
            label: "الهاتف",
            field: "phone",
          },
          {
            label: "الحالة",
            field: "status",
          },
          {
            label: "طلب مسترد",
            field: "returns_request",
          },
          {
            label: "طلب تسوية مالية",
            field: "sett",
          },
          {
            label: "شحنات جاهزة",
            field: "ready",
          },
          {
            label: "تغليف الأمن",
            field: "packaging",
          },
          {
            label: "خريطة التجميع",
            field: "map",
          },
          {
            label: "ملاحظة",
            field: "notes",
          },
          {
            label: "العمليات",
            field: "setting",
          },
        ],
      isLoading: true,
      totalRecords : 0,
      serverParams: {
        currentPage: 1,
        page: 1, // what page I want to show
        perPage: 10 // how many items I'm showing per page
      }
    };
  },
  methods: {
    getPaginateIndex:getPaginateIndex,
    getCollects(type = 1) {
      this.type = type ;
      this.serverParams.page = 1;
      this.loadItems()
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },
    // load items is what brings back the rows from server
    loadItems() {
      let _this = this;
      _this.isLoading = true;
      instance
        .get(`/customer/collects/status?status=${this.type}&page=${this.serverParams.page}`)
        .then(function (response) {
          _this.rows = [];
          _this.totalRecords = response.data.data.meta.total;
          response.data.data.data.forEach(collect => {
          _this.rows.push({
            id : collect.id,
            address : collect.address,
            phone : collect.phone,
            returns_request : collect.returns_request,
            sett : collect.sett,
            ready : collect.ready,
            packaging : collect.packaging,
            map : collect.map,
            status : collect.status,
            notes : collect.notes,
            setting :1,
          })
          });;
          
        })
        .catch(function (error) {
       
           _this.$router.push({ path: "/collects" });
        })
        .then(() => {
          _this.isLoading = false;
          totop()
        });
    },
    deleteCollect(id) {
        $('.modal').modal('hide');
        let _this = this;
        _this.isLoading = true;
        _this.collects = [];
        instance
          .delete(`/customer/collects/${id}`)
          .then(function (response) {
            _this.$toasted.show(_this.$t("collect_delete_success_msg"), {
              type: "success",
            });
          })
          .catch(function (error) {
           
            _this.$router.push({ path: "/collects" });
          })
          .then(() => {
            _this.isLoading = false;
            _this.loadItems();
          });
      },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
<style>
</style>