<template>
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div class="card shadow">
        <div class="card-header card-header-primary">
          <h4 class="card-title">{{$t('store_settelments')}}</h4>
        </div>
        <div class="card-body" dir="rtl">
          <vanex-list-loading v-if="isLoading" :rowcount="10" />
          <div class="table-responsive" v-else>
            <vue-good-table mode="remote" :totalRows="totalRecords" @on-per-page-change="onPerPageChange"
              @on-page-change="onPageChange" :columns="columns" :rows="rows" :rtl="true"
              styleClass="vgt-table  bordered" :pagination-options="{
                enabled: true,
                mode: 'pages',
                perPageDropdownEnabled: false,
                perPage: 10,
                position: 'bottom',
                perPageDropdown: [3, 7, 9],
                dropdownAllowAll: true,
                setCurrentPage: this.serverParams.page,
                nextLabel: 'التالي',
                prevLabel: 'السابق',
                rowsPerPageLabel: '',
                ofLabel: 'من',
                pageLabel: 'صفحة',
                allLabel: 'الكل',
              }" :search-options="{
                 enabled: true,
                placeholder: 'أبحث عن المعاملة هنا ....',
              }"
              
              @on-search="onSearch"
              :sort-options="{
                enabled: false,
              }">
              <template slot="table-row" slot-scope="props">
                <slot :props="props" />
                <div class="btn-group" role="group" aria-label="" v-if="props.column.field == 'print'">
                  <button v-if="!loadingId || loadingId != props.row.settlementSignals" type="button"
                    class="btn btn-danger btn-sm" @click="printSettelment(props.row.settlementSignals)">
                    <i class="material-icons">print</i>
                  </button>
                  <spinner v-if="loadingId && loadingId == props.row.settlementSignals" type="1" :size="17" />
                </div>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
          <print-layout id="printStoreSettelmet">
            <div v-if="selectedSettelment">
              <div class="text-center my-2">
                <h5 dir="rtl">التسوية المالية للمرسل : {{selectedSettelment.store.name}}</h5>
              </div>
              <table class="table table-bordered" dir="rtl">
                <thead>
                  <tr>
                    <th scope="col">بواسطة</th>
                    <td scope="col">{{selectedSettelment.user.name}}</td>
                    <th scope="col">التاريخ</th>
                    <td scope="col">
                      <custom-date :date="new Date()"></custom-date>
                    </td>
                  </tr>
                </thead>
              </table>
              <br />
              <table class="table table-bordered" dir="rtl">
                <tr>
                  <th>#</th>
                  <th>التاريخ</th>
                  <th>المرسل</th>
                  <th>القيمة</th>
                  <th>الملاحظات</th>
                </tr>
                <tr>
                  <td>{{`st-${selectedSettelment.id}`}}</td>
                  <td>
                    <custom-date :date="selectedSettelment.date"></custom-date>
                  </td>
                  <td>{{selectedSettelment.store.name}} - {{selectedSettelment.store.id}}</td>
                  <td>{{selectedSettelment.amount}} د.ل</td>
                  <td>{{selectedSettelment.note}}</td>
                </tr>

              </table>
              <table class="table table-bordered" dir="rtl">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>الوصف</th>
                    <th>المستلم</th>
                    <th>رقم الهاتف</th>
                    <th>أتعاب الشحن</th>
                    <th>استحقاق المتجر</th>
                  </tr>

                </thead>

                <tbody>
                  <tr v-for="(pkg,index) in selectedSettelment.packages" :key="index">
                    <td style="direction:ltr; text-align:center">
                      {{pkg.code}}
                    </td>
                    <td>
                      {{pkg.description}}
                    </td>
                    <td>
                      {{pkg.reciever}}
                    </td>
                    <td>
                      {{pkg.phone}} <span v-if="pkg.phone_b"> - {{pkg.phone_b}}</span>
                    </td>
                    <td>
                      {{pkg.paid_by == 'market' ? 'المتجر' : 'الزبون'}}
                    </td>
                    <td>
                      {{pkg.store_cost}}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-bordered" dir="rtl">

                <tr>
                  <th>إجمالي المبلغ المستحق للمتجر</th>
                  <td>
                    {{totalStoreCost}} د.ل
                  </td>
                </tr>
              </table>
              <table class="table table-bordered" dir="rtl">
                <tr>
                  <td>توقع المرسل</td>
                  <td>توقع الإدارة</td>
                </tr>
                <tr>
                  <td><br><br></td>
                  <td><br><br></td>
                </tr>
              </table>
            </div>
          </print-layout>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from '../../components/public/Spinner.vue';
  import { totop } from '../../modules/helpers';
  import { instance } from '../../modules/instance';
  export default {
    components: { Spinner },
    data() {
      return {
        columns: [
          { label: "#", field: "id" },
          { label: "إشاري التسوية", field: "settlementSignals" },
          { label: "تاريح التسوية", field: "settlementTime" },
          { label: "القيمة", field: "amount" },
          { label: "الملاحظات", field: "notes" },
          { label: "الفرع", field: "branch" },
          { label: "طباعة", field: "print" },
        ],
        rows: [],
        isLoading: true,
        totalRecords: 0,
        totalStoreCost: 0,
        loadingId: null,
        selectedSettelment: null,
        serverParams: {
          query:'',
          currentPage: 1, // what page I want to show
          page: 1, // what page I want to show
          perPage: 10, // how many items I'm showing per page
        },
      };
    },
    methods: {
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      onSearch(q){
      this.serverParams.page = 1;
        this.serverParams.query = q.searchTerm;
        this.loadItems()
    },
      onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems();
      },
      onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage });
        this.loadItems();
      },
      async printSettelment(id) {
        this.selectedSettelment = null
        this.totalStoreCost = 0
        let _this = this;
        _this.loadingId = id;
        await instance
          .get(`/store/settelmets/${id}/show`)
          .then(function (response) {
            _this.selectedSettelment = response.data.data
            if (_this.selectedSettelment) {
              let totalStoreCost = 0;
              _this.selectedSettelment.packages.forEach(pkg => {
                totalStoreCost += parseInt(pkg.store_cost);
              });
              _this.totalStoreCost = totalStoreCost;
            }
          }).then(async function () {
            _this.loadingId = null;
          });
        await this.$htmlToPaper('printStoreSettelmet');
      },
      // load items is what brings back the rows from server
      loadItems() {
        let _this = this;
       
        instance
          .get(`/store/settelmets?page=${this.serverParams.page}&q=${this.serverParams.query}`)
          .then(function (response) {
            _this.rows = []
            _this.totalRecords = response.data.data.total
            let i = 1;
            response.data.data.data.forEach(storeSettelmet => {
              _this.rows.push({
                id: i,
                settlementSignals: storeSettelmet.id,
                settlementTime: (storeSettelmet.date) ? storeSettelmet.date.split(' ')[0] : '',
                amount: `${storeSettelmet.amount} د.ل`,
                notes: storeSettelmet.note,
                // TODO get branch obj
                branch: storeSettelmet.branch ? storeSettelmet.branch.name : '',
                print: "1",
              })
              i++;
            });

          })
          .catch(function (error) {
            // handle error

          })
          .then(function () {
            _this.isLoading = false;
            totop()
          });
      },
    },
    mounted() {
      this.loadItems()
    }
  };
</script>