<template>
  <div class="row my-2" v-if="qty!=0">
      <div class="col-12">
         <div class="text-center" v-if="isLoading">
             <spinner  type="1" :size="17" />
         </div>

         <alert v-else :message="`لديك ${qty} طلب إنضمام لمتجر`" type="vanex" >
            <router-link to="/invitations" class="text-click text-vanex" ><strong>أضغط هنا للعرض</strong></router-link>
         </alert>
      </div>
    </div>
</template>

<script>
import { instance } from '../modules/instance';
import Spinner from './public/Spinner.vue';
export default {
  components: { Spinner },
    data(){
        return {
            qty : 0,
            isLoading :true,
        }
    },
    methods:{
        getReceivedCount(){
            let _this = this;
            instance
            .get("/customer/invites/received/count")
            .then(function (response) {
              _this.qty = response.data.data;
            })
            .then(function () {
              _this.isLoading = false;
            });
        }
    },
    mounted(){
        this.getReceivedCount()
    }
}
</script>

<style>

</style>