<template>
  <div class="row justify-content-center">
    <div class="col-lg-12">
      <div class="card shadow">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col-8">{{ $t("tickets") }}</div>
            <div class="col-4 text-left">
              <router-link to="/tickets/new">
                <button class="btn btn-vanex btn-sm btn-ticket">
                  <i class="fa fa-plus"></i> {{$t('open_new_ticket')}}
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <img
          src="@/assets/images/tickets.png"
          class="card-img-top d-block d-sm-none"
          alt=""
        />
        <div class="card-body" dir="rtl">
          <vanex-list-loading v-if="isLoading" :rowcount="10" />
          <div class="table-responsive" v-else>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <!-- <small class="text-right text-muted">{{
                    $t("status")
                  }}</small>
                  <v-select
                    @input="setStatus()"
                    v-model="status"
                    dir="rtl"
                    label="title"
                    :options="statuses"
                  ></v-select> -->

                  
                </div>
               <ul class="nav nav-tabs my-2" id="myTab" role="tablist">
                  <li class="nav-item text-vanex" v-for="selectStatus in statuses" :key="selectStatus.id" @click="setStatus(selectStatus)">
                    <a  class="btn btn-white text-vanex" :class="{ 'active': (status && (selectStatus.id == status.id)) }" data-toggle="tab" >{{selectStatus.title}}</a>
                  </li>
                </ul>
              </div>
            </div>
            <vue-good-table
              mode="remote"
              :totalRows="totalRecords"
              @on-per-page-change="onPerPageChange"
              @on-page-change="onPageChange"
              @on-row-click="onRowClick"
              :columns="columns"
              :rows="rows"
              :rtl="true"
              styleClass="vgt-table  bordered"
              :pagination-options="{
                enabled: true,
                mode: 'pages',
                perPageDropdownEnabled: false,
                perPage: 10,
                position: 'bottom',
                perPageDropdown: [3, 7, 9],
                dropdownAllowAll: true,
                setCurrentPage: this.serverParams.page,
                nextLabel: 'التالي',
                prevLabel: 'السابق',
                rowsPerPageLabel: '',
                ofLabel: 'من',
                pageLabel: 'صفحة',
                allLabel: 'الكل',
              }"
              :search-options="{
                enabled: false,
              }"
              :sort-options="{
                enabled: false,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <slot :props="props" />
                <div
                  class="btn-group"
                  role="group"
                  aria-label=""
                  v-if="props.column.field == 'print'"
                >
                  <button type="button" class="btn btn-danger btn-sm">
                    <i class="material-icons">print</i>
                  </button>
                </div>
                <a class="text-vanex" v-if="props.column.field == 'id'">
                  <strong>{{ props.row.id }}#</strong>
                </a>
                <a class="text-vanex" v-else-if="props.column.field == 'package_code'">
                  <strong dir="ltr">{{ props.row.package_code }}</strong>
                </a>
                <a v-else-if="props.column.field == 'status'">
                  <i
                    class="fa fa-dot-circle-o mx-1"
                    v-if="props.row.status.id == 1"
                  ></i>
                  <i
                    class="fa fa-dot-circle-o mx-1 text-warning"
                    v-else-if="props.row.status.id == 2"
                  ></i>
                  <i
                    class="fa fa-dot-circle-o mx-1 text-success"
                    v-else-if="props.row.status.id == 3"
                  ></i>
                  <i class="fa fa-dot-circle-o mx-1" v-else></i>
                  {{ props.row.status.title }}
                </a>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { totop } from "../../../modules/helpers";
import { instance } from "../../../modules/instance";
export default {
  data: function () {
    return {
      columns: [
        { label: "رقم الطلب", field: "id" },
        { label: "القسم", field: "category" },
        { label: "العنوان", field: "title" },
        { label: "كود الشحن", field: "package_code" },
        { label: "الحالة", field: "status" },
        { label: "أخر تحديث", field: "updated_at" },
      ],
      statuses: [
        // TODO get status data
        { id: 0, title: "الكل" },
        { id: 1, title: "مفتوحة" },
        { id: 2, title: "معلقة" },
        { id: 3, title: "مغلقة" },
      ],
      rows: [],
      isLoading: true,
      totalRecords: 0,
      status: { id: 0, title: "الكل" },
      serverParams: {
        currentPage: 1, // what page I want to show
        page: 1, // what page I want to show
        perPage: 10, // how many items I'm showing per page
      },
    };
  },
  methods: {
    loadItems() {
      let _this = this;
      _this.isLoading = true;
      totop();
      instance
        .get(
          `/customer/tickets?page=${this.serverParams.page}${
            this.status && this.status.id != 0
              ? `&status=${this.status.id}`
              : ""
          }`
        )
        .then(function (response) {
          _this.rows = [];
          _this.totalRecords = response.data.data.meta.total;
          response.data.data.data.forEach((ticket) => {
            _this.rows.push({
              id: ticket.id,
              title: ticket.title,
              category: ticket.category.title,
              status: ticket.status,
              package_code: ticket.package_code,
              // TODO show updated_at
              updated_at: ticket.category.updated_at,
            });
          });
        })
        .catch(function (error) {})
        .then(function () {
          _this.isLoading = false;
        });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },
    setStatus(selectStatus = null ) {
        if(selectStatus){
            this.status = selectStatus;
        }
      if (this.status) {
        this.serverParams.currentPage = 1;
        this.loadItems();
      }
    },
    onRowClick(params) {
      let id = params.row.id;
      this.$router.push({ path: `/tickets/${id}/show` });
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>

<style>
</style>