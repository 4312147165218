<template>
  <div class="card shadow">
    <div class="card-body">
      <div class="col-12 text-center p-5">
        <div class="row" v-if="user">
          <div class="col-md-4">
            <div class="profile-img">
              <img v-if="user.avatar" class="pimg img-thumbnail" :src="user.avatar" alt="" />
              <img v-else src="@/assets/images/user-blank.png" width="110" class="img-thumbnail" alt="" />
              <br />
              <Modal v-if="!user.avatar" id="upload_avatar_modal" :title="$t('edit_avatar')" type="primary"
                size="small">
                <a slot="btn"><span class="fa fa-edit"></span> </a>
                <div slot="body">
                  <input type="button" class="btn btn-light btn-block text-center border-me"
                    value="أضغط هنا لإختيار الصورة" onclick="document.getElementById('file').click();" />
                  <input type="file" id="file" required @change="onSelectFile" accept="image/*" style="display: none" />
                  <small v-if="errors['newAvatar']" class="text-right text-danger d-inline"><i
                      class="fa fa-exclamation-circle mx-2"></i>
                    {{ errors["newAvatar"] }}
                  </small>
                  <Button type="button" block spinner_size="20" :text="$t('save')" :isLoading="isBtnAvatarLoading"
                    @click="uploadAvatar()" />
                  <a class="btn btn-block btn-secondary btn-sm text-dark" data-dismiss="modal">{{ $t("close") }}</a>
                </div>
              </Modal>
            </div>
            <ul class="list-group list-group-numbered mt-2">
              <li class="list-group-item d-flex justify-content-between align-items-start p-2 list-radiues">
                <div class="ms-2 me-auto">
                  <strong class="fw-bold text-vanex">رقم المستخدم</strong>
                </div>
                <div class="ms-2 me-auto">
                  <strong class="fw-bold text-dark">{{ user.id }}</strong>
                </div>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start p-2 list-radiues">
                <div class="ms-2 me-auto">
                  <strong class="fw-bold text-vanex">الاسم</strong>
                </div>
                <div class="ms-2 me-auto">
                  <strong class="fw-bold text-dark">{{ user.name }}</strong>
                </div>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start p-2 list-radiues">
                <div class="ms-2 me-auto">
                  <strong class="fw-bold text-vanex">البريد الإلكتروني</strong>
                </div>
                <div class="ms-2 me-auto">
                  <strong class="fw-bold text-dark">{{ user.email }}</strong>
                </div>
              </li>
              <li v-if="user.region" class="list-group-item d-flex justify-content-between align-items-start p-2 list-radiues">
                <div class="ms-2 me-auto">
                  <strong class="fw-bold text-vanex">الفرع</strong>
                </div>
                <div class="ms-2 me-auto">
                  <strong class="fw-bold text-dark">{{ user.region ? user.region.name : '' }}</strong>
                </div>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start p-2 list-radiues">
                <div class="ms-2 me-auto">
                  <strong class="fw-bold text-vanex">نوع الحساب</strong>
                </div>
                <div class="ms-2 me-auto">
                  <strong class="fw-bold text-dark">
                    <strong class="bg-success-t2" v-if="user.identity">حساب موثق <a target="_blank" class="text-success" :href="user.identity"><i class="fa fa-eye"></i></a></strong>
                    <strong class="bg-danger-t2" v-else>حساب غير موثق</strong>
                  </strong>
                </div>
              </li>
              
            </ul>
          </div>
          <div class="col-lg-8">
            <div class="row">
              <div class="col-12">
                <div class="profile-head text-right">
                  <h2><img v-if="user.identity" class="d-inline" src="@/assets/images/icons-v2/verified.svg" width="20" alt=""> {{ user.name }} </h2>
                  <h6>{{ user.email }}</h6>
                 
                      <div class="d-inline" v-if="!user.identity">
                        <strong class="bg-warning-t2">توثيق الحساب</strong>
                        <Modal id="account_verification_modal" :title="$t('account_verification')" type="none"
                          size="small">
                          <a slot="btn" class="text-vanex mx-0"><span class="fa fa-edit"></span>
                          </a>
                          <div slot="body">
                            <input type="button" class="
                                btn btn-light btn-block
                                text-center
                                border-me
                              " value="أضغط هنا لإختيار إثبات الهوية"
                              onclick="document.getElementById('id_file').click();" />
                            <input type="file" id="id_file" required @change="onSelectIdentity" accept="image/*"
                              style="display: none" />
                            <small v-if="errors['newIdentity']" class="text-right text-danger d-inline"><i
                                class="fa fa-exclamation-circle mx-2"></i>
                              {{ errors["newIdentity"] }}
                            </small>
                            <Button block :text="$t('save')" :isLoading="isBtnIdentityLoading"
                              @click="uploadIdentity()" />
                            <a class="
                                btn btn-block btn-secondary btn-sm
                                text-dark
                              " data-dismiss="modal">{{ $t("close") }}</a>
                          </div>
                        </Modal>
                      </div>
              </div>
              </div>
              <hr />
              <div class="col-12" v-if="!isLoading && user">
                <div class="row" v-if="store">
                    <div class="col-12 text-right">
                      <div class="row">
                        <div class="col-md-6">
                          <label>رقم المتجر</label>
                        </div>
                        <div class="col-md-6">
                          <p>{{ store.id }}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label>إسم المتجر</label>
                        </div>
                        <div class="col-md-6">
                          <p>{{ store.name }}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label> الهاتف</label>
                        </div>
                        <div class="col-md-6">
                          <p>{{ store.phone }}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label> الهاتف الإحتياطي</label>
                        </div>
                        <div class="col-md-6">
                          <p>{{ store.phone_b }}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label>البريد الإلكتروني</label>
                        </div>
                        <div class="col-md-6">
                          <p>{{ store.email }}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label>الموقع الإلكتروني</label>
                        </div>
                        <div class="col-md-6">
                          <p>{{ store.website }}</p>
                        </div>
                      </div>
                      <div class="row" v-if="store.branch">
                        <div class="col-md-6">
                          <label>الفرع</label>
                        </div>
                        <div class="col-md-6">
                          <p>{{ store.branch ? store.branch.name : '' }}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label>المسؤول</label>
                        </div>
                        <div class="col-md-6">
                          <p>{{ store.contact_person }}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label>تاريخ الإنضمام</label>
                        </div>
                        <div class="col-md-6">
                          <p>{{ store.create_date }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="!isLoading && user && !store">
                    <div class="col-12 text-center">
                      <img src="@/assets/images/store-icon.png" width="120" alt="" />
                      <p class="text-muted my-2">
                        {{$t('store_add_details')}}
                      </p>
                      <router-link to="/create-store"><button class="btn btn-vanex">
                          {{$t('store_add_btn')}}
                        </button></router-link>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="!user">
          <div class="col-lg-4">
            <vanex-list-loading :rowcount="8" />
          </div>
          <div class="col-lg-8">
            <vanex-list-loading :rowcount="2" />
            <hr />
            <vanex-list-loading :rowcount="4" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import Button from "../components/public/Button.vue";
  import Input from "../components/public/Input.vue";
  import Modal from "../components/public/Modal.vue";
  import Spinner from "../components/public/Spinner.vue";
  import { instance } from "../modules/instance";

  export default {
    components: { Spinner, Modal, Input, Button },
    data() {
      return {
        user: null,
        store: null,
        email: null,
        isLoading: true,
        statistics: null,
        newAvatar: null,
        isBtnAvatarLoading: false,
        newIdentity: null,
        sentInvites: [],
        receivedInvites: [],
        isBtnIdentityLoading: false,
        isBtnInviteLoading: false,
        errors: {
          email: "",
          newAvatar: "",
          newIdentity: "",
        },
      };
    },
    methods: {
      getUserData() {
        let _this = this;
        _this.isLoading = true;
        instance
          .get("/user")
          .then(function (response) {
            _this.user = response.data.data;
          })
          .catch(function (error) {
            if (
              error.response &&
              error.response.data &&
              error.response.data.errors
            ) {
            }
          })
          .then(function () {
            
          });
         
          instance
          .get("/store")
          .then(function (response) {
            _this.store = response.data.data;
          })
          .catch(function (error) {
            if (
              error.response &&
              error.response.data &&
              error.response.data.errors
            ) {
            }
          })
          .then(function () {
            
          });
           
        instance
          .get("/statistics")
          .then(function (response) {
            _this.statistics = response.data.data;
          })
          .catch(function (error) {
            if (
              error.response &&
              error.response.data &&
              error.response.data.errors
            ) {
            }
          })
          .then(function () {
            _this.isLoading = false;
          });
        
      },
      uploadAvatar() {
        let errors = 0;
        if (!this.newAvatar) {
          errors++;
          this.errors["newAvatar"] = this.$t("required", {
            title: this.$t("avatar"),
          });
        } else {
          this.errors["newAvatar"] = "";
        }
        if (errors <= 0) {
          let _this = this;
          _this.isBtnAvatarLoading = true;
          const fd = new FormData();
          fd.append("image", this.newAvatar, this.newAvatar.name);
          instance
            .post("/upload/avatar", fd)
            .then(function (response) {
              _this.$toasted.show(response.data.message, {
                type: "success",
              });
            })
            .catch(function (error) {
              if (
                error.response &&
                error.response.data &&
                error.response.data.errors
              ) {
                for (const [key, value] of Object.entries(
                  error.response.data.errors
                )) {
                  _this.errors[key] = value[0];
                }

                _this.needResendOtpCode = error.response.data.resend_otp_code
                  ? true
                  : false;
              }
            })
            .then(function () {
              _this.isBtnAvatarLoading = false;
              $("#upload_avatar_modal").modal("hide");
              _this.getUserData();
            });
        }
      },
      uploadIdentity() {
        let errors = 0;
        if (!this.newIdentity) {
          errors++;
          this.errors["newIdentity"] = this.$t("required", {
            title: this.$t("identity"),
          });
        } else {
          this.errors["newIdentity"] = "";
        }
        if (errors <= 0) {
          let _this = this;
          _this.isBtnIdentityLoading = true;
          const fd = new FormData();
          fd.append("image", this.newIdentity, this.newIdentity.name);
          instance
            .post("/upload/identity", fd)
            .then(function (response) {
              _this.$toasted.show(response.data.message, {
                type: "success",
              });
            })
            .catch(function (error) {
              if (
                error.response &&
                error.response.data &&
                error.response.data.errors
              ) {
                for (const [key, value] of Object.entries(
                  error.response.data.errors
                )) {
                  _this.errors[key] = value[0];
                }

                _this.needResendOtpCode = error.response.data.resend_otp_code
                  ? true
                  : false;
              }
            })
            .then(function () {
              _this.isBtnIdentityLoading = false;
              $("#account_verification_modal").modal("hide");
              _this.getUserData();
            });
        }
      },
      onSelectFile(event) {
        this.newAvatar = event.target.files[0];
      },
      onSelectIdentity(event) {
        this.newIdentity = event.target.files[0];
      },
    },

    mounted() {
      this.getUserData();
    },

    created() { },
  };
</script>

<style>
</style>