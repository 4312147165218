<template>
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div class="card shadow">
        <div class="card-header card-header-primary">
          <div class="row">
            <div class="col-10 d-inline">
              <h4 class="card-title d-inline">{{$t('transactions_reports')}}</h4>
            </div>
            <div class="col-2 text-left d-inline"><button @click="print" class="btn btn-light d-inline"><i
                  class="fa fa-print"></i></button></div>
          </div>
        </div>
        <div class="card-body" dir="rtl">
          <vanex-list-loading v-if="isLoading" :rowcount="10" />
          <div class="table-responsive" v-else>
            <vue-good-table :columns="columns" :rows="rows" :rtl="true" styleClass="vgt-table  bordered"
              :search-options="{
                enabled: false,
              }" :sort-options="{
                enabled: false,
              }" :pagination-options="{
                enabled: true,
                mode: 'pages',
                perPageDropdownEnabled: false,
                perPage: 10,
                position: 'bottom',
                perPageDropdown: [3, 7, 9],
                dropdownAllowAll: true,
                setCurrentPage: 1,
                nextLabel: 'التالي',
                prevLabel: 'السابق',
                rowsPerPageLabel: '',
                ofLabel: 'من',
                pageLabel: 'صفحة',
                allLabel: 'الكل',
            }">
            </vue-good-table>
          </div>
        </div>
        <print-layout id="printMe">
          <div class="text-center my-2"><h5>كشف حساب المتجر من تاريخ {{fromDate}} الي تاريخ {{toDate}}</h5></div>
          <table class="table table-bordered" dir="rtl">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">تاريخ المعاملة</th>
                <th scope="col">وصف المعاملة</th>
                <th scope="col">رقم المستند</th>
                <th scope="col">المبلغ</th>
                <th scope="col">نوع الحركة</th>
                <th scope="col">الرصيد</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in rows" :key="row.id">
                <th scope="row">{{row.id}}</th>
                <td>{{row.date}}</td>
                <td>{{row.description}}</td>
                <td>{{row.del_id}}</td>
                <td>{{row.amount}}</td>
                <td>{{row.type}}</td>
                <td>{{row.balance}}</td>
              </tr>
            </tbody>
          </table>
        </print-layout>
      </div>
    </div>
  </div>
</template>

<script>
  import { isValidDate, totop } from '../../modules/helpers';
  import { instance } from '../../modules/instance';
  export default {
    data() {
      return {
        isLoading: true,
        totalRecords: 0,
        totalPrice: 0,
        date: '',
        totalBalance: 0,
        columns: [
          { label: "#", field: "id" },
          { label: "تاريخ المعاملة", field: "date" },
          { label: "وصف المعاملة", field: "description" },
          { label: "رقم المستند", field: "del_id" },
          { label: "المبلغ", field: "amount" },
          { label: "نوع الحركة", field: "type" },
          { label: "الرصيد", field: "balance" },
        ],
        rows: [],
        serverParams: {
          currentPage: 1,
          page: 1, // what page I want to show
          perPage: 10 // how many items I'm showing per page
        },
        fromDate: null,
        toDate: null,
      };
    },
    methods: {
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      async print() {
        // Pass the element id here
        await this.$htmlToPaper('printMe');
      },
      onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems();
      },
      onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage });
        this.loadItems();
      },
      // load items is what brings back the rows from server
      loadItems() {
        this.fromDate = this.$route.query['from-date'];
        this.toDate = this.$route.query['to-date'];
        if (!this.fromDate || !this.toDate || !isValidDate(this.$route.query['from-date']) || !isValidDate(this.$route.query['to-date'])) {
          this.$router.push({ path: '/reports' })
        }
        let _this = this;
        _this.isLoading = true;
        instance
          .get(`/store/transactions?fd=${_this.fromDate}&td=${_this.toDate}`)
          .then(function (response) {
            _this.rows = []
            let i = 1;
            _this.rows = response.data.data.data;

          })
          .catch(function (error) {
            // handle error

          })
          .then(function () {
            _this.isLoading = false;
            totop()
          });
      },
    },
    mounted() {
      let user = this.$store.state.auth.user;
      this.user = user
      if(user && user.type != 1){
        this.$router.push({ path: '/reports' })
      }
      this.loadItems()
    }
  };
</script>