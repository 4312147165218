<template>
  <div class="row justify-content-center h-100">
    <div class="col-lg-12">
      <div class="card shadow">
          <img src="@/assets/images/support-desktop.png" class="card-img-top d-none d-md-block d-lg-block d-xl-block " alt="">
          <img src="@/assets/images/support-mobile.png" class="card-img-top d-block d-sm-none" alt="">
        <div class="card-body">
          <div class="row mt-2">
            <div class="col-lg-6 my-0">
              <router-link to="/manual" >
              <div class="card border-1 my-1 ">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-4">
                       <img src="@/assets/images/icons-v2/faq.png" width="100%" alt="" />
                    </div>
                    <div class="col-8">
                      <h4 class="text-vanex"><strong>الاسئلة الشائعة</strong></h4>
                      <p class="text-muted">جمعنا أهم الأسئلة والإجابات حول الوصول إلى تطبيقات شركة VANEX وخدماتها</p>
                    </div>
                  </div>
                </div>
              </div>
              </router-link>
            </div>
            <div class="col-lg-6 my-0">
              <router-link to="/tickets" >
              <div class="card border-1 my-1 ">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <img src="@/assets/images/icons-v2/tickets.png" width="100%" alt="">
                    </div>
                    <div class="col-8">
                      <h4 class="text-vanex"><strong>تذاكر الدعم</strong></h4>
                      <p class="text-muted">إفتح تذكرة دعم حتي نحل لك مشكلتك</p>
                    </div>
                  </div>
                </div>
              </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style>
</style>