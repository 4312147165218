<template>
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div class="card shadow">
        <div class="card-header card-header-primary">
          <h4 class="card-title">{{$t('settelments_reports')}}</h4>
        </div>
        <div class="card-body" dir="rtl">
          <vanex-list-loading  v-if="isLoading" :rowcount="10" />
          <div class="table-responsive" v-else>
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :rtl="true"
              styleClass="vgt-table  bordered"
              :search-options="{
                enabled: false,
              }"
              :sort-options="{
                enabled: false,
              }"
            >
            </vue-good-table>
            <div class="row">
              <div class="col-lg-4">
                <div class="card bg-primary">
                  <div class="card-body">
                    <small>إجمالي القيمة</small><br/>
                    <h3 class="d-inline">{{totalPrice}}</h3>  <small class="mx-1 d-inline">د.ل</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isValidDate, totop } from '../../modules/helpers';
import { instance } from '../../modules/instance';
export default {
  data() {
    return {
      columns: [
        { label: "#", field: "id" },
        { label: "إشاري التسوية", field: "settlementSignals" },
        { label: "تاريح التسوية", field: "settlementTime" },
        { label: "القيمة", field: "amount" },
        { label: "الملاحظات", field: "notes" },
        { label: "الفرع", field: "branch" },
      ],
      rows: [],
      isLoading: true,
      totalRecords: 0,
      totalPrice: 0,
      fromDate: null,
      toDate: null,
      serverParams: {
        currentPage: 1, // what page I want to show
        page: 1, // what page I want to show
        perPage: 10, // how many items I'm showing per page
      },
    };
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },
    // load items is what brings back the rows from server
    loadItems() {
      this.fromDate=this.$route.query['from-date'];
      this.toDate=this.$route.query['to-date'];
      if(!this.fromDate || !this.toDate || !isValidDate(this.$route.query['from-date']) || !isValidDate(this.$route.query['to-date'])){
           this.$router.push({path:'/reports'})
      }
      let _this = this;
      _this.isLoading =true;
        instance
          .get(`/store/settelmets?fd=${_this.fromDate}&td=${_this.toDate}`)
          .then(function (response) {
            _this.rows = []
            let i = 1;
            let totalPrice = 0;
            response.data.data.forEach(storeSettelmet => {
              _this.rows.push({
                  id: i,
                  settlementSignals: storeSettelmet.id,
                  settlementTime: (storeSettelmet.date) ? storeSettelmet.date.split(' ')[0] : '',
                  amount: `${storeSettelmet.amount} د.ل`,
                  notes: storeSettelmet.note,
                  branch: storeSettelmet.branch ? storeSettelmet.branch.name : '' ,
               })
               i++;
               totalPrice+= parseFloat(storeSettelmet.amount);
            });
            _this.totalPrice = totalPrice;
           
          })
          .catch(function (error) {
            // handle error
            
          })
          .then(function () {
            _this.isLoading = false;
               totop()
          });
    },
  },
  mounted(){
    let user = this.$store.state.auth.user;
    this.user = user
    if(user && user.type != 1){
      this.$router.push({ path: '/reports' })
    }
    this.loadItems()
  }
};
</script>
