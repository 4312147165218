<template>
  <div class="mt-1">
   <page-loading :show="statisticsLoading"></page-loading>
    <div class="row" v-if="!statisticsLoading">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats shadow">
          <div class="card-header card-header-success card-header-icon p-1">
            <div class="card-icon">
              <img src="@/assets/images/icons/check.svg" width="60" alt="" />
            </div>
            <p class="card-category">بالمسار الصحيح</p>
            <h3 class="card-title">
              {{ statistics.onTrack }}
              <small>شحنة</small>
              
            </h3>
            <small><router-link to="/packages?t=on-track" class="text-primary pb-2 pr-2">التفاصيل</router-link></small>
          </div>
          
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats shadow">
          <div class="card-header card-header-warning card-header-icon p-1">
            <div class="card-icon">
              <img src="@/assets/images/icons/refunded.svg" width="60" alt="" />
            </div>
            <p class="card-category">قيد الاسترداد</p>
            <h3 class="card-title">
              {{ statistics.return }}
              <small>شحنة</small>
            </h3>
            <small><router-link to="/packages?t=return" class="text-primary pb-2 pr-2">التفاصيل</router-link></small>
          </div>
          
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats shadow">
          <div class="card-header card-header-danger card-header-icon p-1">
            <div class="card-icon">
              <img src="@/assets/images/icons/error.svg" width="60" alt="" />
            </div>
            <p class="card-category">المتعذر توصيلها</p>
            <h3 class="card-title">
              {{ statistics.cantDeliver }}
              <small>شحنة</small>
            </h3>
            <small><router-link to="/packages?t=cant-delivery" class="text-primary pb-2 pr-2">التفاصيل</router-link></small>
          </div>
          
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats shadow">
          <div class="card-header card-header-primary card-header-icon p-1">
            <div class="card-icon">
              <img src="@/assets/images/icons/money.svg" width="60" alt="" />
            </div>
            <p class="card-category">التسوية المالية</p>
            <h3 class="card-title">
              {{ statistics.pendingStore }}
              <small>شحنة</small>
            </h3>
            <small><router-link to="/packages?t=pending-store" class="text-primary pb-2 pr-2">التفاصيل</router-link></small>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { instance } from "../modules/instance";
 import { cryptText } from "../modules/crypt";
export default {
  name: "statistics",
  data() {
    return {
      statisticsLoading: true,
      statistics: {
        onTrack: 0,
        return: 0,
        cantDeliver: 0,
        pendingStore: 0,
      },
    };
  },
  methods: {
    getStatistics() {
      let _this = this;
      _this.statisticsLoading = true;
      instance
        .get("/customer/packages/count")
        .then(function(response) {
          localStorage.removeItem('scount')
          
          _this.statistics.onTrack = response.data.onTrack;
          _this.statistics.return = response.data.shipDelReturn;
          _this.statistics.cantDeliver = response.data.enableDilevery;
          _this.statistics.pendingStore = response.data.pendingStoreSett;
          let statisticsCount = [
            response.data.onTrack ,
            response.data.shipDelReturn ,
            response.data.enableDilevery ,
            response.data.pendingStoreSett 
          ]

          
          
           localStorage.setItem('scount',cryptText(JSON.stringify(statisticsCount)))
           
          
      

        })
        .catch(function() {
          _this.$toasted.show("حدث خطا اثناء جلب إحصائيات الشحنات", {
            type: "error",
          });
        })
        .then(function() {
          _this.statisticsLoading = false;
        });
    },
  },
  mounted() {
    this.getStatistics();
  },
};
</script>

<style></style>
