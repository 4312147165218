<template>
    <div class="container">
        <div class="row justify-content-center h-100">
            <div class="col-lg-12">
                <div class="card shadow">
                    <div class="card-header card-header-primary">
                        <h4 class="card-title">{{$t('show_package_details')}}</h4>
                    </div>
                    <div class="card-body " v-if="packageData">
                        <div class="panel-body">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-12 text-center my-3" v-if="packageData.photo">
                                        <silent-box :image="{src:packageData.photo}" />
                                    </div>
                                    <div class="col-12 text-center my-3" v-else>
                                        <img width="150" src="@/assets/images/icons/vnx-blank.jpg" alt="" />
                                    </div>

                                    <div class="table-responsive">
                                        <h4 class="text-vanex heading-info"> {{$t('package_info')}} </h4>
                                        <div class="table-responsive">
                                            <div class="text-center">
                                                <a href=""><img src="img" alt=""></a>
                                            </div>
                                            <table class="table table-bordered">
                                                <tbody>
                                                    <tr class="bg-vanex-light">

                                                        <th class="text-vanex">#</th>
                                                        <th class="text-vanex">نوع الشحن</th>
                                                        <th class="text-vanex">الوصف</th>
                                                        <th class="text-vanex">المرسل</th>
                                                        <th class="text-vanex">المستلم</th>
                                                        <th class="text-vanex" colspan="2">رقم الهاتف</th>
                                                        <th class="text-vanex">الكمية</th>
                                                        <th class="text-vanex">المدينة</th>
                                                        <th class="text-vanex">العنوان</th>
                                                        <th class="text-vanex">الحجم</th>
                                                        <th>الخريطة</th>

                                                    </tr>

                                                    <tr>
                                                        <td style="direction:ltr; text-align:center" dir="ltr">
                                                            {{packageData['package-code']}}</td>
                                                        <td>{{(packageData.package_type) ? packageData.package_type.name
                                                            : ''}}</td>
                                                        <td>{{packageData.description}}</td>
                                                        <td>{{packageData.sender ? packageData.sender.name : ''}}</td>
                                                        <td>{{packageData.reciever}}</td>
                                                        <td>{{packageData.phone}}</td>
                                                        <td>{{packageData.phone_b}}</td>
                                                        <td>{{packageData.qty}}</td>
                                                        <td>{{packageData.City ? packageData.City.name : ''}}</td>
                                                        <td>{{packageData.address}}</td>
                                                        <td>
                                                            <span class="badge badge-primary">{{packageData.leangh}}</span> x 
                                                            <span class="badge badge-primary">{{packageData.width}}</span>  x 
                                                            <span class="badge badge-primary">{{packageData.height}}</span>
                                                        </td>
                                                        <td><a target="_blank" v-if="packageData.map"
                                                                :href="packageData.map" class="btn btn-vanex btn-sm "><i
                                                                    class="fa fa-map"></i></a></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <h4 class="text-vanex heading-info"> {{$t('operation')}} </h4>
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <tbody>
                                                    <tr class="bg-vanex-light">
                                                        <th class="text-vanex">الحالة</th>
                                                        <th class="text-vanex">تاريخ الإضافة</th>
                                                        <th class="text-vanex">الاستلام</th>
                                                        <th class="text-vanex">التسليم للزبون</th>
                                                        <th class="text-vanex">اخر تحديث</th>

                                                    </tr>
                                                    <tr>
                                                        <td>{{packageData.status}}</td>
                                                        <td>{{packageData.create_date ? packageData.create_date :
                                                            packageData.created_at}}</td>
                                                        <td>{{packageData.instore_date}}</td>
                                                        <td>{{packageData.delivery_date}}</td>
                                                        <td>{{packageData.update_date ? packageData.update_date :
                                                            packageData.updated_at}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <h4 class="text-vanex heading-info"> {{$t('financial_details')}} </h4>
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <tbody>
                                                    <tr class="bg-vanex-light">
                                                        <th class="text-vanex">
                                                            <span
                                                                v-if="packageData.package_type && packageData.package_type.id==3">قيمة
                                                                الورقة</span>
                                                            <span v-else>قيمة الشحنة</span>
                                                        </th>
                                                        <th class="text-vanex">سعر الشحن</th>
                                                        <th class="text-vanex">عمولة الحجم</th>
                                                        <th class="text-vanex">عمولة التحصيل</th>
                                                        <th class="text-vanex">إجمالي الشحن</th>
                                                        <th class="text-vanex">الاجمالي</th>
                                                    </tr>
                                                    <tr>
                                                        <td>{{(packageData.price) ? packageData.price : 0}} {{$t('libyan_dinar')}}</td>
                                                        <td>{{packageData.City ? packageData.City.price : ''}} {{$t('libyan_dinar')}}</td>
                                                        <td>{{packageData.extra_size_price}} {{$t('libyan_dinar')}}</td>
                                                        <td>{{packageData.region_commission}} {{$t('libyan_dinar')}}
                                                        </td>

                                                        <td>{{(packageData.shippment) ? packageData.shippment : 0}} {{$t('libyan_dinar')}}</td>
                                                        <td>{{(packageData.total) ? packageData.total : 0}}
                                                            {{$t('libyan_dinar')}}</td>
                                                        

                                                    </tr>

                                                </tbody>
                                            </table>

                                            <table class="table table-bordered">
                                                <tbody>
                                                    <tr class="bg-vanex-light">
                                                        <th class="text-vanex">علي حساب</th>
                                                        <th class="text-vanex">عمولة الحجم علي حساب</th>
                                                        <th class="text-vanex">عمولة التحصيل علي حساب</th>
                                                        <th class="text-vanex">المبلغ المستلم</th>
                                                        <th class="text-vanex">طريقة الدفع</th>
                                                        
                                                    </tr>
                                                    <tr>

                                                        <td>{{ byPerson(packageData.paid_by) }}</td>
                                                        <td>{{ byPerson(packageData.extra_size_by) }}</td>
                                                        <td>{{ byPerson(packageData.commission_by) }}</td>
                                                        <td>{{(packageData.recieved_money)
                                                            ?packageData.recieved_money:0}} {{$t('libyan_dinar')}}</td>
                                                        <td>{{packageData.payment_methode == 'cash' ? $t('cash') :
                                                            $t('cheque') }} </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                            <h4 class="text-vanex heading-info"> {{$t('addons_info')}} </h4>
                                            <table class="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td>الملاحظات</td>
                                                        <td>{{(packageData.sticker_notes != 'null' &&
                                                            packageData.sticker_notes) ? packageData.sticker_notes :
                                                            ''}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <h4 class="text-vanex heading-info"> {{$t('package_logs')}} </h4>
                                        <div class="rtl">
                                            <div id="content" class="">
                                                <ul class="timeline">
                                                    <li class="event d-block my-3" v-for="(log,index) in logs"
                                                        :key="index">
                                                        <strong class="d-block text-vanex">{{log.details}}</strong>
                                                        <small class="mt-0">
                                                            <custom-date :date="log.created_at"></custom-date>
                                                        </small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="card-body" v-else>
                        <vanex-list-loading :rowcount="10" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Date from '../../components/public/date.vue'
    import { by, totop } from '../../modules/helpers'
    import { instance } from '../../modules/instance'
    export default {
        data() {
            return {
                code: '',
                logs: [],
                packageData: null,
            }
        },
        methods: {
            getPackage() {
                // TODO get branch obj
                // TODO get image
                // TODO get map
                if (!this.$route.params.code) {
                    this.$router.push({ path: '/packages' })
                    return false;
                }

                let _this = this
                let code = this.$route.params.code
                this.code = code
                instance
                    .get(`/customer/package/${code}`)
                    .then((response) => {
                        _this.packageData = response.data.data
                    })
                    .catch(function () {
                        _this.$router.push({ path: '/packages' })
                    })
                    .then(function () {

                    });
                _this.getPackageLogs()

            },
            getPackageLogs() {
                // customer/package/192-BEN-439553/logs
                let _this = this
                instance
                    .get(`/customer/package/${_this.code}/logs`)
                    .then((response) => {
                        _this.logs = response.data.data
                    })
                    .catch(function () {
                        _this.$router.push({ path: '/packages' })
                    })
                    .then(function () {

                    });
            },
            byPerson(byData) {
                return by(byData)
            }
        },
        mounted() {
            this.getPackage()
            totop()
        }
    }
</script>

<style>

</style>