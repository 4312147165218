var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-md-12"},[_c('div',{staticClass:"card shadow"},[_vm._m(0),_c('div',{staticClass:"card-body table-responsive",attrs:{"dir":"rtl"}},[(_vm.isLoading)?_c('vanex-list-loading',{attrs:{"rowcount":this.serverParams.perPage}}):_vm._e(),(!_vm.isLoading)?_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.rows,"rtl":true,"isLoading":_vm.isLoading,"styleClass":"vgt-table  bordered","pagination-options":{
              enabled: (this.totalRecords / this.serverParams.perPage > 1) ? true : false ,
              mode: 'pages',
              perPageDropdownEnabled: false,
              perPage: this.serverParams.perPage,
              position: 'bottom',
              perPageDropdown: [3, 7, 9],
              dropdownAllowAll: true,
              setCurrentPage: this.serverParams.page,
              nextLabel: 'التالي',
              prevLabel: 'السابق',
              rowsPerPageLabel: 'عدد الصفوف في الصفحة',
              ofLabel: 'من',
              pageLabel: 'صفحة', 
              allLabel: 'الكل',
              
          },"search-options":{
                  enabled: true,
                  trigger: 'enter',
                  skipDiacritics: true,
                  placeholder: 'أبحث عن المدينة هنا',
                  
                  
          },"sort-options":{
              enabled: false,
          },"totalRows":_vm.totalRecords},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-search":_vm.onPriceSearch},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'id')?_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getPaginateIndex(props.row.originalIndex, _vm.serverParams.page, _vm.serverParams.perPage)))])]):(props.column.field == 'name')?_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(props.row.name))])]):(props.column.field == 'price')?_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(props.row.price)+" "),_c('small',{staticClass:"text-muted"},[_vm._v("د.ل")])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,1664160384)},[_c('div',{staticClass:"text-primary text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" لا توجد أسعار لعرضها ")])]):_vm._e()],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header card-header-primary"},[_c('h4',{staticClass:"card-title"},[_vm._v("أسعار الشحن")])])}]

export { render, staticRenderFns }