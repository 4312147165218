var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card shadow"},[_c('div',{staticClass:"card-header card-header-primary "},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t('packages_report')))])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row justify-content-center"},[(_vm.rows && !_vm.isLoading)?_c('div',{staticClass:"col-lg-12 col-md-12"},[_c('div',{staticClass:"table-responsive"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"rtl":true,"styleClass":"vgt-table  bordered","pagination-options":{
                  enabled: true,
                  mode: 'pages',
                  perPageDropdownEnabled: false,
                  perPage: 10,
                  position: 'bottom',
                  perPageDropdown: [3, 7, 9],
                  dropdownAllowAll: true,
                  setCurrentPage: 1,
                  nextLabel: 'التالي',
                  prevLabel: 'السابق',
                  rowsPerPageLabel: '',
                  ofLabel: 'من',
                  pageLabel: 'صفحة',
                  allLabel: 'الكل',
                },"search-options":{
                  enabled: true,
                },"sort-options":{
                  enabled: true,
                }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_vm._t("default",null,{"props":props}),(props.column.field == 'package_code')?_c('div',[_c('router-link',{attrs:{"to":("/packages/" + (props.row.package_code) + "/show")}},[_c('span',{attrs:{"dir":"ltr"}},[_vm._v(_vm._s(props.row.package_code))])])],1):(props.column.field == 'dimensions')?_c('div',[_c('span',{attrs:{"dir":"ltr"}},[_vm._v(_vm._s(props.row.dimensions))])]):_vm._e()]}}],null,true)},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('alert',{attrs:{"message":"لا توجد شحنات لعرضها","type":"vanex"}})],1)])],1)]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"col-12"},[_c('vanex-list-loading',{attrs:{"rowcount":10}})],1):_vm._e()])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }