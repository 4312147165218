<template>
  <div>{{ arabicStatus }}</div>
</template>

<script>
export default {
  props: ["status"],
  data() {
    return {
      arabicStatus: "",
    };
  },
  methods: {
    transStatus() {
      switch (this.status) {
        case "store_new":
          return "قيد الإجراء";

        case "store_canceled":
          return "شحنة ملغية";

        case "store_return":
          return "شحنة مستردة";

        case "store_rejected":
          return "شحنة مرفوضة";
          
        case "ship_received":
          return "قيد التجهيز";

        case "ship_preperation":
          return "إنتظار الشحن";

        case "ship_del_return":
          return "قيد الاسترداد";

        case "ship_incoming_back":
          return "قيد الشحن العكسي";

        case "ship_del_pending":
          return "قيد المراجعة بالمستودع";

        case "pending_branch_sett":
          return "قيد التسوية المالية";

        case "pending_office_sett":
          return "قيد التسوية المالية";

        case "pending_store_sett":
          return "تحت تسوية الشركة";

        case "ship_pending":
          return "قيد الإنتظار";

        case "ship_ongoing":
          return "قيد الشحن";

        case "pending_pre_branch_sett":
          return "قيد تسوية الفروع";

        case "complete":
          return "المكتملة";

        case "issue":
          return "قيد التحقيق";

        default:
          return "حالة غير مسجلة - الرجاء الاتصال بالدعم الفني";
      }
    },
  },
  mounted() {
    this.arabicStatus = this.transStatus();
  },
};
</script>

<style></style>
