<template>
        <div class="d-inline">

            <!-- Button trigger modal -->
            <button v-if="!withoutbtn" :disabled="disabled" :id="`btn_${id}`" type="button" class="btn " :class="{ 'btn-danger': type==='danger','btn-success': type==='success' ,'btn-vanex': type==='primary'  ,'btn-link': type==='link' ,'btn-info': type==='info' ,'btn-warning': type==='warning' ,'btn-text': type==='none' ,'btn-block': btnblock==true  }" data-toggle="modal" :data-target="`#${id}`">
                <slot name="btn"></slot>
            </button>

            <!-- Modal -->
            <div class="modal fade "  style="display: none"  :class="{'modal-full-screen':size=='fullscreen'}" :id="`${id}`" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div  class="modal-dialog modal-dialog-centered "  :class="{ 'modal-xl': size==='large' , 'modal-sm': size==='small', 'modal-dialog-full-screen' : size==='fullscreen'  }"  role="document">
                    <div class="modal-content" :class="{'modal-content-full-screen':size=='fullscreen'}" >
                        <div class="modal-header" dir="ltr">
                            <h5 class="modal-title text-white" id="exampleModalLabel">{{title}}</h5>
                            <div class="d-inline text-right">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                        </div>
                        <div class="modal-body" :class="{'modal-body-full-screen':size=='fullscreen'}">
                            <slot name="body"></slot>
                        </div>

                    </div>
                </div>
            </div>
        </div>

</template>

<script>
    export default {

        name: "modal",
        props: {
            id: String,
            type: String,
            title: String,
            size: String,
            disabled: Boolean,
            btnblock: Boolean,
            withoutbtn: Boolean,
        },

        mounted(){
           // $('#'+this.id).modal('toggle')
        //    $('#'+this.id).on("show",function(){
        //         $("#myModal").css("display", "block");
        //     });
        }

    }
</script>

<style scoped>

</style>
