<template>
  <div class="row justify-content-center">
        <div class="col-lg-12 col-md-12">
            <div class="card shadow">
                <div class="card-body">
                    <div class="text-center">
                        <img src="@/assets/images/icons-v2/success.svg" height="200" width="400" />
                    <h4 class="text-vanex text-bold ">{{$t('success_create_package')}}</h4>
                    <p class="text-muted ">{{$t('success_create_package_details')}}</p>
                    <h4 dir="ltr">{{code}}</h4>
                    <router-link :to="`/packages/new?tid=${tid}`"><button class="btn btn-light ">{{$t('add_another_package')}}</button></router-link>
                    <router-link :to="`/packages/${code}/show`"><button class="btn btn-vanex">{{$t('show_package_details')}}</button></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data : function () {
        return{
            code : '',
            tid : '',
        }
    },
    methods:{
        getPackageDetails(){
            // TODO error  in {MY VANEX } package code
            if (!this.$route.params.code) {
                    this.$router.push({ path: '/packages' })
            }
            this.code = this.$route.params.code
            this.tid = (this.$route.query.t) ? this.$route.query.t : null
        }
    },
    mounted(){
        this.getPackageDetails()
    }
}
</script>

<style>

</style>