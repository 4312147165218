<template>
  <div>
    <div v-if="type === 'danger'" class="alert text-white alert-danger py-2 mb-0" :class="{'text-center':center}" dir="rtl">
      <small>
       <i class="fa fa-times-circle mx-1 text-white"></i> {{message}}
      </small>
    </div>
    <div v-if="type === 'success'" class="alert alert-success py-2 mb-0" :class="{'text-center':center}" dir="rtl">
      <small>
         {{message}}
      </small>
    </div>
    <div v-if="type === 'info'" class="alert alert-info py-2 mb-0" :class="{'text-center':center}" dir="rtl">
      <small>
         {{message}}
      </small>
    </div>
    <div v-if="type === 'warning'" class="alert alert-warning py-2 mb-0" :class="{'text-center':center}" dir="rtl">
      <small>
         {{message}}
      </small>
    </div>
    <div v-if="type === 'vanex'" class="alert bg-vanex-light py-2 mb-0" :class="{'text-center':center}" dir="rtl">
      <small>
        <i class="fa fa-info-circle mx-1"></i> {{message}} <slot></slot>
      </small>
    </div>
  </div>
</template>

<script>
export default {
    props:['type','message','center']
};
</script>

<style></style>
