<template>
  <div class="d-inline">
      <img v-if="type == 1" src="@/assets/images/loading.gif" :width="(this.size) ? this.size : 20" alt="">
      <img v-if="type == 2" src="@/assets/images/loading-white.gif"  :width="(this.size) ? this.size : 20" alt="">
  </div>
</template>

<script>
export default {
    name : 'Spinner',
    props:['type','size']
}
</script>

<style>

</style>