import {decryptText} from '../modules/crypt'
export default {
  namespaced: true,
  state: {
    isAuth: localStorage.getItem("access_token") !== null ? true : false,
    isVerification: localStorage.getItem("register-email") !== null ? true : false,
    access_token: decryptText(localStorage.getItem("access_token")) ?? null,
    user:
      localStorage.getItem("user") !== null
        ? JSON.parse(decryptText(localStorage.getItem("user")))
        : null,
  },
  
  getters: {
    loggedIn(state) {
      return state.isAuth;
    },
    isVerification(state) {
      return state.isVerification;
    },
  },
};
