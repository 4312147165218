<template>
  <form class="form-inline my-0 p-3 text-center justify-content-center">
    <input
      class="form-control mr-sm-2 bg-white border-r-1 p-1 text-center"
      v-model="package_code"
      dir="ltr"
      type="search"
      v-on:keyup.enter="checkPackage()"
      :placeholder="$t('enter_package_code')"
      aria-label="Search"
    />
    <button
      @click="checkPackage()"
      type="button"
      class="btn btn-light my-2 my-sm-0"
    >
     <spinner v-if="isBtnLoading" type="1" :size="17" />
      <i class="fa fa-search text-vanex" v-if="!isBtnLoading"></i>
    </button>
  </form>
</template>

<script>
import { totop } from '../modules/helpers';
import { instance } from '../modules/instance';
import Spinner from './public/Spinner.vue';
export default {
  components: { Spinner },
  data: function () {
    return {
      isBtnLoading: false,
      package_code: null,
      errors: {
        package_code: null,
      },
    };
  },
  methods: {
    checkPackage() {
    
      let errors = 0;
      if (!this.package_code) {
        this.$toasted.show('يرجى إدخال كود الشحنة', {
                  type: "error",
                });
                errors++;
      } else {
        this.errors["package_code"] = "";
      }
      if (errors == 0) {
        let _this = this;
        
        _this.isBtnLoading = true;
        instance
          .get(`/customer/package/${this.package_code}/check`)
          .then(function (response) {
            if (!response.data.errors) {
                _this.$router.push({
                    path: `/packages/${_this.package_code}/show`,
              });
                  _this.package_code = null;
              totop();
            } else {
              for (const [key, value] of Object.entries(response.data.errors)) {
                _this.$toasted.show(value[0], {
                  type: "error",
                });
              }
              if (response.data.errors["message"]) {
                _this.$toasted.show(response.data.errors["message"], {
                  type: "error",
                });
              }
            }
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.errors
            ) {
              for (const [key, value] of Object.entries(
                error.response.data.errors
              )) {
                _this.errors[key] = value[0];
              }
              if (error.response.data.errors["message"]) {
                _this.$toasted.show(error.response.data.errors["message"], {
                  type: "warning",
                });
              }
            }
          })
          .then(() => {
            _this.isBtnLoading = false;
          });
      }
    },
  },
};
</script>

<style>
</style>