<template>
  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12 ">
      <div class="card ">
        <div class="card-header card-header-primary">
          <h4 class="card-title">{{ $t("services") }}</h4>
        </div>
        <div class="card-body table-responsive" dir="rtl">
          <div class="text-center my-2" v-if="isLoading">
            <vanex-list-loading :rowcount="10" />
          </div>
          <div class="row" v-else>
            <div
              class="col-lg-3 col-md-4 col-sm-6 col-12 "
              v-for="service in services"
              :key="service.id"
            >
              <router-link :to="`/services/${service.id}/show`">
                <div class="card shadow">
                  <img
                    class="card-img-top"
                    v-if="service.featured_image"
                    :src="service.featured_image"
                    alt=""
                  />
                  <img
                    class="card-img-top"
                    v-else
                    src="@/assets/images/icons/vnx-blank.jpg"
                    alt=""
                  />
                  <div class="card-body">
                    <h5 class="card-title text-vanex">{{ service.title }}</h5>
                    <p class="card-text" v-html="(service.paragraph) ? service.paragraph.substring(0, 50) : null"></p>
                    <a href="#" class="text-vanex"
                      ><i
                        style="font-size: 13px"
                        class="fa fa-arrow-left mx-1"
                      ></i
                    ></a>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="card shadow" >
        <div class="card-header card-header-primary">
          <h4 class="card-title">{{ $t("addons_services") }}</h4>
        </div>
        <div class="card-body table-responsive" dir="rtl">
          <div class="text-center my-2" v-if="isLoading">
            <vanex-list-loading :rowcount="10" />
          </div>
          <div class="row" v-else>
            <div
              class="col-lg-3 col-md-4 col-sm-6 col-12 shadow"
              v-for="service in addonsServices"
              :key="service.id"
            >
              <router-link :to="`/services/${service.id}/show`">
                <div class="card ">
                  <img
                    class="card-img-top"
                    v-if="service.featured_image"
                    :src="service.featured_image"
                    alt=""
                  />
                  <img
                    class="card-img-top"
                    v-else
                    src="@/assets/images/icons/vnx-blank.jpg"
                    alt=""
                  />
                  <div class="card-body">
                    <h5 class="card-title text-vanex">{{ service.title }}</h5>
                    <p class="card-text" v-html="((service.paragraph) ? service.paragraph.substring(0, 50) : null)"></p>
                    <a href="#" class="text-vanex"
                      ><i
                        style="font-size: 13px"
                        class="fa fa-arrow-left mx-1"
                      ></i
                    ></a>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { instance } from "../../modules/instance";

import Spinner from "../../components/public/Spinner.vue";
export default {
  components: { Spinner },
  data() {
    return {
      services: [],
      addonsServices: [],
      isLoading: true,
    };
  },
  methods: {
    getServices() {
      let _this = this;
      _this.isLoading = true;
      instance
        .get("/ar/cms/category/1")
        .then(function (response) {
          _this.services = response.data.CmsCategory.cms;
        })
        .catch(function (error) {
          
          // _this.$router.push({ path: "/register" });
        })
        .then(() => {
          _this.isLoading = false;
        });
    },
    getAddonsServices() {
      let _this = this;
      _this.isLoading = true;
      instance
        .get("/ar/cms/category/5")
        .then(function (response) {
          _this.addonsServices = response.data.CmsCategory.cms;
        })
        .catch(function (error) {
         
          // _this.$router.push({ path: "/register" });
        })
        .then(() => {
          _this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getServices();
    this.getAddonsServices();
  },
};
</script>

<style>
</style>