<template>
  <div class="vertical-center">
    <div class="container">
      <div class="row justify-content-center h-100 ">
        <div class="col-lg-6">
          <div class="card  shadow">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-lg-6 bg-vanex text-white h-100 text-center login-image-container">
                  <img src="@/assets/images/top-login-circle.png" class="login-image-top" width="90" />
                  <h1 class="text-right mt-4 mx-3 h1">مرحبـــاً بك مرة أخرى</h1>
                  <img src="@/assets/images/login.svg" class="login-image-1" width="100%" alt="" />
                </div>
                <div class="col-lg-6 bg-white p-4">
                  <h4 class="text-vanex text-bold">تسجيل الدخول</h4>
                  <form @submit="loginUser" novalidate>
                    <Input :title="$t('email')" v-model="email" :error-message="errors" name="email"
                      type="email" />
                    <Input :title="$t('password')" v-model="password" :error-message="errors" name="password"
                      type="password" />
                    <router-link to="/forgot-password"><small class="text-vanex text-right ">نسيت كلمة المرور ؟</small></router-link>
                    <button class="btn btn-block btn-vanex" type="submit">
                      <Spinner v-if="isLoading" type="2" size="20"/>
                      <span class="text-white" v-if="!isLoading">{{$t('login')}}</span>
                    </button>
                  </form>
                  
                  
                  <div class="text-center">
                    <router-link to="/register"><span class="text-vanex text-center">تسجيل حساب جديد</span></router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { instance } from "../../modules/instance";
  import { cryptText } from "../../modules/crypt";
  import Input from '../../components/public/Input.vue';
  import Spinner from '../../components/public/Spinner.vue';

  export default {
    components: { Input , Spinner },
    data() {
      return {
        errors: {
          email: '',
          password: '',
        },
        email: "",
        password: "",
        isLoading: false,
        needResendOtpCode: false,
      };
    },
    methods: {
      loginUser(e) {
        e.preventDefault()
        let errors = 0;
        if (!this.email) {
          errors++
          this.errors['email'] = "حقل البريد الإلكتروني مطلوب ."
        }else{
          this.errors['email'] = ""
        }
        if (!this.password) {
          errors++
          this.errors['password'] = "حقل كلمة المرور مطلوب ."
        }else{
          this.errors['password'] = ""
        }
        if(errors <= 0){
          let _this = this;
          _this.isLoading = true;
          // axios.defaults.withCredentials = true;
          // await axios.get('http://127.0.0.1:8000/sanctum/csrf-cookie')
          // await axios.post('http://127.0.0.1:8000/api/v1/authenticate', {email,password})
          instance
            .post("/authenticate", {
              email: _this.email,
              password: _this.password,
            })
            .then(function (response) {
              if (response.status == 200 && response.data.status_code == 200) {
                localStorage.setItem(
                  "access_token",
                  cryptText(response.data.data.access_token)
                );
                localStorage.setItem(
                  "user",
                  cryptText(JSON.stringify(response.data.data.user))
                );
                
                setTimeout(() => {
                  location.reload();
                }, 230);
              }
            })
            .catch(function (error) {
              if (error.response && error.response.data && error.response.data.errors) {
                
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                  _this.errors[key] = value[0]
                }
                if(error.response.data.resend_otp_code){
                    window.localStorage.removeItem('register-email')
                    window.localStorage.setItem('register-email',cryptText(_this.email))
                    _this.$router.push({ path: '/verification?withres=1' })
                }
              }
            })
            .then(function () {
              _this.isLoading = false;
            });
             
        }
      },
      
    },
    
  };
</script>

<style></style>