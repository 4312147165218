<template>
  <div class="vertical-center">
    <div class="container">
      <div class="row justify-content-center h-100">
        <div class="col-lg-4">
          <div class="card shadow ">
            <div class="card-body ">
              <div class="row justify-content-center">
                <div class="col-lg-12 bg-white p-5">
                  <div class="text-center my-4">
                    <img src="@/assets/images/logo.svg" alt="VANEX" width="100" />
                  </div>
                   <form @submit="resetPassword" novalidate>
                    <div class="text-center">
                      <img src="@/assets/images/icons-v2/reset-password.png" width="140" />
                      <h4 class="text-vanex text-bold my-3">أدخل كلمة المرور الجديدة</h4>
                    </div>
                    <Input title="كلمة المرور" v-model="password" :error-message="errors" name="password"
                      type="password" />
                    <Input title="تأكيد كلمة المرور" v-model="password_confirmation" :error-message="errors"
                      name="password_confirmation" type="password" />
                    <button class="btn btn-block btn-vanex" >
                      <Spinner v-if="isLoading" type="2" size="20" />
                      <span class="text-white" v-if="!isLoading">تعيين</span>
                    </button>
                  </form>
                  <router-link to="/login"><small class="text-vanex text-right ">تسجيل دخول</small></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { instance } from "../../modules/instance";
  import { cryptText } from "../../modules/crypt";
  import Input from '../../components/public/Input.vue';
  import Spinner from '../../components/public/Spinner.vue';

  export default {
    components: { Input, Spinner },
    data() {
      return {
        errors: {
          password: '',
          password_confirmation: '',
        },
        password: "",
        password_confirmation: "",
        isLoading: false,
        needResendOtpCode: false,
      };
    },
    methods: {
      resetPassword(e) {
       e.preventDefault()
        let errors = 0;
        if (!this.password) {
          errors++
          this.errors['password'] = "حقل كلمة المرور مطلوب ."
        } else {
          this.errors['password'] = ""
        }
        if (!this.password_confirmation) {
          errors++
          this.errors['password_confirmation'] = "حقل تأكيد كلمة المرور مطلوب ."
        } else {
          this.errors['password_confirmation'] = ""
        }
        if(this.password_confirmation != this.password && this.password  && this.password_confirmation  ){
          errors++
          this.errors['password'] = "يجب أن يكون حقل تأكيد كلمة المرور مطابق لحقل كلمة المرور"
        }
        if (errors <= 0) {
          let _this = this;
          _this.isLoading = true;
          instance
            .post("/reset-password-store", {
              id: _this.$route.query.uid,
              token: _this.$route.query.key,
              password: _this.password,
              password_confirmation: _this.password_confirmation,
              from: 'web',
            })
            .then(function (response) {
              _this.$toasted.show(response.data.message, {
                type: "success",
              });
             _this.$router.push({ path: '/login' })
            })
            .catch(function (error) {
              if (error.response && error.response.data && error.response.data.errors) {

                for (const [key, value] of Object.entries(error.response.data.errors)) {
                  _this.errors[key] = value
                }


              }
            })
            .then(function () {
              _this.isLoading = false;
            });

        }
      }
    },
    mounted(){
      if(!this.$route.query.key || !this.$route.query.uid){
        this.$router.push({ path: '/login' })
      }
    }
  };
</script>

<style></style>