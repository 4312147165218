<template>
  <div class="vertical-center">
    <div class="container">
      <div class="row justify-content-center h-100 ">
        <div class="col-lg-9">
          <div class="card  ">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-lg-6 bg-vanex text-white text-center login-image-container">
                  <img src="@/assets/images/top-login-circle.png" class="login-image-top" width="90" />
                  <h1 class="text-right mt-4 mx-3 h1">مرحبا بك في تطبيق فانكس</h1>
                  <img src="@/assets/images/login.svg" class="login-image-1" width="100%" alt="" />
                </div>
                <div class="col-lg-6 bg-white p-4">
                  <h4 class="text-vanex text-bold">تسجيل حساب جديد</h4>
                  <form @submit="registerUser" novalidate>
                  <Input title="الاسم" v-model="name" :error-message="errors" name="name" type="text" />
                  <Input title="البريد الإلكتروني" v-model="email" :error-message="errors" name="email" type="email" />
                  <Input title="رقم الهاتف" v-model="phone" :error-message="errors" name="phone" type="number" />
                  <div class="row">
                    <div class="col-lg-6">
                      <Input title="كلمة المرور" v-model="password" :error-message="errors" name="password"
                        type="password" />
                    </div>
                    <div class="col-lg-6">
                      <Input title="تأكيد كلمة المرور" v-model="password_confirmation" :error-message="errors"
                        name="password_confirmation" type="password" />
                    </div>
                  </div>

                  <Select title="الفرع" name="branch" :error-message="errors" v-model="branch">
                    <option v-for="branch in branches" :key="branch.id" :value="branch.id" >{{branch.name}}</option>
                  </Select>

                  <CheckBox name="terms" :error-message="errors" v-model="terms">
                    <small>أوافق على <router-link to="/sign-up-terms"><span class="text-vanex">الشروط والأحكام وسياسة الخصوصية</span></router-link></small>
                  </CheckBox>

                  <button class="btn btn-block btn-vanex" :disabled="isLoading" type="submit">
                    <Spinner v-if="isLoading" type="2" size="20" />
                    <span class="text-white" v-if="!isLoading">تسجيل</span>
                  </button>
                  </form>
                  <div class="text-center">
                    <small class="text-muted text-center">تمتلك حساب بالفعل؟ <router-link to="/login"><span
                          class="text-vanex">تسجيل دخول</span></router-link></small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Input from '../../components/public/Input.vue';
  import Spinner from '../../components/public/Spinner.vue';
  import CheckBox from '../../components/public/CheckBox.vue';
  import Select from '../../components/public/Select.vue';
  import { cryptText } from '../../modules/crypt';
  import { instance } from "../../modules/instance";
  export default {
    components: { Input, Spinner, Select ,CheckBox },
    data() {
      return {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        branch: '',
        branches: [],
        terms: false,
        isLoading: false,
        phone: '',
        errors: {
          name: '',
          email: '',
          password: '',
          password_confirmation: '',
          branch: '',
          terms: '',
          phone: '',
        },
      };
    },
    methods: {
      setupPhone(phone){
        let str = phone;
        let indexOfNine = str.indexOf('9');
        return str.substring(indexOfNine, str.length);
      },
      registerUser(e) {
        e.preventDefault()
        let errors = 0;

        if (!this.name) {
          errors++
          this.errors['name'] = "حقل الاسم مطلوب ."
        } else {
          this.errors['name'] = ""
        }
        if (!this.phone) {
          errors++
          this.errors['phone'] = "حقل الهاتف مطلوب ."
        } else {
          this.errors['phone'] = ""
        }
        if (!this.email) {
          errors++
          this.errors['email'] = "حقل البريد الإلكتروني مطلوب ."
        } else {
          this.errors['email'] = ""
        }
        if (!this.password) {
          errors++
          this.errors['password'] = "حقل كلمة المرور مطلوب ."
        } else {
          this.errors['password'] = ""
        }
        if (!this.password_confirmation) {
          errors++
          this.errors['password_confirmation'] = "حقل تأكيد كلمة المرور مطلوب ."
        } else {
          this.errors['password_confirmation'] = ""
        }
        if(this.password_confirmation != this.password && this.password  && this.password_confirmation){
          errors++
          this.errors['password'] = "يجب أن يكون حقل تأكيد كلمة المرور مطابق لحقل كلمة المرور"
        }
        if (!this.branch) {
          errors++
          this.errors['branch'] = "حقل الفرع مطلوب ."
        } else {
          this.errors['branch'] = ""
        }
        if (!this.terms) {
          errors++
          this.errors['terms'] = " لا يمكن التسجيل بدون الموافقة على الشروط والأحكام وسياسة الخصوصية."
        } else {
          this.errors['terms'] = ""
        }
        if(errors <= 0){
          let _this = this;
          _this.isLoading = true;
          instance
            .post("/register", {
              name: _this.name,
              phone: _this.setupPhone(_this.phone),
              email: _this.email,
              password: _this.password,
              password_confirmation: _this.password_confirmation,
              branch: _this.branch,
            })
            .then(function (response) {
              if (response.data.status_code == 201) {
                _this.$toasted.show(response.data.message, {
                  type: "success",
                });
                window.localStorage.setItem('register-email',cryptText(_this.email))
                _this.$router.push({ path: '/verification' })
              }
            })
            .catch(function (error) {
              if (error.response && error.response.data && error.response.data.errors) {
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                  _this.errors[key] = value[0]
                }
              }
            })
            .then(function () {
              _this.isLoading = false;
            });
        }

      },
      getBranches(){
        let _this = this;
          _this.isLoading = true;
          instance
            .get("/customer/branches")
            .then(function (response) {
              if (response.data.status_code == 200) {
                _this.branches = response.data.data;
              }
            })
            .then(function () {
              _this.isLoading = false;
            });
      }
    },
    mounted(){
      this.getBranches()
    }


  };
</script>

<style></style>