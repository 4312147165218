<template>
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div class="card shadow">
        <div class="card-header card-header-primary">
          <h4 class="card-title">كشف حساب المتجر</h4>
        </div>
        <div class="card-body" dir="rtl">
          <vanex-list-loading  v-if="isLoading" :rowcount="this.serverParams.perPage" />
          
          <div class=" table-responsive" v-if="!isLoading">
            <div class="row">
               <div class="col-lg-12">
                <table class="table table-bordered">
                  <thead>
                    <tr >
                      <th class="bg-vanex-light">
                        الرصيد
                      </th>
                      <th>{{totalBalance}} دينار ليبي</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <vue-good-table
            mode="remote"
            :columns="columns"
            :rows="rows"
            :rtl="true"
            styleClass="vgt-table  bordered"
            :isLoading.sync="isLoading"
            :totalRows="totalRecords"
            @on-per-page-change="onPerPageChange"
            @on-page-change="onPageChange"
            :pagination-options="{
                enabled: true,
                mode: 'pages',
                perPageDropdownEnabled: false,
                perPage: 10,
                position: 'bottom',
                perPageDropdown: [3, 7, 9],
                dropdownAllowAll: true,
                setCurrentPage: this.serverParams.page,
                nextLabel: 'التالي',
                prevLabel: 'السابق',
                rowsPerPageLabel: '',
                ofLabel: 'من',
                pageLabel: 'صفحة',
                allLabel: 'الكل',
            }"
              :search-options="{
                enabled: true,
                placeholder: 'أبحث عن المعاملة هنا ....',
                
              }"
              @on-search="onSearch"
              :sort-options="{
                enabled: false,
              }"
            >

             <div slot="emptystate" class="text-danger text-center">
              لا يوجد معاملات لعرضها
              </div>
              <template slot="table-row" slot-scope="props">
               <slot :props="props" />
                <div v-if="props.column.field == 'type'">
                  {{((props.row.type == 'Deposit') ? 'إيداع' : 'سحب')}}
                </div>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { totop } from '../../modules/helpers';
import {instance} from '../../modules/instance';
export default {
  data() {
    return {
      isLoading : true,
      totalRecords : 0,
        date : '',
        totalBalance : 0,
      columns: [
        { label: "#", field: "id" },
        { label: "تاريخ المعاملة", field: "date" },
        { label: "وصف المعاملة", field: "description" },
        { label: "رقم المستند", field: "doc_id" },
        { label: "المبلغ", field: "amount" },
        { label: "نوع الحركة", field: "type" },
        { label: "الرصيد", field: "balance" },
      ],
      rows: [],
      serverParams: {
        currentPage: 1,
        query : '',
        page: 1, // what page I want to show
        perPage: 10 // how many items I'm showing per page
      }
    };
  },
   methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onSearch(q){
      this.serverParams.page = 1;
        this.serverParams.query = q.searchTerm;
        this.loadItems()
    },
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },
    // load items is what brings back the rows from server
    loadItems() {
      let _this = this;
      instance.get(`/store/transactions?page=${this.serverParams.page}&q=${this.serverParams.query}`)
        .then(function (response) {
          _this.rows = [];
          _this.rows = response.data.data.data;
          _this.totalRecords = response.data.data.meta.total
          _this.totalBalance = response.data.store_balance;
          totop()
        })
        .catch(function (error) {
          // handle error
         
        })
        .then(function () {
         _this.isLoading = false
         
        });
    },
   
  },
  mounted() {
    this.loadItems()
  },
};
</script>
