import { totop,checkValidToken } from "../../modules/helpers";

export default function auth({ next, store }) {
  if (!store.getters['auth/loggedIn']) {

    return next({
      path: "/login",
    });
  }
  checkValidToken(store);
  totop()
  return next();
}
