<template>
  <div class="wrapper ">

    <div class="sidebar" data-color="azure" data-background-color="black">
      <div class="logo">
        <router-link to="/" class="simple-text logo-normal">
          <img src="@/assets/images/logo.svg" alt="VANEX" width="200" />
        </router-link>
      </div>
      <div class="sidebar-wrapper">
        <search-bar />
        <ul class="nav">
          <router-link to="/">
            <li :class="{ 'nav-item': true, active: $route.path == '/' }">
              <a class="nav-link">
                <i class="material-icons">dashboard</i>
                <p>الرئيسية</p>
              </a>
            </li>
          </router-link>
          <li class="nav-item dropdown">
            <a class="nav-link" href="#" id="NewPackageMenu" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <i class="material-icons">library_add</i>
              <p>
                شحنة جديدة
              </p>
            </a>
            <div class="dropdown-menu bg-dark" aria-labelledby="NewPackageMenu">
              <router-link v-if="((user) ? (user.type == 1) : false)" class="dropdown-item  text-white"
                to="/packages/new?t=1">شحن تجاري</router-link>
              <router-link class="dropdown-item  text-white" to="/packages/new?t=2">شحن أفراد</router-link>
              <router-link class="dropdown-item  text-white" to="/packages/new?t=3">البريد الورقي</router-link>
            </div>
          </li>
          <router-link to="/packages">
            <li :class="{ 'nav-item': true, active: $route.path == '/packages' }">
              <a class="nav-link">
                <i class="material-icons">backup_table</i>
                <p>الشحنات</p>
              </a>
            </li>
          </router-link>
          <li class="nav-item dropdown">
            <a class="nav-link" href="#" id="CollectionMenu" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <i class="material-icons">collections_bookmark</i>
              <p>
                طلبات التجميع
              </p>
            </a>
            <div class="dropdown-menu bg-dark" aria-labelledby="CollectionMenu">
              <router-link class="dropdown-item  text-white" to="/collects/new">طلب تجميع جديد</router-link>
              <router-link class="dropdown-item  text-white" to="/collects">طلبات التجميع</router-link>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" href="#" id="SettlementMenu" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <i class="material-icons">room</i>
              <p>
                العناوين البريدية
              </p>
            </a>
            <div class="dropdown-menu bg-dark" aria-labelledby="SettlementMenu">
              <routerLink to="/addresses/new" class="dropdown-item  text-white">إضافة عنوان جديد</routerLink>
              <routerLink to="/addresses" class="dropdown-item  text-white">عرض العناوين البريدية</routerLink>
            </div>
          </li>
          <li class="nav-item dropdown" v-if="((user) ? (user.type == 1) : false)">
            <a class="nav-link" href="#" id="SettlementMenu" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <i class="material-icons">description</i>
              <p>
                الحسابات
              </p>
            </a>
            <div class="dropdown-menu bg-dark" aria-labelledby="SettlementMenu">
              <routerLink to="/accounts/under-settlement" class="dropdown-item  text-white">الشحنات تحت التسوية
              </routerLink>
              <routerLink to="/accounts/transactions" class="dropdown-item  text-white">كشف حسابي</routerLink>
              <routerLink to="/accounts/store-settelments" class="dropdown-item  text-white">التسويات المالية
              </routerLink>
            </div>
          </li>

          <router-link to="/delivery-calculation">
            <li :class="{ 'nav-item': true, active: $route.path == '/delivery-calculation' }">
              <a class="nav-link">
                <i class="material-icons">calculate</i>
                <p>الحاسبة البريدية</p>
              </a>
            </li>
          </router-link>
          <router-link to="/prices">
            <li :class="{ 'nav-item': true, active: $route.path == '/prices' }">
              <a class="nav-link">
                <i class="material-icons">monetization_on</i>
                <p>أسعار الشحن</p>
              </a>
            </li>
          </router-link>
          <router-link to="/services">
            <li :class="{ 'nav-item': true, active: $route.path == '/services' }">
              <a class="nav-link">
                <i class="material-icons">manage_accounts</i>
                <p>الخدمات</p>
              </a>
            </li>
          </router-link>
          <router-link to="/hints">
            <li :class="{ 'nav-item': true, active: $route.path == '/hints' }">
              <a class="nav-link">
                <i class="material-icons">lightbulb_on</i>
                <p>التلميحات</p>
              </a>
            </li>
          </router-link>
          <router-link to="/reports">
            <li :class="{ 'nav-item': true, active: $route.path == '/reports' }">
              <a class="nav-link">
                <i class="material-icons">analytics</i>
                <p>التقارير</p>
              </a>
            </li>
          </router-link>
          <router-link to="/support-center">
            <li :class="{ 'nav-item': true, active: $route.path == '/support-center' }">
              <a class="nav-link">
                <i class="material-icons">support_agent</i>
                <p>مركز الدعم</p>
              </a>
            </li>
          </router-link>
          <router-link to="/about-us">
            <li :class="{ 'nav-item': true, active: $route.path == '/about-us' }">
              <a class="nav-link">
                <i class="material-icons">not_listed_location</i>
                <p>{{$t('about_us')}}</p>
              </a>
            </li>
          </router-link>

          <router-link to="/contact-us">
            <li :class="{
                'nav-item': true,
                active: $route.path == '/contact-us',
              }">
              <a class="nav-link">
                <i class="material-icons">call</i>
                <p>إتصل بنا</p>
              </a>
            </li>
          </router-link>
        </ul>
        <!-- Mobile Navbar  -->
        <ul class="nav navbar-nav nav-mobile-menu">
          <li class="nav-item dropdown">
            <a class="nav-link" href="javascript:;" id="navbarDropdownProfile" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <i class="material-icons">person</i>
              <p class="d-lg-none d-md-block">
                حسابي
              </p>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
              <router-link to="/profile" class="dropdown-item"><i class="material-icons mx-1">edit</i> الملف الشخصي
              </router-link>
              <router-link to="/ban" class="dropdown-item"><i class="material-icons mx-1">person_off</i> حظر الزبائن
              </router-link>
              <router-link to="/invitations" class="dropdown-item"><i class="material-icons mx-1">group_add</i>
                <span v-if="user && user.type == 1">الدعوات المرسلة</span>
                <span v-if="user && user.type == 2">الدعوات المستقبلة</span>
              </router-link>
              <router-link to="/change-password" class="dropdown-item"><i class="material-icons mx-1">lock</i> تغيير
                كلمة المرور
              </router-link>
              <router-link to="/logout" class="dropdown-item"><i class="material-icons mx-1">logout</i> تسجيل خروج
              </router-link>
            </div>
          </li>
        </ul>
        <!-- End Mobile Navbar  -->
      </div>
    </div>
    <div class="main-panel">
      <!-- Navbar -->
      <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top">
        <div class="container-fluid">
          <div class="navbar-wrapper">
            <a class="navbar-brand d-sm-none d-none d-sm-block">{{ $route.name }}</a>
            <a class="navbar-brand d-md-none d-lg-none">
              <router-link to="/" class="simple-text logo-normal">
                <img src="@/assets/images/logo.svg" alt="VANEX" width="100" />
              </router-link>
            </a>
          </div>
          <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index"
            aria-expanded="false" aria-label="القائمة">
            <!-- <img src="@/assets/images/icons-v2/menu.svg" width="40" alt="">
             -->
            <span class="sr-only">القائمة</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar text-danger"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-end">
            <ul class="navbar-nav">

              <li class="nav-item">
                <button @click="showWeather" class="btn btn-link nav-link"  >
                    <i class="material-icons">cloud</i>
                    <p class="d-lg-none d-md-block">الطقس</p>
                  </button>
              </li>
              <li class="nav-item dropdown">
                <button class="btn btn-link nav-link" id="navbarDropdownMenuLink" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons">person</i>
                    <p class="d-lg-none d-md-block">حسابي</p>
                  </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                  <router-link to="/profile" class="dropdown-item"><i class="material-icons mx-1">edit</i> الملف الشخصي
                  </router-link>
                  <router-link to="/invitations" class="dropdown-item"><i class="material-icons mx-1">group_add</i>
                    <span v-if="user && user.type == 1">الدعوات المرسلة</span>
                    <span v-if="user && user.type == 2">الدعوات المستقبلة</span>
                  </router-link>
                  <router-link to="/ban" class="dropdown-item"><i class="material-icons mx-1">person_off</i> حظر الزبائن
                  </router-link>
                  <router-link to="/change-password" class="dropdown-item"><i class="material-icons mx-1">lock</i> تغيير
                    كلمة المرور
                  </router-link>
                  <router-link to="/logout" class="dropdown-item"><i class="material-icons mx-1">logout</i> تسجيل خروج
                  </router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <!-- End Navbar -->

      <div class="content pageContainer">
        <!-- Main Content -->
        <div class="container-fluid p-0">
          <slot />
        </div>
        <!-- End Main Content -->

        <!-- footer -->
        <footer class="footer">
          <div class="container-fluid">
            <nav class=" justify-content-center">
              <ul>
                <Modal withoutbtn id="weather_info" title="حالة الطقس" type="none" size="small">
                  <div slot="btn"></div>
                  <div slot="body" class="py-2 text-center">
                    <div class="row" v-if="!isLoading">
              <div class="col-md-12 " v-if="tripoliTemp>0">
                <div class="card my-1">
                  <div class="card-body text-center text-primary">
                    <h1 class="text-primary" dir="rtl"><img :src="tripoliIcon" class="px-1" align="center" width="50" />
                      <a class="mx-2"><strong>{{ tripoliTemp }}</strong></a> </h1>
                    <h4 class="text-vanex">مدينة طرابلس</h4>
                     <small>{{notes}}</small>
                  </div>
                </div>
              </div>
              <div class="col-md-12 " v-if="benghaziTemp>0">
                <div class="card my-1">
                  <div class="card-body text-center text-primary">
                    <h1 class="text-primary" dir="rtl"><img :src="benghaziIcon" class="px-1" align="center" width="50" />
                      <a class="mx-2"><strong>{{ benghaziTemp }}</strong></a> </h1>
                    <h4 class="text-vanex">مدينة بنغازي</h4>
                     <small>{{notes}}</small>
                  </div>
                </div>
              </div>
              <div class="col-md-12 " v-if="misrataTemp>0">
                <div class="card my-1">
                  <div class="card-body text-center text-primary">
                    <h1 class="text-primary" dir="rtl"><img :src="misrataIcon" class="px-1" align="center" width="50" />
                      <a class="mx-2"><strong>{{ misrataTemp }}</strong></a> </h1>
                     <h4 class="text-vanex">مدينة مصراتة</h4>
                      <small>{{notes}}</small>
                  </div>
                </div>
              </div>
              <div class="col-md-12 " v-if="sabhaTemp>0">
                <div class="card my-1">
                  <div class="card-body text-center text-primary">
                    <h1 class="text-primary" dir="rtl"><img :src="sabhaIcon" class="px-1" align="center" width="50" /> <a
                        class="mx-2"><strong>{{ sabhaTemp }}</strong></a> </h1>
                     <h4 class="text-vanex">مدينة سبها</h4>
                     <small>{{notes}}</small>
                  </div>
                </div>
              </div>
            </div>
                  </div>
                </Modal>

                <li>
                  <a href="https://www.instagram.com/vanex.ly" class="social-icon-container" target="_blank"><i
                      class="fa fa-instagram text-vanex"></i></a>
                </li>
                <li>
                  <a href="https://twitter.com/vanex_ly" class="social-icon-container" target="_blank"><i
                      class="fa fa-twitter text-vanex"></i></a>
                </li>
                <li>
                  <a href="https://www.facebook.com/vanex.ly" class="social-icon-container" target="_blank"><i
                      class="fa fa-facebook text-vanex"></i></a>
                </li>

                <li>
                  <a href="https://www.linkedin.com/company/vanex-libya" class="social-icon-container"
                    target="_blank"><i class="fa fa-linkedin text-vanex"></i></a>
                </li>

                <li>
                  <a href="https://goo.gl/maps/QKoHGtAi2j3XTc6L7" class="social-icon-container" target="_blank"><i
                      class="fa fa-map text-vanex"></i></a>
                </li>
              </ul>
            </nav>
            <div class="copyright ">
              جميع الحقوق محفوظة لشركة فانكس &#169; 2021
            </div>
          </div>
        </footer>
        <!-- End footer -->
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>


</style>
<script>
  import Vue from 'vue';
  import { Datetime } from 'vue-datetime';
  import 'vue-datetime/dist/vue-datetime.css'
  Vue.use(Datetime)
  Vue.component('datetime', Datetime);
  import { Settings } from 'luxon'
  import Button from '../components/public/Button.vue';
  import Input from '../components/public/Input.vue';
  import { instance } from '../modules/instance';
  import SearchBar from '../components/SearchBar.vue';
  import Modal from '../components/public/Modal.vue';

  Settings.defaultLocale = 'ar'
  export default {
    name: "default",
    components: {
      Button,
      Input,
      SearchBar,
      Modal,

    },
    data: function () {
      return {
        user: null,
        tripoliTemp: 0,
        benghaziTemp: 0,
        misrataTemp: 0,
        sabhaTemp: 0,
        tripoliIcon: "",
        benghaziIcon: "",
        misrataIcon: "",
        sabhaIcon: "",
        notes : "",
        isLoading: true,
      }
    },
    methods: {
      showWeather(){
         $("#weather_info").modal("show");
      },
      async getWeather() {
        const options = {
          method: "GET",
          url: `/customer-weather`,
        };
        let _this = this;
         instance
          .request(options)
          .then(function (response) {
            let lastTemp = parseInt(response.data.weather_data.main.temp - 273.15);
            let name = response.data.city;
            _this.notes = response.data.notes
            if (name == "tripoli") {
              _this.tripoliTemp = lastTemp;
              _this.tripoliIcon = `http://openweathermap.org/img/w/${response.data.weather_data.weather[0].icon}.png`;
            }
            if (name == "benghazi") {
              _this.benghaziTemp = lastTemp;
              _this.benghaziIcon = `http://openweathermap.org/img/w/${response.data.weather_data.weather[0].icon}.png`;
            }
            if (name == "misrata") {
              _this.misrataTemp = lastTemp;
              _this.misrataIcon = `http://openweathermap.org/img/w/${response.data.weather_data.weather[0].icon}.png`;
            }
            if (name == "sabha") {
              _this.sabhaTemp = lastTemp;
              _this.sabhaIcon = `http://openweathermap.org/img/w/${response.data.weather_data.weather[0].icon}.png`;
            }
          })
          .catch(function (error) {
            console.error(error);
          })
          .then(() => { });
      },
    },
    async mounted() {
      let user = this.$store.state.auth.user;
      this.user = user
      await this.getWeather();
      this.isLoading = false;
    }
  };
</script>