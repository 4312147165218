<template>
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div class="card shadow">
        <div class="card-header card-header-primary">
          <h4 class="card-title">{{$t('under_settlement')}}</h4>
        </div>
        <div class="card-body" dir="rtl">
          <vanex-list-loading v-if="isLoading" :rowcount="10" />
          <div class=" table-responsive" v-if="!isLoading">

            <vue-good-table  :totalRows="totalRecords" @on-per-page-change="onPerPageChange"
              @on-page-change="onPageChange" :columns="columns" :rows="rows" :rtl="true"
              styleClass="vgt-table  bordered" :pagination-options="{
                enabled: false,
                mode: 'pages',
                perPageDropdownEnabled: false,
                perPage: 10,
                position: 'bottom',
                perPageDropdown: [3, 7, 9],
                dropdownAllowAll: true,
                setCurrentPage: this.serverParams.page,
                nextLabel: 'التالي',
                prevLabel: 'السابق',
                rowsPerPageLabel: '',
                ofLabel: 'من',
                pageLabel: 'صفحة',
                allLabel: 'الكل',
            }" :search-options="{
              enabled: false,
            }" :sort-options="{
              enabled: true,
              initialSortBy: {field: 'id', type: 'asc'}
            }">

              <div slot="emptystate">
                <alert message="لا توجد بيانات حتى الأن" type="danger" />
              </div>
            </vue-good-table>

            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="bg-vanex-light">{{$t('store_balance')}}</th>
                  <th> {{storeBalance}} {{$t('libyan_dinar')}}</th>
                </tr>
                <tr>
                  <th class="bg-vanex-light">{{$t('store_debit')}} </th>
                  <th>{{storeDebit}} {{$t('libyan_dinar')}}</th>
                </tr>
              </thead>

            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { instance } from '../../modules/instance';

  import Spinner from '../../components/public/Spinner.vue';
  import { totop } from '../../modules/helpers';
  export default {
    components: { Spinner },
    data() {
      return {
        columns: [
          { label: "#", field: "id", type: 'number' },
          { label: "كود الشحنة", field: "package_code" },
          { label: "الوصف", field: "description" },
          { label: "المستلم", field: "reciever" },
          { label: "الفرع المنشأ", field: "origin" },
          { label: "أتعاب الشحن", field: "shippingFees" },
          { label: "إستحقاق المتجر", field: "StoreEntitlement" },

        ],
        isLoading: true,
        storeBalance: 0,
        storeDebit: 0,
        rows: [],
        totalRecords: 0,
        serverParams: {
          currentPage: 1, // what page I want to show
          page: 1, // what page I want to show
          perPage: 15 // how many items I'm showing per page
        }
      };
    },
    methods: {
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems();
      },
      onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage });
        this.loadItems();
      },
      // load items is what brings back the rows from server
      loadItems() {
        let _this = this;
        this.rows = [],
          instance.get(`/customer/packages/settelment?page=${this.serverParams.page}`)
            .then(function (response) {
              _this.totalRecords = response.data.settelments.pagination.total
              let i = 1;
              response.data.settelments.data.forEach(settelment => {
                _this.rows.push({
                  id: i,
                  package_code: settelment['package-code'],
                  description: settelment.description,
                  reciever: settelment.reciever,
                  shippingFees: settelment.shippment,
                  StoreEntitlement: settelment.price,
                  origin: settelment.origin ? settelment.origin.name : '',
                })
                i++;
              });

              _this.storeBalance = response.data.storeBalance
              _this.storeDebit = response.data.storeDebit
            })
            .catch(function (error) {
              // handle error
             
            })
            .then(function () {
              _this.isLoading = false
              totop()
            });

      },
    },
    mounted() {
      this.loadItems()
    }
  };
</script>