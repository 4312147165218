<template>
  <div class="row justify-content-center">
    <div class="col-lg-12">
      <div class="card shadow">
        <div class="card-header card-header-primary">
          <h4 class="card-title">{{ $t("terms") }}</h4>
        </div>
        <div class="card-body table-responsive" dir="rtl">
          <div class="text-center my-2">
            <img src="@/assets/images/icons/terms.svg" width="200" alt="" />
            <div class="text-right" v-html="terms"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { instance } from "../modules/instance";

import Spinner from "../components/public/Spinner.vue";
export default {
  components: { Spinner },
  data() {
    return {
      terms: "",
    };
  },
  methods: {
    getTerms() {
      let _this = this;
      instance
        .get("/ar/cms/5")
        .then(function (response) {
          _this.terms = response.data.data.paragraph;
        })
        .catch(function (error) {
          _this.$router.push({ path: "/register" });
        });
    },
  },
  mounted() {
    this.getTerms();
  },
};
</script>

<style>
</style>