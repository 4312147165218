<template>
  <div class="container">
    <div class="row justify-content-center h-100">
      <div class="col-lg-12">
        <div class="card  ">
          <div class="card-body ">
            <form @submit="createCollect" novalidate>
              <div class="row justify-content-center">
                <div class="col-lg-12 bg-white p-4">

                  <div class="text-right">
                    <h4 class="text-vanex text-bold my-3">{{$t('new_collect')}}</h4>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      
                      <small class="text-muted">{{ $t("info_code") }}</small>
                      <v-select @input="setAddressDetails"  v-model="selectedAddress"  label="code" :options="addresses">
                          <template slot="option" slot-scope="option" class="text-right">
                              {{ option.code }} - {{ option.description }} 
                          </template>
                      </v-select>
                    </div>
                    <div class="col-lg-6">
                      <Input :title="$t('phone')" :readonly="selectedAddress" v-model="phone" :error-message="errors" name="phone" type="number" />
                    </div>
                    <div class="col-lg-6">
                      <Input :title="$t('num_pkg')"   v-model="num_pkg" :error-message="errors" name="num_pkg"
                        type="number" />
                    </div>
                    <div class="col-lg-6">
                      <Input :title="$t('map')" v-model="map" :readonly="selectedAddress" :error-message="errors" name="map" type="text" />
                      <div class="row">
                        <div class="btn-group">
                          <button @click="getUserLocation()" type="button"
                            class="btn btn-light text-primary btn-sm py-0 mx-2">
                            <i class="fa fa-map-pin mx-2"></i> إستخدم موقعي الحالي
                          </button>
                         <map-selector @change="mapChange"></map-selector>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-6">
                      <Input :title="$t('address')" v-model="address" :readonly="selectedAddress" :error-message="errors" name="address"
                        type="text" />
                    </div>



                    <div class="col-lg-12 my-2">
                      <h4 class="text-vanex text-right text-bold ">الخدمات المضافة المطلوبة</h4>
                    </div>

                    <div class="col-12 mb-2">
                      <div class="row justify-content-start">
                        <div class="col-lg-2">
                          <CheckBox name="returns_request"  :checked="services.returns_request" :error-message="errors" v-model="services.returns_request">
                            <a class="text-muted">طلب المسترد</a>
                          </CheckBox>
                        </div>
                        <div class="col-lg-2">
                          <CheckBox name="packaging"  :checked="services.packaging" :error-message="errors" v-model="services.packaging">
                            <a class="text-muted">طلب التغليف الآمن</a>
                          </CheckBox>
                        </div>
                        <div class="col-lg-2">
                          <CheckBox name="sett"  :checked="services.sett" :error-message="errors" v-model="services.sett">
                            <a class="text-muted">طلب تسوية مالية</a>
                          </CheckBox>
                        </div>
                        <div class="col-lg-3">
                          <CheckBox name="ready" :disabled="num_pkg>=0" :checked="services.ready" :error-message="errors" v-model="services.ready">
                            <a class="text-muted">الشحنات الجاهزة للتسليم</a>
                          </CheckBox>
                        </div>
                        <div class="col-12">
                          <small v-if="errors && errors['services']" class="text-right text-danger d-inline"><i
                              class="fa fa-exclamation-circle mx-2"></i>
                            {{errors['services']}}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <Input :title="$t('notes')" v-model="notes" :error-message="errors" name="notes" type="text" />
                    </div>
                  </div>

                  <Button :text="$t('create')" :isLoading="isLoading" type="submit" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Button from '../../components/public/Button.vue';
  import CheckBox from '../../components/public/CheckBox.vue';
  import Input from '../../components/public/Input.vue';
import Modal from '../../components/public/Modal.vue';
  import { totop } from '../../modules/helpers';
  import { instance } from "../../modules/instance";
  export default {
    components: { Input, Button, CheckBox, Modal },
    data() {
      return {
        address: "",
        selectedAddress: null,
        addresses:[],
        marker : { lat: 32.625148937407864, lng: 17.414175040074806 },
        map: "",
        phone: "",
        num_pkg: 0,
        notes: "",
        isLoading: false,
        services: {
          returns_request: false,
          packaging: false,
          sett: false,
          ready: false,
        },
        errors: {
          address: "",
          map: "",
          phone: "",
          notes: "",
          services: "",
          num_pkg: "",
        },
      };
    },
    methods: {
      async getUserLocation() {
        let _this = this;
        await navigator.geolocation.getCurrentPosition(function (position) {
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;
          _this.marker =  { lat, lng }
          _this.map = `https://maps.google.com/?q=${lat},${lng}`;
          document.getElementById('map').value = `https://maps.google.com/?q=${lat},${lng}`;
        });
      },
      createCollect(e) {
        e.preventDefault()
        let errors = 0;
        if (!this.address) {
          errors++
          this.errors['address'] = this.$t('required', { title: this.$t('address') })
        } else {
          this.errors['address'] = ""
        }
        if (!this.phone) {
          errors++
          this.errors['phone'] = this.$t('required', { title: this.$t('phone') })
        } else {
          this.errors['phone'] = ""
        }
        if (!this.services.returns_request && !this.services.packaging && !this.services.sett && !this.services.ready) {
          errors++
          this.errors['services'] = this.$t('services_required')
        } else {
          this.errors['services'] = ""
        }

        if (errors === 0) {
          this.isLoading = true;
          let _this = this;
          let data = {
            address: this.address,
            phone: this.phone,
            map: this.map,
            notes: this.notes,
            services: {
              sett: (this.services.sett) ? 1 : 0,
              returns_request: (this.services.returns_request) ? 1 : 0,
              ready: (this.services.ready) ? 1 : 0,
              packaging: (this.services.packaging) ? 1 : 0,
            },
            num_pkg: this.num_pkg,
          }

          instance
            .post("/customer/collects", data)
            .then(function (response) {
              _this.$toasted.show(response.data.message, {
                type: "success",
              });
              _this.$router.push({ path: '/collects' })
            })
            .catch(function (error) {

              totop()
              if (error.response && error.response.data && error.response.data.errors) {
                if (error.response.data.errors['message']) {
                  _this.$toasted.show(error.response.data.errors['message'], {
                    type: "warning",
                  });
                }
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                  _this.errors[key] = value[0]
                }
              }
            })
            .then(function () {
              _this.isLoading = false;
            });
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }
      },
      mapChange(e){
                let _this = this;
                let data = `https://maps.google.com/?q=${e.marker.lat},${e.marker.lng}`;
                _this.map = data;
                document.getElementById('map').value = data;
      },
      getUserAddresses(){
                let _this = this;
                 _this.isLoading = true;
                 _this.addresses = [];
                instance.get(`/user/info`).then(function (response) {
                    let addresses = response.data.data;
                    _this.addresses = addresses
                }).then(()=>{
                    _this.isLoading = false;
                })
      },
      setAddressDetails(){
        this.address = this.selectedAddress.address
        this.map  = this.selectedAddress.map
        this.phone  = this.selectedAddress.phone
        document.getElementById('address').value = this.selectedAddress.address
        document.getElementById('map').value = this.selectedAddress.map
        document.getElementById('phone').value = this.selectedAddress.phone
      }
    },
    mounted(){
      document.getElementById('num_pkg').value = 0
       document.getElementById('ready').disabled = true;
      this.getUserAddresses()
    },
    watch:{
      num_pkg:function(v){
        
        if(this.num_pkg > 0){
          this.services.ready = true;
          document.getElementById('ready').checked = true;
        }else{
          this.services.ready = false;
          document.getElementById('ready').disabled = true;
          document.getElementById('ready').checked = false;
        }
        if(this.num_pkg < 0){
            document.getElementById('num_pkg').value = 0;
            this.num_pkg = 0
        }
        
      }
    }
  };
</script>

<style></style>