<template>
    <div class="form-group ">
        <small v-if="!wihtoutlabel" class="text-right text-muted">{{title}} <span class="mx-1">{{extraText}}</span></small>
        <input :dir="(ltrvalue == 1) ? 'ltr' : 'rtl'" :type="type" @input="setInput"  class="form-control "
         :class="{ 'is-invalid': (errorMessage && errorMessage[name]) }"
         :placeholder="$t('input_placeholder', { title })" 
         :id="name"
         :name="name"
         :ref="name"
         :readonly="(readonly) ? true : false"
          >
        <small v-if="errorMessage && errorMessage[name]" class="text-right text-danger d-inline"><i class="fa fa-exclamation-circle mx-2"></i> 
        {{errorMessage[name]}}
        </small>
    </div>
</template>

<script>
    export default {
        props:['title','name','type','ltrvalue','readonly','errorMessage','value','extraText','wihtoutlabel'],
        methods:{
            setInput(e){
                 this.$emit('input', (e) ? e.target.value : this.value )
                // var el = this.$refs[this.name]
                // var event = new Event(this.name, {bubbles: true})
                // el.value = (e) ? e.target.value : '' 
                // el.dispatchEvent(event)
            }
        },
        mounted(){
            if(this.value){
                document.getElementById(this.name).value = this.value;
            }
            this.setInput()
        }
    };
</script>

<style>
</style>