<template>
  <div class="d-inline">
    <small>{{ newDate }}</small>
  </div>
</template>

<script>
export default {
  props: ["date"],
  name: "date",
  data() {
    return {
      newDate: null,
    };
  },
  mounted() {
    const event = new Date(this.date);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    this.newDate = event.toLocaleTimeString("ar-LY", options);
  },
};
</script>

<style scoped>
</style>
