<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card shadow">
          <div class="card-header card-header-primary ">
            <h4 class="card-title">{{$t('show_packages')}}</h4>
          </div>
          <div class="card-body">
            <div class="row justify-content-center">
              <div class="col-lg-12 col-md-12" v-if="rows && !isLoading">
                <div class="table-responsive">
                  <vue-good-table mode="remote" :totalRows="totalRecords" @on-per-page-change="onPerPageChange"
                    @on-page-change="onPageChange" :columns="columns" :rows="rows" :rtl="true"
                    styleClass="vgt-table  bordered" :pagination-options="{
                    enabled: true,
                    mode: 'pages',
                    perPageDropdownEnabled: false,
                    perPage: 10,
                    position: 'bottom',
                    perPageDropdown: [3, 7, 9],
                    dropdownAllowAll: true,
                    setCurrentPage: this.serverParams.page,
                    nextLabel: 'التالي',
                    prevLabel: 'السابق',
                    rowsPerPageLabel: '',
                    ofLabel: 'من',
                    pageLabel: 'صفحة',
                    allLabel: 'الكل',
                  }" :search-options="{
                    enabled: false,
                  }" :sort-options="{
                    enabled: false,
                  }">
                    <div slot="emptystate">
                      <alert message="لا توجد شحنات لعرضها" type="vanex" />
                    </div>
                    <template slot="table-row" slot-scope="props">
                      <slot :props="props" />
                      <div v-if="props.column.field == 'setting'">
                        <div class="btn-group" role="group" aria-label="">

                          <router-link v-if="(packageCategory && packageCategory.key == 'sent')" :to="`/packages/${props.row.package_code}/show`"><button type="button"
                              class="btn btn-link text-success  ">
                              <i class="fa fa-eye"></i>
                            </button>
                          </router-link>
                          <router-link  v-if="props.row.status_object.status_value == 'store_new' && packageCategory && packageCategory.key == 'sent'" :to="`/packages/${props.row.package_code}/edit`"><button type="button"
                              class="btn btn-link text-primary ">
                              <i class="material-icons">border_color</i>
                            </button>
                          </router-link>


                          <Modal v-if="props.row.status_object.status_value == 'store_new'"
                            :id="`delete_modal_${props.row.package_code}`" :title="$t('package_delete_confirm')"
                            type="link" size="small">
                            <a slot="btn" class="text-danger"><i class="material-icons">delete</i> </a>
                            <div slot="body">
                              <button @click="deletePackage(props.row.package_code)"
                                class="btn btn-danger btn-sm btn-block">{{$t('confirm')}}</button>
                              <a class="btn btn-block btn-secondary btn-sm text-dark"
                                data-dismiss="modal">{{$t('close')}}</a>
                            </div>
                          </Modal>
                        </div>
                      </div>
                      <div v-else-if="props.column.field == 'package_code'">
                        <router-link :to="`/packages/${props.row.package_code}/show`"> <span
                            dir="ltr">{{props.row.package_code}}</span></router-link>
                      </div>
                      <div v-else-if="props.column.field == 'dimensions'" dir="ltr">
                        <span class="badge badge-primary">{{props.row.leangh}}</span> x
                        <span class="badge badge-primary">{{props.row.width}}</span> x
                        <span class="badge badge-primary">{{props.row.height}}</span>
                      </div>
                    </template>
                    <div slot="table-actions">


                      <div class="form-inline">
                        <div class="form-group ">
                          <v-select @input="changeCategory" v-model="packageCategory" dir="rtl" label="title"
                            :options="packageCategories">
                          </v-select>
                        </div>
                        <div class="form-group">
                          <router-link to="/packages/new">
                            <button class="btn btn-vanex btn-sm btn-ticket"><i class="fa fa-plus"></i>
                              {{$t('new_package')}}</button>

                          </router-link>
                        </div>
                      </div>
                    </div>
                  </vue-good-table>
                </div>
              </div>
              <div class="col-12" v-if="isLoading">
                <vanex-list-loading :rowcount="10" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Modal from '../../components/public/Modal.vue';
  import Spinner from "../../components/public/Spinner.vue";
  import { by, totop } from '../../modules/helpers';
  import { instance } from "../../modules/instance";
  export default {
    components: { Spinner, Modal },
    data: function () {
      return {
        
        packageCategory: { title: this.$t('sent'), key: 'sent' },
        columns: [
          {
            label: "#",
            field: "package_code",
          },
          {
            label: "نوع الشحن",
            field: "type",
          },
          {
            label: "الوصف",
            field: "description",
          },
          {
            label: "المستلم",
            field: "reciever",
          },
          {
            label: "الهاتف",
            field: "phone",
          },
          {
            label: "الهاتف الإحتياطي",
            field: "phone_b",
          },
          {
            label: "المدينة",
            field: "city",
          },
          {
            label: "المنطقة",
            field: "address",
          },
          {
            label: "الأبعاد",
            field: "dimensions",
          },
          {
            label: "المستحق",
            field: "price",
          },
          {
            label: "الشحن",
            field: "shippment",
          },
          {
            label: "عمولة الحجم",
            field: "extra_size_price",
          },
          {
            label: "عمولة التحصيل",
            field: "region_commission",
          },
          {
            label: "الاجمالي",
            field: "total",
          },
          {
            label: "علي حساب",
            field: "paid_by",
          },
          {
            label: "الحجم على حساب",
            field: "extra_size_by",
          },
          {
            label: "التحصيل على حساب",
            field: "commission_by",
          },
          {
            label: "الحالة",
            field: "status_text",
          },
          {
            label: "الإضافة",
            field: "created",
          },
          {
            label: "الإعدادات",
            field: "setting",
          },
        ],
        rows: [],
        user: null,
        packageCategories: [
          { title: this.$t('sent'), key: 'sent' },
          { title: this.$t('received'), key: 'received' },
        ],
        isLoading: false,
        totalRecords: 0,
        serverParams: {
          status: '',
          currentPage: 1, // what page I want to show
          page: 1, // what page I want to show
          perPage: 10, // how many items I'm showing per page
          category: 'sent', // how many items I'm showing per page
        },
      }
    },

    methods: {
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems();
      },
      onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage });
        this.loadItems();
      },
      changeCategory() {
        this.serverParams.category = this.packageCategory.key
        this.loadItems()
      },
      deletePackage(code) {
        let _this = this;
        instance.delete(`/customer/package/${code}`).then(function (response) {
        }).then(() => {
          
          $(`#delete_modal_${code}`).modal("hide");
          _this.$toasted.show(_this.$t("package_delete_success_msg"), {
            type: "success",
          });
          setTimeout(() => {
            _this.loadItems();
          }, 200);
        }).catch((err) => {
          
        })
      },
      // load items is what brings back the rows from server
      loadItems() {
        let _this = this;
        _this.isLoading = true;
        instance
          .get(`/customer/package/${_this.serverParams.category}?page=${this.serverParams.page}&status=${this.serverParams.status}`)
          .then(function (response) {
            _this.rows = []
            _this.totalRecords = 1
            _this.totalRecords = response.data.data.meta.total
            response.data.data.data.forEach(packageData => {
              _this.rows.push({
                package_code: packageData['package-code'],
                type: (packageData.package_type) ? packageData.package_type.name : '',
                description: packageData.description,
                reciever: packageData.reciever,
                phone: packageData.phone,
                phone_b: packageData.phone_b,
                city: (packageData.City) ? packageData.City.name : '',
                address: packageData.address,
                dimensions: '',
                price: `${packageData.price}`,
                shippment: `${packageData.shippment}`,
                total: `${packageData.total}`,
                paid_by: by(packageData.paid_by),
                extra_size_by: by(packageData.extra_size_by),
                commission_by: by(packageData.commission_by),
                status_object: packageData.status_object,
                status_text: packageData.status,
                cid: packageData.cid,
                width: packageData.width,
                leangh: packageData.leangh,
                extra_size_price: packageData.extra_size_price,
                region_commission: packageData.region_commission,
                height: packageData.height,
                created: packageData.update_date ? packageData.update_date : packageData.updated_at,
                setting: 1,
              })

            });


          })
          .catch(function (error) {

          })
          .then(function () {
            _this.isLoading = false;
            totop()
          });
      },
    },
    mounted() {

      const acceptStatuses = ['on-track', 'return', 'cant-delivery', 'pending-store'];
      if (this.$route.query.t) {
        if (!acceptStatuses.includes(this.$route.query.t)) {
          this.$router.push({ path: '/' });
          return false;
        }
        this.serverParams.status = this.$route.query.t;
      }
      this.loadItems()
    }
  }
</script>

<style>

</style>