import { render, staticRenderFns } from "./vanex-loading.vue?vue&type=template&id=5ccd8818&scoped=true&"
import script from "./vanex-loading.vue?vue&type=script&lang=js&"
export * from "./vanex-loading.vue?vue&type=script&lang=js&"
import style0 from "./vanex-loading.vue?vue&type=style&index=0&id=5ccd8818&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ccd8818",
  null
  
)

export default component.exports