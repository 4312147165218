<template>
  <div class="card shadow">
    <div class="card-header card-header-primary">
      <h4 class="card-title">{{ $t("ban_list") }}</h4>
    </div>
    <div class="card-body">
      <div class="row justify-content-center">
        <div class="col-lg-12 col-md-12" v-if="rows && !isLoading">
          <div class="table-responsive">
            <vue-good-table :columns="columns" :rows="rows" :rtl="true" styleClass="vgt-table striped"
              :pagination-options="{
                enabled: true,
                mode: 'pages',
                perPageDropdownEnabled: false,
                perPage: 5,
                position: 'bottom',
                perPageDropdown: [3, 7, 9],
                dropdownAllowAll: true,
                setCurrentPage: 1,
                nextLabel: 'التالي',
                prevLabel: 'السابق',
                rowsPerPageLabel: 'عدد الصفوف في الصفحة',
                ofLabel: 'من',
                pageLabel: 'صفحة',
                allLabel: 'الكل',
              }" :search-options="{
                enabled: false,
                placeholder: 'ابحث من هنا...',
              }" :sort-options="{
                enabled: true,
              }">
              <template slot="table-row" slot-scope="props">
                <slot :props="props" />

                <div v-if="props.column.field == 'status'">
                  <span v-if="props.row.status == 'pending'">بإنتظار الموافقة</span>
                  <span v-if="props.row.status == 'rejected'" class="text-danger">مرفوض</span>
                  <span v-if="props.row.status == 'approved'">تمت الموافقة</span>
                </div>
                <div v-else-if="props.column.field == 'notes'">
                  <small>{{ props.row.notes }}</small>
                </div>
                <div v-else-if="props.column.field == 'created_at'">
                  <custom-date :date="props.row.created_at"></custom-date>
                </div>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <div slot="table-actions">

                <div class="form-inline">
                 
                  <div class="form-group">
                    <router-link to="/ban/new">
                      <button class="btn btn-vanex btn-sm btn-ticket"><i class="fa fa-plus"></i>
                      {{$t('new_ban_customer')}}</button>

                    </router-link>
                  </div>
                </div>
              </div>
            </vue-good-table>
          </div>
        </div>
        <div class="col-12" v-if="isLoading">
          <vanex-list-loading :rowcount="10" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Modal from "../../components/public/Modal.vue";
  import Spinner from "../../components/public/Spinner.vue";
  import QRCode from "qrcode";
  import { instance } from "../../modules/instance";
  export default {
    components: { Spinner, Modal },
    data: function () {
      return {
        columns: [
          {
            label: "#",
            field: "order",
          },
          {
            label: "الاسم",
            field: "name",
          },
          {
            label: "الهاتف",
            field: "phone",
          },
          {
            label: "ملاحظة",
            field: "notes",
          },
          {
            label: "الحالة",
            field: "status",
          },
          {
            label: "تاريخ الإضافة",
            field: "created_at",
          },
        ],
        rows: [],
        isLoading: true,
      };
    },
    methods: {
      getBanList() {
        let _this = this;
        _this.isLoading = true;
        _this.rows = [];
        instance
          .get(`/customer/ban-requests`)
          .then(function (response) {
            let banList = response.data.data;
            banList.forEach((address, index) => {
              _this.rows.push({
                order: index + 1,
                name: address.name,
                phone: address.phone,
                notes: address.notes,
                status: address.status,
                created_at: address.created_at,
              });
            });
          })
          .then(() => {
            _this.isLoading = false;
          });
      },

    },
    mounted() {
      this.getBanList();
    },
  };
</script>

<style>
</style>