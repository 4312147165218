<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card shadow">
          <div class="card-body text-center">
            <img src="@/assets/images/icons-v2/404.png" class="my-2" width="250" alt="">
            <h3 class="text-vanex">عفوًا ، الصفحة التي تبحث عنها غير موجودة ..</h3>
            <small class="text-muted">ربما أخطأت في كتابة العنوان أو ربما تم نقل الصفحة. حاول البحث أدناه.</small>
            <br><button class="btn btn-vanex">العودة الي الرئيسية</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>