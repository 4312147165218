<template>
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div class="card shadow">
        <div class="card-header card-header-primary">
          <h4 class="card-title">أسعار الشحن</h4>
        </div>
        <div class="card-body table-responsive" dir="rtl">
          <vanex-list-loading  v-if="isLoading" :rowcount="this.serverParams.perPage" />
           <vue-good-table
            v-if="!isLoading"
            mode="remote"
            :columns="columns"
            :rows="rows"
            :rtl="true"
            :isLoading.sync="isLoading"
            @on-page-change="onPageChange"
            @on-per-page-change="onPerPageChange"
            styleClass="vgt-table  bordered"
            :pagination-options="{
                enabled: (this.totalRecords / this.serverParams.perPage > 1) ? true : false ,
                mode: 'pages',
                perPageDropdownEnabled: false,
                perPage: this.serverParams.perPage,
                position: 'bottom',
                perPageDropdown: [3, 7, 9],
                dropdownAllowAll: true,
                setCurrentPage: this.serverParams.page,
                nextLabel: 'التالي',
                prevLabel: 'السابق',
                rowsPerPageLabel: 'عدد الصفوف في الصفحة',
                ofLabel: 'من',
                pageLabel: 'صفحة', 
                allLabel: 'الكل',
                
            }"
            :search-options="{
                    enabled: true,
                    trigger: 'enter',
                    skipDiacritics: true,
                    placeholder: 'أبحث عن المدينة هنا',
                    
                    
            }"
            @on-search="onPriceSearch"
            :sort-options="{
                enabled: false,
            }"
              :totalRows="totalRecords"
              
            >
            <div slot="emptystate" class="text-primary text-center">
              لا توجد أسعار لعرضها
            </div>
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'id'">
                 <span style="font-weight: bold">  {{getPaginateIndex(props.row.originalIndex, serverParams.page, serverParams.perPage)}}</span> 
                </span>
                <span v-else-if="props.column.field == 'name'">
                  <span style="font-weight: bold">{{props.row.name}}</span> 
                </span>
                <span v-else-if="props.column.field == 'price'">
                  <span style="font-weight: bold">{{props.row.price}} <small class="text-muted">د.ل</small></span> 
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
            </template>
           </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPaginateIndex, totop } from '../modules/helpers';
import {instance} from '../modules/instance';
export default {
  data() {
    return {
      isLoading : true,
      totalRecords:0,
     columns: [
        { label: '#', field: 'id' },
        { label: 'المدينة', field: 'name' },
        { label: 'كود المدينة', field: 'code' },
        { label: 'السعر (د.ل)', field: 'price' },
        { label: 'زمن الشحن ( باليوم )', field: 'est_time' },
      ],
      rows: [
       
        
      ],
      serverParams: {
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        
        query : '',
        page: 1, // what page I want to show
        perPage: 10 // how many items I'm showing per page
      }
    };
  },
  methods: {
      getPaginateIndex:getPaginateIndex,
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onPriceSearch(params){
      this.updateParams({query: params.searchTerm});
      this.loadItems();
    },

    
    
    
    // load items is what brings back the rows from server
    loadItems() {
      let _this = this;
      instance.get(`/delivery/price?page=${this.serverParams.page}&q=${this.serverParams.query}`)
        .then(function (response) {
          
          _this.totalRecords = response.data.data.meta.total
          _this.rows  = response.data.data.data
        })
        .catch(function (error) {
          // handle error
         
        })
        .then(function () {
         _this.isLoading = false
         totop()
        });
      
    }
  },
  mounted() {
    this.loadItems()
  },
};
</script>
