import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store';
import {closeMenu} from '../modules/jqueryFunc';

import {dashboardRouter } from './dashboard';
import {authRouter } from './auth';

Vue.use(VueRouter);

const routes = [
  /* dashboard routes */
  ...dashboardRouter,
  /* dashboard routes */
  /* Auth */
  ...authRouter,
  /* Auth */
];

const router = new VueRouter({
  mode: 'history',
  routes
})
// set title name by route name
const DEFAULT_TITLE = 'VANEX | CUSTOMER APP';
router.afterEach((to) => {
  
  Vue.nextTick(() => {
      document.title = `${to.name} | VANEX` || DEFAULT_TITLE;
  });
  
});
router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
      return next()
  }
  const middleware = to.meta.middleware

  const context = {
      to,
      from,
      next,
      store
  }
  return middleware[0]({
      ...context
  })
})
router.afterEach(()=>{
  closeMenu();
})

export default router



