var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card shadow"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-8"},[_vm._v(_vm._s(_vm.$t("tickets")))]),_c('div',{staticClass:"col-4 text-left"},[_c('router-link',{attrs:{"to":"/tickets/new"}},[_c('button',{staticClass:"btn btn-vanex btn-sm btn-ticket"},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('open_new_ticket'))+" ")])])],1)])]),_c('img',{staticClass:"card-img-top d-block d-sm-none",attrs:{"src":require("@/assets/images/tickets.png"),"alt":""}}),_c('div',{staticClass:"card-body",attrs:{"dir":"rtl"}},[(_vm.isLoading)?_c('vanex-list-loading',{attrs:{"rowcount":10}}):_c('div',{staticClass:"table-responsive"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"}),_c('ul',{staticClass:"nav nav-tabs my-2",attrs:{"id":"myTab","role":"tablist"}},_vm._l((_vm.statuses),function(selectStatus){return _c('li',{key:selectStatus.id,staticClass:"nav-item text-vanex",on:{"click":function($event){return _vm.setStatus(selectStatus)}}},[_c('a',{staticClass:"btn btn-white text-vanex",class:{ 'active': (_vm.status && (selectStatus.id == _vm.status.id)) },attrs:{"data-toggle":"tab"}},[_vm._v(_vm._s(selectStatus.title))])])}),0)])]),_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.totalRecords,"columns":_vm.columns,"rows":_vm.rows,"rtl":true,"styleClass":"vgt-table  bordered","pagination-options":{
              enabled: true,
              mode: 'pages',
              perPageDropdownEnabled: false,
              perPage: 10,
              position: 'bottom',
              perPageDropdown: [3, 7, 9],
              dropdownAllowAll: true,
              setCurrentPage: this.serverParams.page,
              nextLabel: 'التالي',
              prevLabel: 'السابق',
              rowsPerPageLabel: '',
              ofLabel: 'من',
              pageLabel: 'صفحة',
              allLabel: 'الكل',
            },"search-options":{
              enabled: false,
            },"sort-options":{
              enabled: false,
            }},on:{"on-per-page-change":_vm.onPerPageChange,"on-page-change":_vm.onPageChange,"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_vm._t("default",null,{"props":props}),(props.column.field == 'print')?_c('div',{staticClass:"btn-group",attrs:{"role":"group","aria-label":""}},[_c('button',{staticClass:"btn btn-danger btn-sm",attrs:{"type":"button"}},[_c('i',{staticClass:"material-icons"},[_vm._v("print")])])]):_vm._e(),(props.column.field == 'id')?_c('a',{staticClass:"text-vanex"},[_c('strong',[_vm._v(_vm._s(props.row.id)+"#")])]):(props.column.field == 'package_code')?_c('a',{staticClass:"text-vanex"},[_c('strong',{attrs:{"dir":"ltr"}},[_vm._v(_vm._s(props.row.package_code))])]):(props.column.field == 'status')?_c('a',[(props.row.status.id == 1)?_c('i',{staticClass:"fa fa-dot-circle-o mx-1"}):(props.row.status.id == 2)?_c('i',{staticClass:"fa fa-dot-circle-o mx-1 text-warning"}):(props.row.status.id == 3)?_c('i',{staticClass:"fa fa-dot-circle-o mx-1 text-success"}):_c('i',{staticClass:"fa fa-dot-circle-o mx-1"}),_vm._v(" "+_vm._s(props.row.status.title)+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,true)})],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }