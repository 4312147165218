<template>
  <button
    class="btn"
    :class="{ 'btn-danger': btnType==='danger','btn-success': btnType==='success' ,'btn-vanex': btnType==='primary' || !btnType   ,'btn-link': btnType==='link' ,'btn-info': btnType==='info' ,'btn-warning': btnType==='warning' ,'btn-text': btnType==='none' ,'btn-block': block  }"
    @click="$emit('click')"
    :disabled="disabled"
    :type="type ? type : 'button'"
  >
    <Spinner v-if="isLoading" type="2" :size="spinner_size" />
    <span class="text-white" v-if="!isLoading">{{ text }}</span>
  </button>
</template>

<script>
import Spinner from "./Spinner.vue";
export default {
  components: { Spinner },
  props: {
    isLoading: Boolean,
    text: String,
    type: String,
    spinner_size: String,
    block: Boolean,
    btnType: String,
    disabled: Boolean,
  },
};
</script>

<style>
</style>