<template>
  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12">
      <div class="card shadow">
        <!-- <div class="card-header card-header-primary">
                 <h4 class="card-title">{{$t('hints')}}</h4>
        </div> -->
        <img
          src="@/assets/images/hints-desktop.jpg"
          class="card-img-top d-none d-md-block d-lg-block d-xl-block"
          alt=""
        />
        <img
          src="@/assets/images/hints-mobile.png"
          class="card-img-top d-block d-sm-none"
          alt=""
        />

        <div class="card-body table-responsive" dir="rtl">
          <!-- <div class="row">
            <div class="col-12" dir="ltr">
              <carousel :per-page="1" :navigation-enabled="false" :min-swipe-distance="1">
                <slide v-for="image in images" :key="image.id">
                  <img width="100%"  :src="image.src" :id="image.id" alt="">
                </slide>
              </carousel>
            </div>
          </div> -->
          <div class="text-center my-2" v-if="isLoading">
            <vanex-list-loading :rowcount="10" />
          </div>
          <div class="row" v-else>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12" v-for="hint in hints" :key="hint.id">
              <router-link :to="`/hints/${hint.id}/show`">
                <div class="card shadow">
                  <img class="card-img-top" v-if="hint.featured_image" :src="hint.featured_image" alt="" />
                  <img class="card-img-top" v-else src="@/assets/images/icons/vnx-blank.jpg" alt="" />
                  <div class="card-body">
                    <h5 class="card-title text-vanex">{{ hint.title }}</h5>
                    <p class="card-text" v-html="hint.paragraph.substring(0, 50)"></p>
                    <a href="#" class="text-vanex">
                      <i style="font-size: 13px" class="fa fa-arrow-left mx-1"></i></a>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { instance } from "../../modules/instance";

  import Spinner from "../../components/public/Spinner.vue";
  export default {
    components: { Spinner },
    data() {
      return {
        hints: "",
        images:[
          {
            id : 1,
            src : 'https://scontent.ftip3-1.fna.fbcdn.net/v/t39.30808-6/s960x960/217528383_1734737783379902_7671250043260231691_n.jpg?_nc_cat=106&ccb=1-5&_nc_sid=e3f864&_nc_ohc=dcssckrcQ_EAX8Ck0Uh&_nc_ht=scontent.ftip3-1.fna&oh=4e3f7c8487dae9eb2198fbe69a52bf44&oe=6195A202'
          },
          {
            id : 2,
            src : 'https://scontent.ftip3-1.fna.fbcdn.net/v/t1.6435-9/187203287_1691495024370845_4087136939389338990_n.png?_nc_cat=104&ccb=1-5&_nc_sid=e3f864&_nc_ohc=QzBOT-PMAnkAX9mQ3-C&tn=hWjlCFPlhizYU_IH&_nc_ht=scontent.ftip3-1.fna&oh=5fe36337d90feaafceea23b8dae7caf9&oe=61B59D61'
          },
          {
            id : 3,
            src : 'https://scontent.ftip3-2.fna.fbcdn.net/v/t1.6435-9/185367033_1688086958044985_4981118556832568806_n.png?_nc_cat=107&ccb=1-5&_nc_sid=e3f864&_nc_ohc=xQ--QHGjVMcAX8FX5qD&_nc_ht=scontent.ftip3-2.fna&oh=bd0344723487cfbf691b55627a49c12c&oe=61B3AA6C'
          },
          {
            id : 4,
            src : 'https://scontent.ftip3-2.fna.fbcdn.net/v/t1.6435-9/172479116_1667111690142512_5652100085342864266_n.png?_nc_cat=100&ccb=1-5&_nc_sid=e3f864&_nc_ohc=QCVzHRw-8pIAX9x9jnh&_nc_ht=scontent.ftip3-2.fna&oh=2802d00611d37cac47b4f3bbd3364d51&oe=61B3EFCB'
          }
        ],
        isLoading: true,
      };
    },
    methods: {
      getHints() {
        let _this = this;
        _this.isLoading = true;
        instance
          .get("/ar/cms/category/2")
          .then(function (response) {
            _this.hints = response.data.CmsCategory.cms;
          })
          .catch(function (error) {

            // _this.$router.push({ path: "/register" });
          })
          .then(() => {
            _this.isLoading = false;
          });
      },
    },
    mounted() {
      this.getHints();
    },
  };
</script>

<style>
</style>