<template>
  <div class="col-lg-12 col-md-12">
    <div class="card shadow">
      <div>
        <div class="card-header card-header-danger">
          <h4 class="card-title">حالات الطقس</h4>
        </div>
        <div class="card-body table-responsive">
          <div dir="rtl">
            <div v-if="isLoading">
              <page-loading :show="true"></page-loading>
            </div>
            <div class="row" v-if="!isLoading">
              <div class="col-md-12 " v-if="tripoliTemp>0">
                <div class="card my-1">
                  <div class="card-body text-center text-primary">
                    <h1 class="text-primary" dir="rtl"><img :src="tripoliIcon" class="px-1" align="center" width="50" />
                      <a class="mx-2"><strong>{{ tripoliTemp }}</strong></a> </h1>
                    <h4 class="text-vanex">مدينة طرابلس</h4>
                     <small>{{notes}}</small>
                  </div>
                </div>
              </div>
              <div class="col-md-12 " v-if="benghaziTemp>0">
                <div class="card my-1">
                  <div class="card-body text-center text-primary">
                    <h1 class="text-primary" dir="rtl"><img :src="benghaziIcon" class="px-1" align="center" width="50" />
                      <a class="mx-2"><strong>{{ benghaziTemp }}</strong></a> </h1>
                    <h4 class="text-vanex">مدينة بنغازي</h4>
                     <small>{{notes}}</small>
                  </div>
                </div>
              </div>
              <div class="col-md-12 " v-if="misrataTemp>0">
                <div class="card my-1">
                  <div class="card-body text-center text-primary">
                    <h1 class="text-primary" dir="rtl"><img :src="misrataIcon" class="px-1" align="center" width="50" />
                      <a class="mx-2"><strong>{{ misrataTemp }}</strong></a> </h1>
                     <h4 class="text-vanex">مدينة مصراتة</h4>
                      <small>{{notes}}</small>
                  </div>
                </div>
              </div>
              <div class="col-md-12 " v-if="sabhaTemp>0">
                <div class="card my-1">
                  <div class="card-body text-center text-primary">
                    <h1 class="text-primary" dir="rtl"><img :src="sabhaIcon" class="px-1" align="center" width="50" /> <a
                        class="mx-2"><strong>{{ sabhaTemp }}</strong></a> </h1>
                     <h4 class="text-vanex">مدينة سبها</h4>
                     <small>{{notes}}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { instance } from "../modules/instance";
  export default {
    data() {
      return {
        tripoliTemp: 0,
        benghaziTemp: 0,
        misrataTemp: 0,
        sabhaTemp: 0,
        tripoliIcon: "",
        benghaziIcon: "",
        misrataIcon: "",
        sabhaIcon: "",
        notes : "",
        isLoading: true,
      };
    },
    async mounted() {
      await this.setWeather();
    },
    methods: {
      async getWeather() {
        const options = {
          method: "GET",
          url: `/customer-weather`,
        };
        let _this = this;
         instance
          .request(options)
          .then(function (response) {
            let lastTemp = parseInt(response.data.weather_data.main.temp - 273.15);
            let name = response.data.city;
            _this.notes = response.data.notes
            if (name == "tripoli") {
              _this.tripoliTemp = lastTemp;
              _this.tripoliIcon = `http://openweathermap.org/img/w/${response.data.weather_data.weather[0].icon}.png`;
            }
            if (name == "benghazi") {
              _this.benghaziTemp = lastTemp;
              _this.benghaziIcon = `http://openweathermap.org/img/w/${response.data.weather_data.weather[0].icon}.png`;
            }
            if (name == "misrata") {
              _this.misrataTemp = lastTemp;
              _this.misrataIcon = `http://openweathermap.org/img/w/${response.data.weather_data.weather[0].icon}.png`;
            }
            if (name == "sabha") {
              _this.sabhaTemp = lastTemp;
              _this.sabhaIcon = `http://openweathermap.org/img/w/${response.data.weather_data.weather[0].icon}.png`;
            }
          })
          .catch(function (error) {
            console.error(error);
          })
          .then(() => { });
      },
      async setWeather() {
        await this.getWeather();
        // await this.getWeather("benghazi");
        // await this.getWeather("misrata");
        // await this.getWeather("sabha");
        this.isLoading = false;
      },
    },
  };
</script>

<style></style>