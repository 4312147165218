<template>
  <div class="">
    <!-- <vanex-loading v-if="statisticsLoading" size="50" />
    <div class="row justify-content-center text-center" v-else>
        
        <div class="col-lg-4 my-0">
            <div class="card bg-vanex my-1">
                <div class="card-body p-4 text-center">
                    <h6 class="mb-0">الشحنات المرسلة</h6>
                    <h2 class="mt-0 d-inline"><strong>120 </strong></h2><small>شحنة</small>
                </div>
            </div>
        </div>
        <div class="col-lg-4 my-0">
            <div class="card bg-vanex my-1">
                <div class="card-body p-4 text-center">
                    <h6 class="mb-0">العنوان البريدي</h6>
                    <h2 class="mt-0" dir="ltr"><strong>{{defaultAddressCode}}</strong></h2>
                </div>
            </div>
        </div>
        <div class="col-lg-4 my-0">
            <div class="card bg-vanex my-1">
                <div class="card-body p-4 text-center">
                    <h6 class="mb-0">الشحنات المستلمة</h6>
                    <h2 class="mt-0 d-inline"><strong>34 </strong></h2><small>شحنة</small>
                </div>
            </div>
        </div>
    </div> -->
  </div>
</template>

<script>
import { instance } from "../modules/instance";
export default {
  name: "PackageStatistics",
  data() {
    return {
      statisticsLoading: false,
      defaultAddressCode: '',

      
    };
  },
  methods: {
    
    getPackagesCount() {
      let _this = this;
      _this.statisticsLoading = true;
      instance
        .get("/customer/packages/count")
        .then(function(response) {
          
        }).then(()=>{
            _this.statisticsLoading = false;
        })
    },
  },
  mounted() {
   
    this.getPackagesCount();
  },
};
</script>

<style></style>
