<template>
  <div :id="id" class="show-on-print" dir="rtl">
    <img src="@/assets/images/prints/header.svg" alt="VANEX" width="100%" />
    <slot dir="rtl"></slot>
  </div>
</template>

<script>
export default {
  props: ["id"],
};
</script>

