<template>
  <div id="app">
    <component :is="layout">
      <transition name="fade">
        <router-view />
      </transition>
    </component>
  </div>
</template>

<style lang="stylus" scoped>


@import './assets/css/vstyle.css';

</style>

<script>
 
const defaultLayout = 'blank'
export default {
  name : "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + '-layout';
    },
  }
};
</script>
