<template>
  <div class="container">
    <div class="row justify-content-center h-100">
      <div class="col-lg-12">
        <div class="card shadow">
          <div class="card-header card-header-primary">
            <h4 class="card-title">{{ $t("delivery_calculation") }}</h4>
          </div>
          <div class="card-body">
            <form @submit="calcPrice" novalidate>
            <div class="row justify-content-center">
              <div class="col-md-4 text-center mt-5 align-center align-items-center">
                <img src="@/assets/images/icons-v2/calculator.png" width="90%" alt="" />
              </div>
              <div class="col-md-8 bg-white">
                <div class="text-right">
                  <h4 class="text-vanex text-bold my-3">
                    {{ $t("delivery_calculation") }}
                  </h4>
                  <small class="text-right">{{
                    $t("delivery_calculation_details")
                    }}</small>
                </div>
                <div class="row">

                  <div :class="{ 'col-lg-6': subCities.length>0 , 'col-lg-4': subCities.length<=0 }">
                    <small class="text-muted">{{ $t("branch") }}</small>
                    <v-select @input="getCities()" v-model="branch" dir="rtl" label="name" :options="branches">
                    </v-select>
                    <small v-if="errors && errors['branch']" class="text-right text-danger d-inline"><i
                        class="fa fa-exclamation-circle mx-2"></i>
                      {{ errors["branch"] }}
                    </small>
                  </div>
                  <div :class="{ 'col-lg-6': subCities.length>0 , 'col-lg-4': subCities.length<=0 }" v-if="types">
                    <small class="text-muted">{{ $t("delivery_type") }}</small>
                    <v-select @input="resetInputs()" v-model="delivery_type" dir="rtl" label="name" :options="types">
                    </v-select>
                    <small v-if="errors && errors['delivery_type']" class="text-right text-danger d-inline"><i
                        class="fa fa-exclamation-circle mx-2"></i>
                      {{ errors["delivery_type"] }}
                    </small>
                  </div>
                  <div :class="{ 'col-lg-6': subCities.length>0 , 'col-lg-4': subCities.length<=0 }" v-if="cities">
                    <small class="text-muted">{{ $t("city") }}</small>
                    <v-select  v-model="city" dir="rtl" label="name" :options="cities">
                    </v-select>
                    <small v-if="errors && errors['city']" class="text-right text-danger d-inline"><i
                        class="fa fa-exclamation-circle mx-2"></i>
                      {{ errors["city"] }}
                    </small>
                  </div>
                  <div class="col-lg-6" v-if="subCities.length>0 && city">
                    <label for="address_child">{{$t('address_child')}}</label>
                    <v-select v-model="subCity" dir="rtl" label="name" :options="subCities">
                    </v-select>
                    <small v-if="errors && errors['address_child']" class="text-right text-danger d-inline"><i
                        class="fa fa-exclamation-circle mx-2"></i>
                      {{errors['address_child']}}
                    </small>
                  </div>
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-lg-12">
                        <Input v-if="delivery_type && [1,3,4,5].includes(delivery_type.id) "
                          :title="$t(((delivery_type.id!=3) ? 'price' : 'paper_value'))" v-model="price"
                          :error-message="errors" value="0" name="price" type="number" />
                      </div>
                      <div class="col-lg-4">
                        <Input v-if="delivery_type && [1,2].includes(delivery_type.id)" :title="$t('leangh')"
                          v-model="leangh" :error-message="errors" name="leangh" type="number" />
                      </div>
                      <div class="col-lg-4">
                        <Input v-if="delivery_type && [1,2].includes(delivery_type.id)" :title="$t('width')"
                          v-model="width" :error-message="errors" name="width" type="number" />
                      </div>
                      <div class="col-lg-4">
                        <Input v-if="delivery_type && [1,2].includes(delivery_type.id)" :title="$t('height')"
                          v-model="height" :error-message="errors" name="height" type="number" />
                      </div>
                    </div>
                  </div>
                </div>

                <Button :text="$t('calculate')" type="submit" :disabled="isLoading" :isLoading="isLoading" />
              </div>
            </div>
            </form>
          </div>
          <Modal withoutbtn id="price_modal" :title="$t('delivery_result')" type="none" size="small">
            <div slot="btn"></div>
            <div slot="body" class="py-2 text-center">
              <h1 class="text-vanex text-center">
                {{ deliveryPrice }}
                <span><small class="text-vanex">د.ل</small></span>
              </h1>
              <ul class="list-group list-group-flush" v-if="result">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  <strong>مدة التوصيل</strong>
                  <span class="badge badge-primary badge-pill">{{deliveryDays}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center"
                  v-if="result.commission_price>0">
                  <strong>عمولة التحصيل</strong>
                  <span class="badge badge-primary badge-pill">{{result.commission_price}} د.ل</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center"
                  v-if="result.extra_size_price>0">
                  <strong>عمولة الحجم</strong>
                  <span class="badge badge-primary badge-pill">{{result.extra_size_price}} د.ل</span>
                </li>
              </ul>
              <hr />
              <small class="text-muted">{{$t("delivery_result_notes")}}</small>
              <hr>
              <a href="/prices" class="btn btn-vanex btn-block">{{$t('show_prices')}}</a>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Button from "../components/public/Button.vue";
  import CheckBox from "../components/public/CheckBox.vue";
  import Input from "../components/public/Input.vue";
  import { totop } from "../modules/helpers";
  import { instance } from "../modules/instance";
  import Modal from "../components/public/Modal.vue";
  export default {
    components: { Input, Button, CheckBox, Modal },
    data() {
      return {
        isLoading: false,
        delivery_type: null,
        deliveryPrice: null,
        deliveryDays: null,
        price: 0,
        leangh: 35,
        width: 35,
        height: 35,
        city: null,
        branch: { id: 1, name: 'طرابلس' },
        types: [],
        cities: [],
        subCities: [],
        subCity: null,
        result: null,
        branches: [],
        errors: {
          delivery_type: null,
          branch: null,
          city: null,
          price: null,
          leangh: null,
          width: null,
          height: null,
        },
      };
    },
    methods: {
      getCities() {
        let _this = this;
        _this.cities = [];
        _this.city = null;
        _this.isLoading = true;
        instance
          .get(`/city/names?region=${(_this.branch) ? _this.branch.id : 1}`)
          .then(function (response) {
            let cities = response.data.data;
            _this.cities = cities;
          })
          .catch(function () { })
          .then(function () {
            _this.isLoading = false;
          });
      },
      getBranches() {
        let _this = this;
        _this.isLoading = true;
        instance
          .get("/customer/branches")
          .then(function (response) {
            if (response.data.status_code == 200) {
              _this.branches = response.data.data;
            }
          })
          .then(function () {
            _this.isLoading = false;
          });
      },
      getTypes() {
        let _this = this;
        _this.delivery_type = null;
        _this.types = null;
        _this.isLoading = true;
        instance
          .get(`/customer/package/types`)
          .then((res) => {
            _this.types = [];
            res.data.data.forEach((type) => {
              _this.types.push(type);
            });
            _this.delivery_type = _this.types[0]
          })
          .then(function () {
            _this.isLoading = false;
          });
      },
      getSubCities() {
        let _this = this;
        let cityId = this.city.id;
        _this.isLoading = true;
        _this.subCity = null;
        _this.subCities = [];
        instance.get(`/city/${cityId}/subs`).then(function (response) {
          let prices = response.data.data.data;
          _this.subCities = prices;
        }).then(() => {
          _this.isLoading = false;
        })
      },
      calcPrice(e) {
        e.preventDefault()
        let errors = 0;
        if (!this.leangh) {
          errors++;
          this.errors["leangh"] = this.$t("required", {
            title: this.$t("leangh"),
          });
        } else {
          this.errors["leangh"] = "";
        }
        if (!this.width) {
          errors++;
          this.errors["width"] = this.$t("required", { title: this.$t("width") });
        } else {
          this.errors["width"] = "";
        }
        if (!this.height) {
          errors++;
          this.errors["height"] = this.$t("required", {
            title: this.$t("height"),
          });
        } else {
          this.errors["height"] = "";
        }
        if (!this.delivery_type) {
          errors++;
          this.errors["delivery_type"] = this.$t("required", {
            title: this.$t("delivery_type"),
          });
        } else {
          this.errors["delivery_type"] = "";
        }
        if (!this.city) {
          errors++;
          this.errors["city"] = this.$t("required", { title: this.$t("city") });
        } else {
          this.errors["city"] = "";
        }
        // if (!this.price && this.delivery_type && [1, 3, 4, 5].includes(this.delivery_type.id)) {
        //   errors++;
        //   this.errors["price"] = this.$t("required", { title: this.$t(((this.delivery_type.id != 3) ? 'price' : 'paper_value')) });
        // } else {
        //   this.errors["price"] = "";
        // }

        if (errors == 0) {
          let _this = this;
          let params = {
            destination: _this.city ? _this.city.id : null,
            sender_region: _this.branch ? _this.branch.id : null,
            height: (_this.height) ? parseInt(_this.height) : 35,
            leangh: (_this.leangh) ? parseInt(_this.leangh) : 35,
            width: (_this.width) ? parseInt(_this.width) : 35,
            price: (_this.price) ? parseInt(_this.price) : 0,
            delivery_type: _this.delivery_type ? _this.delivery_type.id : null,
          };
          _this.isLoading = true;
          instance
            .get(`/delivery-calculation`, { params })
            .then(function (response) {
              _this.deliveryPrice = response.data.delivery_price;
              _this.deliveryDays = response.data.estimated_duration;
              _this.result = response.data
            })
            .catch(function (error) {
              // handle error
            })
            .then(function () {
              $("#price_modal").modal("show");
              _this.isLoading = false;
              totop();
            });
        }
      },
      resetInputs() {
        this.price = 0;
        this.leangh = 35;
        this.width = 35;
        this.height = 35;
        this.errors = {
          delivery_type: null,
          branch: null,
          city: null,
          price: null,
          leangh: null,
          width: null,
          height: null,
        }
        if (document.getElementById('price')) {
          document.getElementById('price').value = 0;
        }
        // document.getElementById('leangh').value = 35;
        // document.getElementById('width').value = 35;
        // document.getElementById('height').value = 35;
      }

    },
    mounted() {
      this.getTypes();
      this.getCities();
      this.getBranches();
    },
  };
</script>

<style></style>