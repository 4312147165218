<template>
    <div class="container">
        <div class="row justify-content-center h-100">
            <div class="col-lg-12">
                <div class="card  ">
                    <div class="card-header card-header-primary ">
                            <h4 class="card-title">{{$t('new_ban_customer')}}</h4>
                    </div>
                    <div class="card-body ">
                        <div class="row justify-content-center">
                            <div class="col-lg-12 bg-white p-4">
                                <form @submit="createBanCustomer" novalidate>
                                <div class="text-right">
                                    <h4 class="text-vanex text-bold my-3">{{$t('new_ban_customer')}}</h4>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <Input :title="$t('name')" v-model="name" :error-message="errors"
                                            name="name" type="text" />
                                    </div>
                                    <div class="col-lg-6">
                                        <Input :title="$t('phone')" v-model="phone" :error-message="errors" name="phone"
                                            type="number" />
                                    </div>
                                    <div class="col-lg-12">
                                        <Input :title="$t('notes')" v-model="notes" :error-message="errors" name="notes"
                                            type="text" />
                                    </div>
                                </div>

                                <Button :text="$t('create')" :disabled="isLoading" :isLoading="isLoading" type="submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from '../../components/public/Button.vue';
    import CheckBox from '../../components/public/CheckBox.vue';
    import Input from '../../components/public/Input.vue';
    import { totop } from '../../modules/helpers';
    import { instance } from "../../modules/instance";
    export default {
        components: { Input, Button, CheckBox },
        data() {
            return {
                name: "",
                phone: "",
                notes: "",
                isLoading: false,
                errors: {
                name: "",
                phone: "",
                notes: "",
                },
            };
        },
        methods: {
            createBanCustomer(e) {
                e.preventDefault()
                let errors = 0;
                if (!this.name) {
                    errors++
                    this.errors['name'] = this.$t('required', { title: this.$t('name') })
                } else {
                    this.errors['name'] = ""
                }
                if (!this.phone) {
                    errors++
                    this.errors['phone'] = this.$t('required', { title: this.$t('phone') })
                } else {
                    this.errors['phone'] = ""
                }
                if (!this.notes) {
                    errors++
                    this.errors['notes'] = this.$t('required', { title: this.$t('notes') })
                } else {
                    this.errors['notes'] = ""
                }


                if (errors === 0) {
                    this.isLoading = true;
                    let _this = this;
                    let data = {
                        name: this.name,
                        phone: this.phone,
                        notes: this.notes,
                    }
                    instance
                        .post("/customer/ban-requests", data)
                        .then(function (response) {
                            _this.$toasted.show(response.data.message, {
                                type: "success",
                            });
                            _this.$router.push({ path: '/ban' })
                        })
                        .catch(function (error) {
                            totop()
                            if (error.response && error.response.data && error.response.data.errors) {
                                for (const [key, value] of Object.entries(error.response.data.errors)) {
                                    _this.errors[key] = value[0]
                                }
                            }
                        })
                        .then(function () {
                            _this.isLoading = false;
                        });
                } else {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                }
                

            },
        },
    };
</script>

<style></style>