<template>
    <div class="vertical-center">
        <div class="container">
            <div class="row justify-content-center h-100">
                <div class="col-lg-5">
                    <div class="card  shadow">
                        <div class="card-body p-0">
                            <div class="row">
                                <div class="col-lg-10 bg-white col-centered text-center py-5">
                                    <div class="text-center my-4">
                                        <img src="@/assets/images/logo.svg" alt="VANEX" width="200" />
                                    </div>
                                    <img src="@/assets/images/icons/verification-icon.svg" width="300" />
                                    <h3 class="text-vanex text-bold">رمز التحقق</h3>
                                    <p class="text-muted">
                                        الرجاء إدخال رمز التحقق المكون من 5 خانات الذي تم إرساله إلى
                                        البريد الإلكتروني {{ email }}
                                    </p>
                                    <div dir="ltr">
                                        <CodeInput :fields="5" :loading="isLoading"
                                            className="input code-input-container"
                                            v-on:complete="onVerificationCodeComplete" />
                                    </div>
                                    <small v-if="isLoading" class="text-vanex text-center">الرجاء الإنتظار</small>
                                    <small v-if="errors.code && !isLoading"
                                        class="text-warning text-center">{{errors.code}}</small>
                                        <div class="text-center text-click" v-if="needResendOtpCodeCount <= 0 && needResendOtpCode && !needResendOtpCodeLoading" @click="resendOtp()">
                                            <hr/>
                                                <small>لم يصل الرمز ؟ <span class="text-vanex text-right">إعادة إرسال بإستخدام البريد الإلكتروني</span> 
                                                </small>
                                        </div>
                                        <div v-if="needResendOtpCodeLoading">
                                            <spinner  type="1" :size="17" />
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { instance } from "../../modules/instance";
    import CodeInput from "vue-verification-code-input";
    import { decryptText } from "../../modules/crypt";
import Spinner from '../../components/public/Spinner.vue';
    export default {
        name: "Verification",
        components: {
            CodeInput,
                Spinner,
        },
        data() {
            return {
                email: "",
                isLoading: false,
                needResendOtpCodeCount: 0,
                needResendOtpCodeFalseCount: 0,
                needResendOtpCodeLoading: false,
                needResendOtpCode: false,
                errors: {
                    code: ''
                },
            };
        },
        mounted() {
            let email = window.localStorage.getItem("register-email");
            if (email) {
                this.email = decryptText(email);
            } else {
                this.$router.push({ path: '/login' })
            }
            if(this.$route.query.withres == 1){
                this.needResendOtpCode =true;
            }
        },
        methods: {
            onVerificationCodeComplete(v) {
                this.isLoading = true;


                if (v && v.length == 5 && this.email) {
                    let _this = this;
                    _this.isLoading = true;
                    instance
                        .post("/verification", {
                            email: _this.email,
                            code: v,
                        })
                        .then(function (response) {
                            if (response.data.status_code == 200) {
                                window.localStorage.removeItem("register-email")
                                _this.$router.push({ path: '/success-verification' })
                            }
                        })
                        .catch(function (error) {
                            if (
                                error.response &&
                                error.response.data &&
                                error.response.data.errors
                            ) {
                                v = null
                                for (const [key, value] of Object.entries(
                                    error.response.data.errors
                                )) {
                                    _this.errors[key] = value[0];
                                }
                            }
                        })
                        .then(function () {
                            _this.isLoading = false;
                        });
                } else {
                    this.$toasted.show('يوجد خطا أثناء عملية التحقق', {
                        type: "danger",
                    });
                    this.isLoading = false;
                }
            },
            resendOtp() {
                let errors = 0;

                if (errors <= 0) {
                    let _this = this;
                    _this.needResendOtpCodeLoading =true;
                    instance
                        .post("/account-recovery", {
                            email: _this.email,
                        })
                        .then(function (response) {
                            _this.$toasted.show(response.data.message, {
                                type: "success",
                            });
                            window.localStorage.removeItem('register-email')
                            window.localStorage.setItem('register-email', cryptText(_this.email))
                            _this.$router.push({ path: '/verification' })
                        })
                        .catch(function (error) {
                            if (error.response && error.response.data && error.response.data.errors) {

                                for (const [key, value] of Object.entries(error.response.data.errors)) {
                                    _this.errors[key] = value[0]
                                }
                                 _this.needResendOtpCodeFalseCount ++ ;
                                _this.needResendOtpCode = (error.response.data.resend_otp_code) ? true : false
                            }
                        })
                        .then(function () {
                            _this.needResendOtpCodeLoading = false;
                            _this.needResendOtpCodeCount ++ ;

                            if(_this.needResendOtpCodeFalseCount >= 3){
                                _this.needResendOtpCodeCount = 0 ;
                                _this.needResendOtpCodeFalseCount = 0 ;
                            }
                        });

                }
            },
        },
    };
</script>