<template>
  <div>
    <div class="row">
      <div class="col-12 text-center loading-container my-4">
        <img
          src="@/assets/images/icons/empty.svg"
          alt="VANEX"
          :width="size !== null ? size : 40"
        />
        <br>
        <strong class="text-danger">{{message}}</strong>
        <br>
         <router-link to="/packages/new" class="btn btn-primary">
            إضافة شحنة جديدة
                      </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["size",'message'],
};
</script>

<style></style>
