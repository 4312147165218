<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4">
        <div class="card shadow">
          <div class="card-header">
            <div class="text-center">
              <img src="@/assets/images/logo.svg" alt="VANEX" width="200" />
              <h4 class="text-vanex">إضافة مفتاح Api</h4>
            </div>
            <Input
              :title="$t('email')"
              v-model="email"
              :error-message="errors"
              name="email"
              type="email"
            />
            <Input
              :title="$t('password')"
              v-model="password"
              :error-message="errors"
              name="password"
              type="password"
            />
            <button class="btn btn-block btn-vanex" @click="loginUser()">
              <spinner v-if="isLoading" type="2" size="20" />
              <span class="text-white" v-if="!isLoading">{{
                $t("check")
              }}</span>
            </button>

            <div v-if="token">
                <Input
                :title="$t('token')"
                v-model="token"
                name="token"
                type="text"
                ltrvalue="1"
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "../components/public/Input.vue";
import Spinner from '../components/public/Spinner.vue';
import { instance } from '../modules/instance';
export default {
  components: { Input, Spinner },
  data() {
    return {
      errors: {
        email: "",
        password: "",
        token: "",
      },
      email: "",
       token: "",
      password: "",
      isLoading: false,
      needResendOtpCode: false,
    };
  },
  methods:{
      loginUser() {
        let errors = 0;
        if (!this.email) {
          errors++
          this.errors['email'] = "حقل البريد الإلكتروني مطلوب ."
        }else{
          this.errors['email'] = ""
        }
        if (!this.password) {
          errors++
          this.errors['password'] = "حقل كلمة المرور مطلوب ."
        }else{
          this.errors['password'] = ""
        }
        if(errors <= 0){
          let _this = this;
          _this.isLoading = true;
          _this.token = null;
          instance
            .post("/authenticate", {
              email: _this.email,
              password: _this.password,
            })
            .then(function (response) {
              if (response.status == 200 && response.data.status_code == 200) {
                let token = response.data.data.access_token;
                _this.email = null;
                _this.password = null;
                _this.token = token;
                document.getElementById('email').value = null;
                document.getElementById('password').value = null;
              }
            })
            .catch(function (error) {
              if (error.response && error.response.data && error.response.data.errors) {
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                  _this.errors[key] = value[0]
                }
              }
              _this.token = null;
            })
            .then(function () {
              _this.isLoading = false;
            });
             
        }
      },
  }
};
</script>

<style>
</style>