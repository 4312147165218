<template>
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-8">
            <div class="card shadow">
                <div class="card-body table-responsive" dir="rtl">
                    <div class="text-center my-2" v-if="isLoading">
                        <Spinner type="1" size="30" />
                    </div>
                    <div class="row justify-content-center" v-else>
                        <div class="col-lg-12">
                            <img class="card-img-top" v-if="hint.featured_image" :src="hint.featured_image" alt="" />
                            <img class="card-img-top" v-else src="@/assets/images/icons/vnx-blank.jpg" alt="" />
                            <h3 class="card-title my-2 text-vanex">{{hint.title}}</h3>
                            <p class="card-text" v-html="hint.paragraph">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { instance } from "../../modules/instance";

    import Spinner from "../../components/public/Spinner.vue";
    export default {
        components: { Spinner },
        data() {
            return {
                hint: "",
                isLoading: true,
            };
        },
        methods: {
            getHint() {
                if (!this.$route.params.id) {
                    this.$router.push({ path: '/hints' })
                }
                let _this = this;
                _this.isLoading = true;
                instance
                    .get(`/ar/cms/${this.$route.params.id}`)
                    .then(function (response) {
                        _this.hint = response.data.data;
                    })
                    .catch(function (error) {
                        
                        // _this.$router.push({ path: "/register" });
                    }).then(() => {
                        _this.isLoading = false;
                    })
            },
        },
        mounted() {
            this.getHint();
        },
    };
</script>

<style>
</style>