<template>
  
  <span class="badge p-1" :class="{
      'badge-info text-white':infoArray.includes(status),
      'badge-warning':yellowArray.includes(status),
      'badge-danger':redArray.includes(status),
      'badge-success':greenArray.includes(status)
  }">{{arabicStatus}}</span>
</template>

<script>
export default {
  props: ["status"],
  data() {
    return {
      arabicStatus: "",
      infoArray : ['active'],
      yellowArray : ['ongoing','pending'],
      redArray : ['canceled','refused'],
      greenArray: ['complete'],
    };
  },
  methods: {
    transStatus() {
      switch (this.status) {
        case "active":
          return "طلب جديد";
          
        case "canceled":
          return "طلب ملغي";
       
        case "refused":
          return "طلب مرفوض";
         
        case "complete":
          return "طلب مكتمل";
          
        case "ongoing":
          return "جاري التنفيد";
        
        case "pending":
          return "قيد الإنتظار";

        default:
          return "حالة غير مسجلة - الرجاء الاتصال بالدعم الفني";
      }
    },
  },
  mounted() {
    this.arabicStatus = this.transStatus();
  },
  

};
</script>

<style></style>
