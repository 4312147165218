<template>
  <div class="row justify-content-center">
    <div class="col-lg-12">
      <div class="card shadow">
        <div class="card-header card-header-primary">
          <h4 class="card-title">{{ aboutUsTitle || $t("about_us") }}</h4>
        </div>
        <div class="card-body table-responsive" dir="rtl">
          <div class="text-right my-2">
            <div  v-html="aboutUs"></div>
            <router-link to="/terms" class="btn btn-white text-vanex">سياسة الخصوصية</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { instance } from "../modules/instance";

import Spinner from "../components/public/Spinner.vue";
export default {
  components: { Spinner },
  data() {
    return {
      aboutUs: "",
      aboutUsTitle: "",
    };
  },
  methods: {
    getTerms() {
      let _this = this;
      instance
        .get("/ar/cms/13")
        .then(function (response) {
          _this.aboutUs = response.data.data.paragraph;
          _this.aboutUsTitle = response.data.data.title;
        })
        .catch(function (error) {
          _this.$router.push({ path: "/register" });
        });
    },
  },
  mounted() {
    this.getTerms();
  },
};
</script>

<style>
</style>