<template>
  <div class="vertical-center" >
    <div class="container">
      <div  class="row justify-content-center h-100">
        <div class="col-lg-6">
          <div class="card shadow">
            <div class="card-body p-0">
              <div class="row justify-content-center">
                <div v-if="terms" class="col-12 text-center p-5">
                    <img src="@/assets/images/icons/terms.svg" width="200" alt="">
                    <h4 class="text-vanex">الشروط والأحكام وسياسة الخصوصية</h4>
                    <div class="text-right" v-html="terms.paragraph"></div>
                    <div class="text-right">
                        <router-link to="/register">
                        <button class="btn btn-vanex">تسجيل</button> 
                        </router-link>
                    </div>
                </div>
                <div v-else class="col-12 text-center p-5">
                    <Spinner type="1" size="35"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
import { instance } from '../../modules/instance';

  import Spinner from '../../components/public/Spinner.vue';
export default {
    components: {Spinner},
    data(){
        return {
            terms : '',
        }
    },
    methods:{
        getTerms(){
            let _this = this;
            instance
            .get("/policy")
            .then(function (response) {
              _this.terms = response.data.date;
            })
            .catch(function (error) {
              _this.$router.push({path : '/register'});
            });
        }
    },
    mounted(){
        this.getTerms()
    }

}
</script>
<style>
</style>