<template>
  <div class="col-lg-12 col-md-12">
    <div class="card shadow">

      <page-loading :show="shipmentsLoading"></page-loading>
      <div v-if="!shipmentsLoading && !shipmentsEmpty">

        <div class="card-header card-header-primary">
          <h4 class="card-title">قيد الإجراء</h4>
        </div>
        <div class="card-body table-responsive p-2">
          <table class="table table-hover table-bordered">
            <thead class="text-primary">
              <th>#</th>
              <th>المستلم</th>
              <th>المدينة</th>
              <th>الحالة</th>
              <th>اخر تحديث</th>
            </thead>
            <tbody>

              <tr v-for="shipment in shipments" :key="shipment.id">
                <td>
                  <router-link :to="`/packages/${shipment['package-code']}/show`">
                    <a class="text-vanex" dir="ltr">
                      {{shipment['package-code']}}
                    </a>
                  </router-link>
                </td>
                <td>{{shipment.reciever}}</td>
                <td>{{(shipment.City) ? shipment.City.name : ''}}</td>
                <td>{{shipment.status}}</td>
                <td>{{shipment.update_date}}</td>

              </tr>

            </tbody>
          </table>
        </div>
        <div class="card-footer">

        </div>

      </div>
      <empty-shipments v-if="shipmentsEmpty" size="140" message="لا يوجد شحنات قيد الإجراء حتى الأن" />
    </div>
  </div>
</template>

<script>
  import { instance } from "../modules/instance";
  export default {
    data() {
      return {
        shipments: [],
        shipmentsLoading: true,
        shipmentsEmpty: false,
      }
    },
    methods: {
      getStoreNewShipments() {
        let _this = this;
        _this.shipmentsLoading = true;
        instance
          .get("/customer/packages/status/store_new")
          .then(function (response) {

            if (response.data.data.length > 0) {

              response.data.data.forEach(element => {
                if (_this.shipments.length <= 5) {
                  _this.shipments.push(element)
                }
              });


            } else {
              _this.shipmentsEmpty = true
            }
          })
          .catch(function () {
            _this.$toasted.show("حدث خطا أثناء جلب الشحنات ", {
              type: "error",
            });
          })
          .then(function () {
            _this.shipmentsLoading = false;
          });
      },
    },
    mounted() {
      this.getStoreNewShipments()
    }
  };
</script>

<style></style>