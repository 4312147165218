<template>
    <div class="form-group">
        <small class="text-right text-muted">{{title}}</small>
        <select class="custom-select custom-select-sm" @input="$emit('input', $event.target.value)"
            :class="{ 'is-invalid': errorMessage[name] }" :id="name">
            <option selected disabled value="">قم بتحديد {{title}}</option>
            <slot></slot>
        </select>
        <small v-if="errorMessage[name]" class="text-right text-danger d-inline"><i class="fa fa-exclamation-circle mx-2"></i> {{errorMessage[name]}} </small>
    </div>
</template>

<script>
    export default {
        name: 'Select',
        props: ['title', 'name',  'errorMessage']
    }
</script>

<style>

</style>