<template>
  <div class="container mt-4">

    <div class=" rounded mx-auto">
      <div class="carousel m-0 ">

        <div class="row">
          <div class="col-2 col-lg-1">
            <div class="">
              <div class="position-relative"
                style="width: 50px; height:50px; border-radius:50%; border: 2px solid #0055cc; padding: 1px;">
                <img src="https://nextbootstrap.netlify.app/assets/images/profiles/2.jpg" alt="..."
                  class="img-fluid rounded-circle">
              </div>
              <div class="m-2 text-small">Anne</div>
            </div>
          </div>
          <div class="col-2 col-lg-1">
            <div class="">
              <div class="position-relative"
                style="width: 50px; height:50px; border-radius:50%; border: 2px solid #0055cc; padding: 1px;">
                <img src="https://nextbootstrap.netlify.app/assets/images/profiles/2.jpg" alt="..."
                  class="img-fluid rounded-circle">
              </div>
              <div class="m-2 text-small">Anne</div>
            </div>
          </div>
          <div class="col-2 col-lg-1">
            <div class="">
              <div class="position-relative"
                style="width: 50px; height:50px; border-radius:50%; border: 2px solid #0055cc; padding: 1px;">
                <img src="https://nextbootstrap.netlify.app/assets/images/profiles/2.jpg" alt="..."
                  class="img-fluid rounded-circle">
              </div>
              <div class="m-2 text-small">Anne</div>
            </div>
          </div>
          <div class="col-2 col-lg-1">
            <div class="">
              <div class="position-relative"
                style="width: 50px; height:50px; border-radius:50%; border: 2px solid #0055cc; padding: 1px;">
                <img src="https://nextbootstrap.netlify.app/assets/images/profiles/2.jpg" alt="..."
                  class="img-fluid rounded-circle">
              </div>
              <div class="m-2 text-small">Anne</div>
            </div>
          </div>
          <div class="col-2 col-lg-1">
            <div class="">
              <div class="position-relative"
                style="width: 50px; height:50px; border-radius:50%; border: 2px solid #0055cc; padding: 1px;">
                <img src="https://nextbootstrap.netlify.app/assets/images/profiles/2.jpg" alt="..."
                  class="img-fluid rounded-circle">
              </div>
              <div class="m-2 text-small">Anne</div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style scoped>
  /* @import url('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css'); */
</style>