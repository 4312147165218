<template>
  <div class="container">
    <div class="row justify-content-center h-100">
      <div class="col-lg-12">
        <div class="card shadow">
          <div class="card-header card-header-primary">
            <h4 class="card-title">{{$t('show_collect_details')}}</h4>
          </div>
          <div class="card-body " v-if="collectData">
            <div class="panel-body">
              <div class="col-12 mb-2" v-if="collectData.map !== null && collectData.map !== '' && lat && lng">
                <iframe width="100%" height="250" style="border:0" loading="lazy" allowfullscreen
                  :src="`https://www.google.com/maps/embed/v1/place?q=${lat},${lng}&key=AIzaSyBbLEntOWpXN2fG-b20QtZHHjQw0WP9wlo`">
                </iframe>
              </div>
              <div class="col-md-12">
                <h4 class="text-vanex heading-info"> البيانات العامة </h4>
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <tbody>
                      <tr class="bg-vanex-light">

                        <th class="text-vanex">رقم الطلب</th>
                        <th class="text-vanex">العنوان</th>
                        <th class="text-vanex">الهاتف</th>
                        <th class="text-vanex">الحالة</th>
                        <th class="text-vanex">خريطة التجميع</th>
                        <th class="text-vanex">ملاحظة</th>

                      </tr>

                      <tr>
                        <td style="direction:ltr; text-align:center" dir="ltr"> {{collectData.id}}</td>
                        <td style="direction:ltr; text-align:center"> {{collectData.address}}</td>
                        <td style="direction:ltr; text-align:center" dir="ltr"> {{collectData.phone}}</td>
                        <td style="direction:ltr; text-align:center">
                          <div class="text-right">
                            <get-collects-status v-if="collectData.status" :status="collectData.status" />
                          </div>
                        </td>
                        <td style="direction:ltr; text-align:center" dir="ltr">
                          <div v-if="collectData.map !== null && collectData.map !== ''">
                            <a :href="collectData.map" target="_blank" rel="noopener noreferrer"><i
                                class="material-icons text-success">remove_red_eye</i></a>
                          </div>
                        </td>
                        <td style="direction:ltr; text-align:center" dir="ltr"> {{collectData.notes}}</td>

                      </tr>
                    </tbody>
                  </table>
                </div>
                <h4 class="text-vanex heading-info"> الخدمات المضافة المطلوبة </h4>
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <tbody>
                      <tr class="bg-vanex-light">

                        <th class="text-vanex">طلب مسترد</th>
                        <th class="text-vanex">طلب تسوية مالية</th>
                        <th class="text-vanex">شحنات جاهزة</th>
                        <th class="text-vanex">تغليف الأمن</th>

                      </tr>

                      <tr>
                        <td style="direction:ltr; text-align:center" dir="ltr">
                          <div v-if="collectData.returns_request == 1" class="text-right">
                            <i class="material-icons text-success">task_alt</i>
                          </div>
                        </td>
                        <td style="direction:ltr; text-align:center">
                          <div v-if="collectData.sett == 1" class="text-right">
                            <i class="material-icons text-success">task_alt</i>
                          </div>
                        </td>
                        <td style="direction:ltr; text-align:center" dir="ltr">
                          <div v-if="collectData.ready == 1" class="text-right">
                            <i class="material-icons text-success">task_alt</i>
                          </div>
                        </td>
                        <td style="direction:ltr; text-align:center">
                          <div v-if="collectData.packaging == 1" class="text-right">
                            <i class="material-icons text-success">task_alt</i>
                          </div>
                        </td>


                      </tr>
                    </tbody>
                  </table>
                </div>
                <h4 class="text-vanex hedaing-info">السجل التفصيلي لطلب التجميع</h4>
                <div class="rtl">
                  <div id="content" class="">
                    <ul class="timeline">
                      <li class="event d-block my-3" v-for="(log,index) in logs" :key="index">
                        <strong class="d-block text-vanex">{{log.details}}</strong>
                        <small class="mt-0">
                          <custom-date :date="log.created_at"></custom-date>
                        </small>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" v-else>
            <vanex-list-loading :rowcount="10" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { by, totop } from '../../modules/helpers'
  import { instance } from '../../modules/instance'
  export default {
    data() {
      return {
        id: '',
        lat: null,
        lng: null,
        logs: [],
        collectData: null,
      }
    },
    methods: {
      getCollect() {
        if (!this.$route.params.id) {
          this.$router.push({ path: '/collects' })
          return false;
        }
        let _this = this
        let id = this.$route.params.id
        this.id = id
        instance
          .get(`/customer/collects/${id}/show`)
          .then((response) => {
            _this.collectData = response.data.data
            if (_this.collectData.map) {
              let url = _this.collectData.map;
              url = url.split('?q=')[1]
              if (url) {
                url = url.split(',');
                _this.lat = url[0];
                _this.lng = url[1];
              }
            }
          })
          .catch(function () {
            _this.$router.push({ path: '/collects' })
          })
          .then(function () {
            _this.getCollectLog();
          });


      },
      getCollectLog(){
        if(this.id){
          let _this = this
                instance
                    .get(`/customer/collects/${_this.id}/logs`)
                    .then((response) => {
                        _this.logs = response.data.data
                    })
                    .catch(function () {
                        _this.$router.push({ path: '/collects' })
                    })
                    .then(function () {

                    });
        }
      },

      byPerson(byData) {
        return by(byData)
      }
    },
    mounted() {
      this.getCollect()
      totop()
    }
  }
</script>

<style>

</style>