<template>
  <div class="vertical-center">
    <div class="container">
      <div class="row justify-content-center h-100">
        <div class="col-lg-4">
          <div class="card  shadow">
            <div class="card-body ">
              <div class="row justify-content-center">
                <div class="col-lg-12 bg-white p-5">
                  <div class="text-center my-4">
                    <img src="@/assets/images/logo.svg" alt="VANEX" width="200" />
                  </div>
                  <h4 class="text-vanex text-bold text-center">{{$t('reset_password')}}</h4>
                  <p  class="text-muted  text-center">{{$t('reset_password_text')}}</p>
                  <Input :title="$t('email')" v-model="email" :error-message="errors" name="email"
                    type="email" />
                  <button class="btn btn-block btn-vanex" @click="resetPassword()">
                    <Spinner v-if="isLoading" type="2" size="20"/>
                    <span class="text-white" v-if="!isLoading">{{$t('reset_password')}}</span>
                  </button>
                  <router-link to="/login"><small class="text-vanex text-right ">{{$t('login')}}</small></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { instance } from "../../modules/instance";
  import { cryptText } from "../../modules/crypt";
  import Input from '../../components/public/Input.vue';
  import Spinner from '../../components/public/Spinner.vue';

export default {
components: { Input , Spinner },
data(){
  return {
        errors: {
          email: '',
          password: '',
        },
        email: "",
        password: "",
        isLoading: false,
        needResendOtpCode: false,
      };
},
methods:{
  resetPassword(){
    let errors = 0;
        if (!this.email) {
          errors++
          this.errors['email'] = "حقل البريد الإلكتروني مطلوب ."
        }else{
          this.errors['email'] = ""
        }
        if(errors <= 0){
          let _this = this;
          _this.isLoading = true;
          instance
            .post("/password-reset", {
              email: _this.email,
              from: 'web',
            })
            .then(function (response) {
              _this.$toasted.show(response.data.message, {
                type: "success",
              });
              _this.$router.push({ path: '/login' })
            })
            .catch(function (error) {
              if (error.response && error.response.data && error.response.data.errors) {
                
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                  _this.errors[key] = value[0]
                }

                
              }
            })
            .then(function () {
              _this.isLoading = false;
            });
             
        }
  }
}
};
</script>

<style></style>
