import { instance } from "./instance";

function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}
function logout() {
  instance.get('/logout').then();
  localStorage.removeItem('access_token')
  localStorage.removeItem('user')
  localStorage.removeItem('scount')
  window.open('/', '_self')
}
function totop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  try {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  } catch (error) {

  }
}
function by(by) {
  if (by == 'customer') {
    return 'المستلم';
  }
  if (by == 'market') {
    return 'المرسل';
  }
  if (by == 'pre') {
    return 'مسبقة الدفع';
  }
}
function getPaginateIndex(index, page = 1, perPage) {
  try {
    page = (page) ? page : 1;
    return index + ((page * perPage) - (perPage - 1));
  } catch (e) {
    return 0;
  }
}
function checkValidToken(store) {

  try {
    instance.get('check-token').then((res) => {
    }).catch((err) => {
      if (err.response.data.result == false) {
        logout()
      }
    })
  } catch (error) {
    logout()
  }

}
function scrollToId(id) {
  let objDoc = document.getElementById(id);
  if (objDoc) {
    objDoc.scrollIntoView({
      behavior: 'smooth'
    });
  }
}
function isValidDate(d) {
  var checkDate = new Date(d);
  return !isNaN(checkDate.getTime());
}
function shareAddress(code) {
  if (navigator.share) {
    navigator.share({
      title: this.$t('address_share'),
      text: 'يمكنك إرسال شحنات الي عنواني البريدي عن طريق شركة فانكس للشحن السريع',
      url: '/packages/new?c=' + code
    }).catch(console.error);
  }
}
export { validURL, shareAddress, logout, totop, by, getPaginateIndex, scrollToId, isValidDate, checkValidToken };
