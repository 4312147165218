


import Login from "../views/auth/login.vue";
import Register from "../views/auth/register.vue";
import Verification from "../views/auth/verification.vue";
import ForgotPassword from "../views/auth/forgot_password.vue";
import resetPassword from "../views/auth/reset_password.vue";
import Terms from "../views/auth/terms.vue";
import CreateApiKey from "../views/create-api-key.vue";
import SuccessVerification from "../views/auth/success_verification.vue";
import guest from './middleware/guest';
const authMeta = { layout: 'blank', middleware: [guest] };
export const authRouter = [
  {
    path: "/login",
    name: "تسجيل دخول",
    component: Login,
    meta: authMeta,
  },
  {
    path: "/create-api-key",
    name: "Create Api Key",
    component: CreateApiKey,
    meta: { layout: 'blank' },
  },
  {
    path: "/register",
    name: "تسجيل حساب جديد",
    component: Register,
    meta: authMeta
  },
  {
    path: "/verification",
    name: "تأكيد تسجيل حسابك",
    component: Verification,
    meta: authMeta
  },
  {
    path: "/success-verification",
    name: "تم تأكيد تسجيل حسابك",
    component: SuccessVerification,
    meta: authMeta
  },
  {
    path: "/forgot-password",
    name: "إعادة تعيين كلمة المرور",
    component: ForgotPassword,
    meta: authMeta
  },
  {
    path: "/reset-password",
    name: "تعيين كلمة مرور الجديدة",
    component: resetPassword,
    meta: authMeta
  },
  {
    path: "/sign-up-terms",
    name: "تعيين كلمة مرور الجديدة",
    component: Terms,
    meta: authMeta
  },

]