<template>
  <div class="card shadow">
    <div class="card-header card-header-primary">
      <h4 class="card-title">{{$t('search_for_package')}}</h4>
    </div>
    <div class="card-body">
      <div class="row">
        
        <div class="col-lg-12">
          <Input :title="$t('description')" v-model="description" :error-message="errors" name="description"
            type="text" />
        </div>
        <div class="col-lg-6">
          <Input :title="$t('phone')" v-model="phone" :error-message="errors" name="phone" type="number" />
        </div>
        <div class="col-lg-6">
          <Input :title="$t('phone_b')" v-model="phone_b" :error-message="errors" name="phone_b" type="number" />
        </div>
        <div class="col-lg-6">
          <Input :title="$t('address')" v-model="address" :error-message="errors" name="address" type="text" />
        </div>
        <div class="col-lg-6">
          <Input :title="$t('reciever')" v-model="reciever" :error-message="errors" name="reciever" type="text" />
        </div>
        <div class="col-lg-6">
          <Input :title="$t('from_date')" v-model="from_date" :error-message="errors" name="from_date" type="date" />
        </div>
        <div class="col-lg-6">
          <Input :title="$t('to_date')" v-model="to_date" :error-message="errors" name="to_date" type="date" />
        </div>
        <div class="col-lg-6">
          <label for="city">{{$t('city')}}</label>
          <v-select @input="getSubCities()" v-model="city" dir="rtl" label="name" :options="cities"></v-select>
          <small v-if="errors && errors['city']" class="text-right text-danger d-inline"><i
              class="fa fa-exclamation-circle mx-2"></i>
            {{errors['city']}}
          </small>
        </div>
        <div class="col-lg-6" v-if="subCities.length>0 && city">
          <label for="sub_city">{{$t('sub_city')}}</label>
          <v-select v-model="sub_city" dir="rtl" label="name" :options="subCities">
          </v-select>
          <small v-if="errors && errors['sub_city']" class="text-right text-danger d-inline"><i
              class="fa fa-exclamation-circle mx-2"></i>
            {{errors['sub_city']}}
          </small>
        </div>
       
        
        <div class="col-12">
          <Button :text="$t('search')" :isLoading="isLoading" @click="searchPackages()" />
        </div>
        
        <div class="col-12">
          <div v-if="packages.length>0">
            <ul class="list-group list-group-flush mt-2">
              <router-link :to="`/packages/${selectedPackage['package-code']}/show`"  v-for="selectedPackage in packages" :key="selectedPackage.id" >
                <li class="list-group-item d-flex justify-content-between align-items-center text-click" dir="ltr">
                  <span class="btn btn-success btn-sm"> عرض  <i class="fa fa-eye mr-1"></i></span>
                  <div>
                    <strong>{{selectedPackage['package-code']}}</strong><br/>
                    <small class="text-muted">{{(selectedPackage['description']) ? selectedPackage['description'] : null}}</small>
                  </div>
                </li>
              </router-link>
            </ul>
            <span id="lasPackage"></span>
          </div>
          <div class="col-12" v-if="notFound">
            <alert  message="لا توجد نتائج بحث حسب خيارات البحث" type="vanex" ></alert>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Button from '../components/public/Button.vue';
  import CheckBox from '../components/public/CheckBox.vue';
  import Input from '../components/public/Input.vue';
  import { scrollToId, totop } from '../modules/helpers';
  import { instance } from "../modules/instance";
  import Spinner from "../components/public/Spinner.vue";

  export default {
    components: { Input, Button, CheckBox, Spinner },
    data() {
      return {
        isLoading: false,
        notFound:false,
        description: '',
        from_date: '',
        to_date: '',
        cities: [],
        subCities: [],
        statuses: [],
        packages: [],
        city: null,
        status: null,
        phone: null,
        phone_b: null,
        sub_city: null,
        address: null,
        reciever: null,
        errors: {
          description: '',
          from_date: '',
          city: '',
          sub_city: '',
          address: '',
          reciever: '',
          phone: '',
          phone_b: '',
          status: '',
          to_date: ''
        }
      }
    },
    methods: {
      getCities() {
        let _this = this;
        _this.cities = [];
        _this.citiesLoading = true;
        instance
          .get("/main-cities")
          .then(function (response) {
            let cities = response.data.data;
            _this.cities = cities;
          });
      },
      getPackageStatuses() {
        let _this = this;
        _this.cities = [];
        _this.citiesLoading = true;
        instance
          .get("/customer/package/statuses")
          .then(function (response) {
            let statuses = response.data.data;
            _this.statuses = statuses;
          });
      },
      getSubCities() {
        let _this = this;
        let cityId = (this.city) ? this.city.id : '';
        _this.citiesLoading = true;
        _this.sub_city = null;
        instance.get(`/main-cities/${cityId}`).then(function (response) {
          let prices = response.data.data;
          _this.subCities = prices;
        }).then(() => {
          _this.citiesLoading = false;
        })
      },
      searchPackages() {
        this.notFound = false;
        let data = {
          description: this.description,
          reciever: this.reciever,
          address: this.address,
          fromDate: this.from_date,
          toDate: this.to_date,
          city: (this.city) ? this.city.id : null,
          sub_city: (this.sub_city) ? this.sub_city.id : null,
          phone: this.phone,
          phone_b: this.phone_b,
          status: (this.status) ? this.status.status_value : null,
        }
        let _this = this;
        _this.isLoading = true;
        _this.packages = [];
        instance.post(`/customer/package/search`,data).then(function (response) {
         
          if(response.data){
            
            if(response.data.data){
              _this.packages = response.data.data.data;
              
            }else{
              _this.notFound = true;
            }
          }else{
              _this.notFound = true;
            }
        })
        .then(() => {
          _this.isLoading = false;
          scrollToId('lasPackage')
        })
      }
    },
    mounted() {
      this.getCities()
      this.getPackageStatuses()
    }
  }
</script>

<style>

</style>