<template>
    <div class="vertical-center">
        <div class="container">
            <div class="row justify-content-center h-100">
                <div class="col-lg-5">
                    <div class="card shadow ">
                        <div class="card-body p-0">
                            <div class="row">
                                <div class="col-lg-10 bg-white col-centered text-center py-5">
                                    <img src="@/assets/images/icons-v2/success-verification.png" height="250" width="400" />
                                    <h3 class="text-vanex text-bold">تم التحقق بنجاح</h3>
                                    <p class="text-muted text-center">
                                        تم التحقق من الرمز بنجاح، يرجى الدخول على ملفك الشخصي وتعبئة باقي البيانات ليتم تفعيل حسابك والإستفادة من خدمات النظام.
                                    </p>
                                    <router-link to="/login"><button class="btn btn-block btn-vanex" >
                                        تسجيل الدخول
                                    </button></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CodeInput from "vue-verification-code-input";
    export default {
        name: "SuccessVerification",
        components: {
            CodeInput,
        },
        data() {
            return {
                
            };
        },
        mounted() {
            
        },
        methods: {
            
        },
    };
</script>

<style>
</style>