<template>
  <div class="row">
    <div class="col-lg-12 col-md-12">

      <div class="card shadow">
        <div class="card-header card-header-primary">
          <h4 class="card-title">{{$t('collects_reports')}}</h4>
        </div>
        <vanex-list-loading :rowcount="10" v-if="isLoading"  />
        <div class="card-body table-responsive" dir="rtl" v-if="!isLoading">
          <vue-good-table  :columns="columns" :rows="rows" :rtl="true" styleClass="vgt-table  bordered"
            :isLoading.sync="isLoading" :pagination-options="{
                enabled: true ,
                mode: 'pages',
                perPageDropdownEnabled: false,
                perPage: 10,
                position: 'bottom',
                perPageDropdown: [3, 7, 9],
                dropdownAllowAll: true,
                setCurrentPage: 1,
                nextLabel: 'التالي',
                prevLabel: 'السابق',
                rowsPerPageLabel: 'عدد الصفوف في الصفحة',
                ofLabel: 'من',
                pageLabel: 'صفحة', 
                allLabel: 'الكل',
                
            }" :search-options="{
                    enabled: true
            }" :sort-options="{
                enabled: false,
            }">
            <template slot="table-row" slot-scope="props">
              <slot :props="props" />


              <div v-if="props.column.field == 'returns_request'">
                <div v-if="props.row.returns_request == 1" class="text-right"><i
                    class="material-icons text-success">task_alt</i></div>
                <div v-if="props.row.returns_request == 0" class="text-right"><i
                    class="material-icons text-danger">remove_circle_outline</i></div>
              </div>

              <div v-else-if="props.column.field == 'sett'">
                <div v-if="props.row.sett == 1" class="text-right"><i class="material-icons text-success">task_alt</i>
                </div>
                <div v-if="props.row.sett == 0" class="text-right"><i
                    class="material-icons text-danger">remove_circle_outline</i></div>
              </div>

              <div v-else-if="props.column.field == 'ready'">
                <div v-if="props.row.ready == 1" class="text-right"><i class="material-icons text-success">task_alt</i>
                </div>
                <div v-if="props.row.ready == 0" class="text-right"><i
                    class="material-icons text-danger">remove_circle_outline</i></div>
              </div>
              <div v-else-if="props.column.field == 'status'">
                <div class="text-right">
                  <get-collects-status :status="props.row.status" />
                </div>
              </div>
              <div v-else-if="props.column.field == 'map'">
                <div v-if="props.row.map !== null && props.row.map !== '' && validURL(props.row.map)">
                  <a :href="props.row.map" target="_blank" rel="noopener noreferrer"><i
                      class="material-icons text-success">remove_red_eye</i></a>
                </div>
              </div>
              <div v-else-if="props.column.field == 'notes'">
                <button v-if="props.row.notes" type="button" class="btn btn-link " data-toggle="modal"
                  :data-target="`#view_note${props.row.id}`">
                  <i class="material-icons text-success">remove_red_eye</i>
                </button>
                <blank-modal :id="`view_note${props.row.id}`" title="عرض ملاحظة طلب تجميع" :body="props.row.notes" />
              </div>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
          <!-- <div class="row">
            <div class="col-lg-4">
              <div class="card bg-primary">
                <div class="card-body">
                  <small>إجمالي عدد طلبات التجميع</small><br />
                  <h3 class="d-inline">{{totalCollectsCount}}</h3> <small class="mx-1 d-inline">طلب تجميع</small>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card bg-success">
                <div class="card-body">
                  <small>إجمالي عدد الشحنات</small><br />
                  <h3 class="d-inline">{{totalPackagesCount}}</h3> <small class="mx-1 d-inline">شحنة</small>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card bg-warning">
                <div class="card-body">
                  <small>إجمالي سعر التجميع</small><br />
                  <h3 class="d-inline">{{totalAmount}}</h3> <small class="mx-1 d-inline">د.ل</small>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { instance } from '../../modules/instance';
  import { isValidDate, validURL } from '../../modules/helpers';
  export default {
    data() {
      return {
        isLoading: true,
        fromDate: null,
        toDate: null,
        totalPackagesCount: 0,
        totalCollectsCount: 0,
        totalAmount: 0,

        columns: [
          { label: '#', field: 'id' },
          { label: 'العنوان', field: 'address' },
          { label: 'رقم الهاتف', field: 'phone' },
          { label: 'عدد الشحنات', field: 'num_pkg' },
          { label: 'طلب المسترد', field: 'returns_request' },
          { label: 'طلب التسوية المالية', field: 'sett' },
          { label: 'الشحنات جاهزة', field: 'ready' },
          { label: 'الحالة', field: 'status' },
          { label: 'خريطة التجميع', field: 'map' },
          { label: 'الملاحظات', field: 'notes' },

        ],
        rows: [],
        serverParams: {

          columnFilters: {
          },
          page: 1, // what page I want to show
          perPage: 10 // how many items I'm showing per page
        }
      };
    },
    methods: {
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },

      onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems();
      },

      onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage });
        this.loadItems();
      },
      // load items is what brings back the rows from server
      loadItems() {
        this.fromDate = this.$route.query['from-date'];
        this.toDate = this.$route.query['to-date'];
        if (!this.fromDate || !this.toDate || !isValidDate(this.$route.query['from-date']) || !isValidDate(this.$route.query['to-date'])) {
          this.$router.push({ path: '/reports' })
        }
        let _this = this;
        this.totalPackagesCount = 0;
        this.totalCollectsCount = 0;
        this.totalAmount = 0;
        instance.get(`/customer/collects?fd=${_this.fromDate}&td=${_this.toDate}`)
          .then(function (response) {
            _this.rows = response.data.data.data
          })
          .catch(function (error) {
            // handle error

          })
          .then(function () {
            _this.rows.forEach(collect => {
              _this.totalCollectsCount+=1;
              _this.totalPackagesCount += collect.num_pkg;
              _this.totalAmount += parseInt(collect.amount);
            });
            _this.isLoading = false

          });

      },
      validURL: validURL

    },
    mounted() {
      this.loadItems()

    },
  };
</script>