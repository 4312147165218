<template>
  <div id="wrapper" v-if="show">
    <img id="loading-logo-img" src="@/assets/images/logo-loading.svg" />
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
};
</script>

<style>
</style>