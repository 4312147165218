<template>
    <div class="d-inline">
        <input :disabled="disabled" :id="name" type="checkbox" :checked="checked" @change="change">
        <label :for="name" class="mx-1 d-inline">
            <slot></slot>
        </label>
        <small v-if="errorMessage[name]" class="text-right text-danger d-block"><i
                class="fa fa-exclamation-circle mx-2"></i> {{errorMessage[name]}} </small>

    </div>
</template>

<script>
    export default {
        name: 'CheckBox',
        props: ['name', 'errorMessage','value','disabled'],
        data: function () {
            return {
                checked: this.value
            };
        },

        methods: {
            change: function () {
                this.checked = !this.checked;
                this.$emit('input', this.checked);
            }
        }
    }
</script>

<style>

</style>