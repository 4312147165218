<template>
    <div class="row mt-0">
        <div class="col-lg-6" v-if="statisticsLoading">
            <div class="card shadow">
                <vanex-list-loading :rowcount="2" />
            </div>
        </div>
        <div class="col-lg-6" v-if="statisticsLoading">
            <div class="card shadow">
                <vanex-list-loading :rowcount="2" />
            </div>
        </div>
        <div class="col-lg-6" v-if="!statisticsLoading">
            <div  v-if="defaultAddressCode" :style="{ backgroundImage: 'url(' + require('@/assets/images/default-address-bg.jpg') + ')' }"
                class="card shadow text-white default-address-card  my-1">
                <div class="card-body text-center row ">
                    <div class="col-12">
                    </div>
                    <div class="col-lg-12">
                        <small>العنوان البريدي الرئيسي</small>
                        <h2 class="mt-0" dir="ltr">
                            <strong>{{ defaultAddressCode }}</strong>
                        </h2>
                        <button class="btn btn-light btn-sm" @click="shareAddress(defaultAddressCode)"><i
                                class="material-icons">share</i> مشاركة العنوان </button>
                    </div>
                    
                </div>
            </div>
            <div  v-else 
                class="card shadow text-vanex default-address-card ">
                <div class="card-body text-center row align-items-center" >
                    <div class="col-4 ">
                        <img src="@/assets/images/icons-v2/new-address.png" width="120%" alt="">
                    </div>
                    <div class="col-8">
                        <strong class="d-block">ليس لديك عنوان بريدي</strong> 
                        <small class="d-block">تساعدك العناوين البريدية في تسهيل عملية تحديد عنوانك</small>
                        <router-link to="/addresses/new" class="btn btn-light btn-smy">إضافة عنوان بريدي جديد</router-link>
                    </div>
                    
                    
                </div>
            </div>
        </div>
        <div class="col-lg-6" v-if="!statisticsLoading">
            <div class="row">
                <div class="col-6 col-lg-6">
                    <div class="card shadow bg-warning my-1">
                        <div class="card-body text-center">
                            <h6>إجمالي الشحنات</h6>
                            <h4>{{packagesCount}} <small class="text-very-small">شحنة</small></h4>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-6  col-lg-4">
                    <div class="card shadow bg-warning  card-header-warning my-1">
                        <div class="card-body text-center">
                            <h6>إجمالي المعاملات</h6>
                            <h4>{{totalCost}} <small class="text-very-small">د.ل</small></h4>
                        </div>
                    </div>
                </div> -->
                <div class="col-6 col-lg-6">
                    <div class="card shadow bg-success  card-header-success my-1">
                        <div class="card-body text-center">
                            <h6>المبلغ المستحق</h6>
                            <h4>{{balance }} <small class="text-very-small">د.ل</small></h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { shareAddress } from '../modules/helpers';
    import { instance } from "../modules/instance";
    export default {
        data() {
            return {
                statisticsLoading: true,
                defaultAddressCode: "",
                packagesCount: 0,
                totalCost: 0,
                balance: 0,
            };
        },
        methods: {
            getDefaultAddress() {
                let _this = this;
                _this.statisticsLoading = true;
                instance
                    .get("/user/info/default")
                    .then(function (response) {
                        _this.defaultAddressCode = response.data.data.code;
                    })
                    .catch(err => {
                        _this.defaultAddressCode = '';
                    })
                    .then(() => {
                        _this.statisticsLoading = false;
                    })
                _this.statisticsLoading = true;
                instance
                    .get("/statistics")
                    .then(function (response) {
                        _this.packagesCount = response.data.data.packages_count;
                        _this.totalCost = response.data.data.total_cost;
                        _this.balance = response.data.data.balance;
                    })
                    .then(() => {
                        _this.statisticsLoading = false;
                    })
            },
            shareAddress: shareAddress
        },
        mounted() {
            this.getDefaultAddress();
        },
    };
</script>

<style>
</style>