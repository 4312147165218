
import axios from 'axios';
import store from '../store/auth';
import { logout } from "./helpers";
let token = (store.getters.loggedIn) ? `Bearer ${store.state.access_token}` : '';

export const instance = axios.create({
    // baseURL: 'http://127.0.0.1:8000/api/v1',
    baseURL: 'https://api.vanextest.com.ly/api/v1',
    // baseURL: 'https://app.vanex.ly/api/v1',
    timeout: 50000,
    responseType: 'json',
    headers: { 'Authorization': token, 'Access-Control-Allow-Credential': true },
});

