<template>
    <div class="container">
        <div class="row justify-content-center h-100">
            <div class="col-lg-12">
                <div class="card  ">
                    <div class="card-header card-header-primary ">
                            <h4 class="card-title">{{$t('new_user_info')}}</h4>
                    </div>
                    <div class="card-body ">
                        <form @submit="createAddress" novalidate>
                            <div class="row justify-content-center">
                                <div class="col-lg-12 bg-white p-4">

                                    <div class="text-right">
                                        <h4 class="text-vanex text-bold my-3">{{$t('new_user_info')}}</h4>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <Input :title="$t('name')" v-model="name" :error-message="errors"
                                                name="name" type="text" />
                                        </div>
                                        <div class="col-lg-6">
                                            <Input :title="$t('description')" v-model="description" :error-message="errors"
                                                name="description" type="text" />
                                            <div class="btn-group">
                                                <button type="button" v-for="(type,index) in types" @click="setType(type)" :key="index" class="btn badge badge-vanex mx-1">{{type}}</button>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-6">
                                            <Input :title="$t('phone')" v-model="phone" :error-message="errors" name="phone"
                                                type="number" />
                                        </div>
                                        <div class="col-lg-6">
                                            <Input :title="$t('phone_b')" v-model="phone_b" :error-message="errors"
                                                name="phone_b" type="number" />
                                        </div>
                                        <div  :class="{ 'col-lg-6': subCities.length>0 , 'col-lg-12': subCities.length<=0 }">
                                            <label for="city">{{$t('city')}}</label>
                                            <v-select @input="getSubCities()" v-model="city" dir="rtl" label="name"
                                                :options="cities"></v-select>
                                            <small v-if="errors && errors['city']"
                                                class="text-right text-danger d-inline"><i
                                                    class="fa fa-exclamation-circle mx-2"></i>
                                                {{errors['city']}}
                                            </small>
                                        </div>
                                        <div class="col-lg-6" v-if="subCities.length>0 && city">
                                            <label for="sub_city">{{$t('sub_city')}}</label>
                                            <v-select v-model="sub_city" dir="rtl" label="name" :options="subCities">
                                            </v-select>
                                            <small v-if="errors && errors['sub_city']"
                                                class="text-right text-danger d-inline"><i
                                                    class="fa fa-exclamation-circle mx-2"></i>
                                                {{errors['sub_city']}}
                                            </small>
                                        </div>
                                        <div class="col-lg-6">
                                            <Input :title="$t('map')" v-model="map" :error-message="errors"
                                                name="map" type="text" />
                                            <div class="btn-group">
                                                <button @click="getUserLocation()" type="button"
                                                    class="btn btn-light text-primary btn-sm py-0">
                                                    إستخدم موقعي الحالي
                                                </button>
                                                <map-selector @change="mapChange"></map-selector>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <Input :title="$t('address')" v-model="address" :error-message="errors"
                                                name="address" type="text" />
                                        </div>
                                        
                                    </div>

                                    <Button :text="$t('create')" :disabled="isLoading || citiesLoading" :isLoading="isLoading || citiesLoading" type="submit" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from '../../components/public/Button.vue';
    import CheckBox from '../../components/public/CheckBox.vue';
    import Input from '../../components/public/Input.vue';
import Modal from '../../components/public/Modal.vue';
    import { totop } from '../../modules/helpers';
    import { instance } from "../../modules/instance";
    export default {
        components: { Input, Button, CheckBox, Modal },
        data() {
            return {
                name: "",
                phone: "",
                phone_b: "",
                map: "",
                marker : { lat: 32.625148937407864, lng: 17.414175040074806 },
                address: "",
                city: "",
                sub_city: "",
                description: "",
                subCities: [],
                isLoading: false,
                citiesLoading: false,
                cities: [],
                types: [],
                type : '',
                errors: {
                    name: "",
                    type:"",
                    phone: "",
                    phone_b: "",
                    map: "",
                    address: "",
                    city: "",
                    sub_city: "",
                    description: "",
                },
            };
        },
        methods: {
            async getUserLocation() {
                let _this = this;
                await navigator.geolocation.getCurrentPosition(function (position) {
                    let lat = position.coords.latitude;
                    let long = position.coords.longitude;
                    document.getElementById('map').value = `https://maps.google.com/?q=${lat},${long}`;
                    _this.map = `https://maps.google.com/?q=${lat},${long}`
                });
            },
            getCities() {
                let _this = this;
                _this.cities= [];
                _this.citiesLoading = true;
                instance
                    .get("/main-cities")
                    .then(function (response) {
                         let cities = response.data.data;
                         _this.cities = cities;
                    })
                    .catch(function () {

                    })
                    .then(function () {

                        _this.citiesLoading = false;

                    });
            },
            getTypes() {
                let _this = this;
                instance
                    .get("/user/info/types")
                    .then(function (response) {
                         _this.types = response.data.data;
                    });
            },
            setType(type) {
                this.description = type;
                document.getElementById('description').value = type;
            },
            createAddress(e) {
                e.preventDefault()
                let errors = 0;
                if (!this.name) {
                    errors++
                    this.errors['name'] = this.$t('required', { title: this.$t('name') })
                } else {
                    this.errors['name'] = ""
                }
                
                if (!this.description) {
                    errors++
                    this.errors['description'] = this.$t('required', { title: this.$t('description') })
                } else {
                    this.errors['description'] = ""
                }
                if (!this.phone) {
                    errors++
                    this.errors['phone'] = this.$t('required', { title: this.$t('phone') })
                } else {
                    this.errors['phone'] = ""
                }
                if (!this.map) {
                    errors++
                    this.errors['map'] = this.$t('required', { title: this.$t('map') })
                } else {
                    this.errors['map'] = ""
                }
                if (!this.address) {
                    errors++
                    this.errors['address'] = this.$t('required', { title: this.$t('address') })
                } else {
                    this.errors['address'] = ""
                }
                if (!this.city) {
                    errors++
                    this.errors['city'] = this.$t('required', { title: this.$t('city') })
                } else {
                    this.errors['city'] = ""

                    if ((this.city.id == 2 || this.city.id == 1) && !this.sub_city) {
                    errors++
                    this.errors['sub_city'] = this.$t('required', { title: this.$t('sub_city') })
                    } else {
                        this.errors['sub_city'] = ""
                    }
                }

                if (errors === 0) {
                    this.isLoading = true;
                    let _this = this;
                    let data = {
                        name: this.name,
                        phone: this.phone,
                        phone_b: this.phone_b,
                        map: this.map,
                        description: this.description,
                        city: this.city.id,
                        sub_city: (this.sub_city)? this.sub_city.id : null,
                        address: this.address,
                    }
                    instance
                        .post("/user/info", data)
                        .then(function (response) {
                            _this.$toasted.show(response.data.message, {
                                type: "success",
                            });
                            _this.$router.push({ path: '/addresses' })
                        })
                        .catch(function (error) {
                            totop()
                            if (error.response && error.response.data && error.response.data.errors) {
                                for (const [key, value] of Object.entries(error.response.data.errors)) {
                                    _this.errors[key] = value[0]
                                }
                            }
                        })
                        .then(function () {
                            _this.isLoading = false;
                        });
                } else {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                }
                

            },
            getSubCities() {
                let _this = this;
                let cityId = this.city.id;
                 _this.citiesLoading = true;
                 _this.sub_city = null;
                instance.get(`/main-cities/${cityId}`).then(function (response) {
                    let prices = response.data.data;
                    _this.subCities = prices;
                }).then(()=>{
                    _this.citiesLoading = false;
                })
            },
            mapChange(e){
                let _this = this;
                let data = `https://maps.google.com/?q=${e.marker.lat},${e.marker.lng}`;
                _this.map = data;
                document.getElementById('map').value = data;
            }
        },
        mounted() {
            this.getCities()
            this.getTypes()
        }
    };
</script>

<style></style>