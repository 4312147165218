import CryptoJS  from 'crypto-js'
const secret = 'J95gG3EqAJHMjQ2tTHTxaFN7sMPnynTqqFLRGddT'
function cryptText(text){
    try {
        return CryptoJS.AES.encrypt(text.toString(), secret).toString();
    } catch (error) {
        return ''
    }
}
function decryptText(encryptText){
    try {
        var bytes  = CryptoJS.AES.decrypt(encryptText.toString(), secret);
        return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        return ''
    }
}

export {cryptText , decryptText};