<template>
  <div class="container">
    <div class="row justify-content-center h-100">
      <div class="col-lg-12">
        <div class="card  ">

          <div class="card-body ">
           
           <form @submit="createTicket" novalidate>
              <div class="row justify-content-center">
                <div class="col-lg-12 bg-white p-4">

                  <div class="text-right">
                    <h4 class="text-vanex text-bold my-3">{{$t('new_ticket')}}</h4>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <small class="text-right text-muted">{{$t('for_team')}}</small>
                        <v-select v-model="category"  dir="rtl" label="title" :options="types"></v-select>
                        <small v-if="errors && errors['category']" class="text-right text-danger d-inline"><i class="fa fa-exclamation-circle mx-2"></i> 
                          {{errors['category']}}
                        </small>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <small class="text-right text-muted">{{$t('priority')}}</small>
                        <v-select v-model="priority" dir="rtl" label="title" :options="priorities"></v-select>
                        <small v-if="errors && errors['priority']" class="text-right text-danger d-inline"><i class="fa fa-exclamation-circle mx-2"></i> 
                          {{errors['priority']}}
                        </small>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <Input required="required" :title="$t('ticket_title')" v-model="title" :error-message="errors" name="title" type="text" />
                    </div>
                    <div class="col-lg-6">
                      <Input ltrvalue="1" required="required" :title="`${$t('code')} ( غير إلزامي )`" v-model="code" :error-message="errors" name="code" type="text" />
                    </div>
                    <div class="col-lg-12">
                      <Input required="required" :title="$t('subject')" v-model="subject" :error-message="errors" name="subject" type="text" />
                    </div>
                  </div>

                  <Button  :text="$t('create')" :isLoading="isLoading" type="submit"  />
                </div>
              </div>
           </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Button from '../../../components/public/Button.vue';
  import CheckBox from '../../../components/public/CheckBox.vue';
  import Input from '../../../components/public/Input.vue';
import { totop } from '../../../modules/helpers';
  import { instance } from "../../../modules/instance";
  export default {
    components: { Input, Button ,CheckBox},
    data() {
      return {
        types: [],
        priorities: [
          {key:'Low',title:'منخفضة'},
          {
            key:'Medium',
            title:'متوسطة',
          },
          {
            key:'High',
            title:'عالية',
          },
        ],
        priority:{ "key": "Low", "title": "منخفضة" },
        isLoading: false,
        title : '',
        priority : '',
        code : '',
        subject : '',
        category : null,
        errors: {
          title : '',
          subject : '',
          category : '',
          priority : '',
          code : '',
        },
      };
    },
    methods: {
      
      createTicket(e) {
       e.preventDefault()
        let errors = 0;

        if (!this.title) {
          errors++
          this.errors['title'] = this.$t('required', { title: this.$t('ticket_title') })
        } else {
          this.errors['title'] = ""
        }
        if (!this.subject) {
          errors++
          this.errors['subject'] = this.$t('required', { title: this.$t('subject') })
        } else {
          this.errors['subject'] = ""
        }
        if (!this.category) {
          errors++
          this.errors['category'] = this.$t('required', { title: this.$t('for_team') })
        } else {
          this.errors['category'] = ""
        }
        if (!this.priority) {
          errors++
          this.errors['priority'] = this.$t('required', { title: this.$t('priority') })
        } else {
          this.errors['priority'] = ""
        }
        

        if (errors === 0) {
          this.isLoading = true;
          let _this = this;
          let data = {
            title: this.title,
            code: this.code,
            category: `${this.category.id}`,
            subject: this.subject,
            priority: this.priority.key,
          }
          instance
            .post("/customer/tickets", data)
            .then(function (response) {
              _this.$toasted.show(response.data.message, {
                  type: "success",
                });
                _this.$router.push({path:'/tickets'})
            })
            .catch(function (error) {
              totop()
              if (error.response && error.response.data && error.response.data.errors) {
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                  _this.errors[key] = value[0]
                }
              }
            })
            .then(function () {
              _this.isLoading = false;
            });
        } else {
          totop()
        }
      },
      getTicketCategories() {
      let _this = this;
       _this.isLoading = true;
       instance
            .get("/customer/tickets/catagories")
            .then(function (response) {
              _this.types = response.data.data
            })
            .catch(function (error) {

            })
            .then(function () {
              _this.isLoading = false;
            });
      }
    },
    mounted(){
      this.getTicketCategories()
      this.priority = {key:'Low',title:'منخفضة'};
    }
  };
</script>

<style></style>