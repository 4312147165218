<template>
  <div class="col-lg-6">
    <div class="row">
      <div class="col-lg-12 col-md-12 h-100">
        <div>
          <div class="card shadow">
            <div class="card-header card-header-success">
              <h4 class="card-title">إحصائيات الشحنات الحالية</h4>
            </div>
            <div class="card-body table-responsive">
              <page-loading :show="chartLoading"></page-loading>
              <div class="chart-container customChartSize">
                <canvas id="shipments-chart"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 h-100">
        <div>
          <!-- <div class="card shadow p-0">
            <div class="card-header p-0">
              <router-link to="/hints">
                <img src="@/assets/images/hints-mobile.png"   class="card-img-top"  alt="" />
              </router-link>
            </div>
          </div> -->
          <!-- <div class="card shadow">
            <div class="card-header card-header-primary">
              <h4 class="card-title">البحث بكود الشحنة</h4>
            </div>
            <div class="card-body table-responsive">
              <div class="col-lg-12">
                <Input
                  :title="$t('package_code')"
                  v-model="package_code"
                  :error-message="errors"
                  name="package_code"
                  type="text"
                  ltrvalue="1"
                />
              </div>
              <div class="col-12">
                <Button
                  :text="$t('search')"
                  :isLoading="isBtnLoading"
                  @click="checkPackage()"
                ></Button>
              </div>
            </div>
          </div> -->
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import { decryptText } from "../modules/crypt";
import Input from "../components/public/Input.vue";
import Button from "../components/public/Button.vue";
import { instance } from "../modules/instance";
import { totop } from '../modules/helpers';
export default {
  name: "statistics",
  components: { Input, Button },
  data() {
    return {
      chartLoading: true,
      package_code: null,
      errors: {
        package_code: "",
      },
      isBtnLoading: false,
      shipmentsData: {
        labels: [
          "تحت التسوية",
          "بالمسار الصحيح",
          "المعتذر توصيلها",
          "قيد الإسترداد",
        ],
        datasets: [
          {
            label: "إحصائيات الشحنات",
            data: [0, 0, 0, 0],
            backgroundColor:["#1e87c1", "#2bafac", "#f98115", "#ff6470"],
            borderColor: ["#1e87c1", "#2bafac", "#f98115", "#ff6470"],
            borderWidth: 1,
          },
        ],
      },
    };
  },
  methods: {
    createChart(chartID, Data) {
      var ctx = document.getElementById(chartID);
      new Chart(ctx, {
        type: "pie",
        data: Data,
        options: {
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25,
                },
              },
            ],
          },
          legend: {
            labels: {
              // This more specific font property overrides the global property
              fontColor: "black",
              fontFamily: "Tajawal",
            },
          },
        },
      });
    },

    setChartData(n1, n2, n3, n4) {
      this.shipmentsData.datasets[0].data = [n1, n2, n3, n4];
    },
    checkPackage() {
      let errors = 0;
      if (!this.package_code) {
        errors++;
        this.errors["package_code"] = this.$t("required", {
          title: this.$t("package_code"),
        });
      } else {
        this.errors["package_code"] = "";
      }

      if (errors == 0) {
        let _this = this;
        _this.isBtnLoading = true;
        instance
          .get(`/customer/package/${this.package_code}/check`)
          .then(function (response) {
            if(!response.data.errors){
                _this.$router.push({path:`/packages/${_this.package_code}/show`})
                totop()
            }else{
              for (const [key, value] of Object.entries(
                response.data.errors
              )) {
                _this.errors[key] = value[0];
              }
              if (response.data.errors["message"]) {
                _this.$toasted.show(response.data.errors["message"], {
                  type: "warning",
                });
              }
            }
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.errors
            ) {
              for (const [key, value] of Object.entries(
                error.response.data.errors
              )) {
                _this.errors[key] = value[0];
              }
            }
          })
          .then(() => {
            _this.isBtnLoading = false;
          });
      }
    },
  },

  mounted() {
    setTimeout(() => {
      if(localStorage.getItem("scount")){
        let statisticsCount = JSON.parse(
          decryptText(localStorage.getItem("scount"))
        );
        this.setChartData(
          statisticsCount[3],
          statisticsCount[0],
          statisticsCount[2],
          statisticsCount[1]
        );
        this.createChart("shipments-chart", this.shipmentsData);  
      }
      this.chartLoading = false;
    }, 2000);
  },
};
</script>

<style>
.CustomChartSize {
  position: relative;
  max-width: 400px;
  max-height: 400px;
}
</style>