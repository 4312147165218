<template>
    <div dir="ltr">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <vanex-list-loading v-if="isLoading" :rowcount="8" />
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="card shadow" v-if="!isLoading && ticket">
                                    <div class="card-header card-header-primary ">
                                        <h4 class="card-title">{{$t('ticket_details')}}</h4>
                                    </div>
                                    <div class="card-body">
                                        <ol class="list-group list-group-numbered">
                                            <li class="list-group-item d-flex justify-content-between ">
                                                <div class="ms-2"><small>المتجر</small>
                                                    <div class="fw-bold"></div>
                                                </div>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between ">
                                                <div class="ms-2"><small>الزبون</small>
                                                    <div class="fw-bold">{{ticket.customer.name}}</div>
                                                </div>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between ">
                                                <div class="ms-2"><small>كود الشحنة</small>
                                                    <div class="fw-bold">{{ticket.package_code}}</div>
                                                </div>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between ">
                                                <div class="ms-2"><small> القسم</small>
                                                    <div class="fw-bold">{{ticket.category.title}}</div>
                                                </div>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between">
                                                <div class="ms-2"><small>الحالة</small>
                                                    <div class="fw-bold">{{ticket.status.title}}</div>
                                                </div>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between ">
                                                <div class="ms-2"><small>أخر رد</small>
                                                    <div class="fw-bold"><i class="fa fa-dot-circle text-dark"></i>
                                                        رد المشترك
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between">
                                                <div class="ms-2"><small>الأولوية</small>
                                                    <div class="fw-bold"><i class="fa fa-dot-circle"></i> 
                                                    <span v-if="ticket.priority == 'High'">{{$t('high')}}</span>
                                                    <span v-if="ticket.priority == 'Medium'">{{$t('medium')}}</span>
                                                    <span v-if="ticket.priority == 'Low'">{{$t('low')}}</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between ">
                                                <div class="ms-2"><small>تاريخ الإرسال</small> 
                                                    <div> <custom-date :date="ticket.created_at" /></div>
                                                </div>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between ">
                                                <div class="ms-2"><small>أخر تحديث</small> 
                                                    <div><custom-date :date="ticket.updated_at" /></div>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="card shadow" v-if="!isLoading && ticket">
                                    <div class="card-header card-header-primary ">
                                        <h4 class="card-title">{{$t('show_ticket',{id : id,title:ticket.title})}}</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="col-12">
                                            <div v-if="!isChatLoading" class="py-2 chat-box bg-white"
                                                id="chatContainer">
                                                <!-- Reciever Message-->
                                                <div class="media w-75 mb-3">
                                                    <div class="media-body">
                                                        <div class="bg-primary rounded py-2 px-3 mb-2">
                                                            <p class="text-small mb-0 text-white p-2">
                                                                {{ticket.desc}}
                                                            </p>
                                                        </div>
                                                        <small class="small text-muted">
                                                            <custom-date :date="ticket.created_at" />
                                                        </small>
                                                    </div>
                                                </div>
                                                <!-- Reciever Message-->
                                                <div v-for="reply in ticket.replies" :key="reply.id">
                                                    <!-- Sender Message-->
                                                    <div v-if="reply.user_id" class="media w-75 mb-3 mr-2 float-left">
                                                        <div class="media-body ml-3 text-left ">
                                                            <div
                                                                class="bg-vanex-light rounded text-left pt-2  pb-1 px-3 mb-0">
                                                                <div class="text-small mb-0 text-vanex   p-2"
                                                                    v-html="reply.reply"></div>
                                                            </div>
                                                            <small class="small text-muted text-left">
                                                                <custom-date :date="reply.created_at" />
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <!-- Sender Message-->
                                                    <!-- Reciever Message-->
                                                    <div v-else-if="reply.customer_id" class="media w-75 mb-3">
                                                        <div class="media-body">
                                                            <div class="bg-primary rounded pt-2 pb-1 px-3 mb-0">
                                                                <p class="text-small mb-0 text-white   p-2">
                                                                    {{reply.reply}}
                                                                </p>
                                                            </div>
                                                            <small class="small text-muted">
                                                                <custom-date :date="reply.created_at" />
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <!-- Reciever Message-->
                                                </div>
                                            </div>
                                            <div v-if="isChatLoading" class="py-2 chat-box bg-white">
                                                <vanex-list-loading :rowcount="8" />
                                            </div>

                                            <!-- Typing area -->

                                        </div>

                                        <div class="col-12 mt-2 p-2">
                                            <div class="input-group">
                                                <input autofocus type="text" :disabled="isReplyLoading || (ticket.status && ticket.status.id == 3)"
                                                    :placeholder="$t('write_reply')" v-on:keyup.enter="reply()"
                                                    v-model="replyMessage" aria-describedby="button-addon2"
                                                    class="form-control rounded-0 border-0 py-4 pl-2 bg-light">
                                                <div class="input-group-append">
                                                    <button id="button-addon2"
                                                        :disabled="!replyMessage || isReplyLoading" type="button"
                                                        @click="reply()" class="btn btn-link text-vanex">
                                                        <i v-if="!isReplyLoading" class="fa fa-paper-plane"></i>
                                                        <Spinner v-else :size="20" :type="1" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-2 p-2" v-if="ticket.status && ticket.status.id == 3">
                                            <alert message="هذة التذكرة مغلقة لا يمكن الرد عليها الا بإعادة فتحها من قبل المسؤول" type="vanex" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { totop } from '../../../modules/helpers';
    import { instance } from '../../../modules/instance';
    import Spinner from "../../../components/public/Spinner.vue";
    export default {
        components: { Spinner },
        data() {
            return {
                id: null,
                ticket: null,
                replyMessage: null,
                isChatLoading: false,
                isReplyLoading: false,
                isLoading: true,
            }
        },
        methods: {
            getTicketData() {

                let _this = this;
                _this.isLoading = true;
                totop()
                // TODO adding customer - user object in replies
                instance
                    .get(`/customer/tickets/${_this.id}`)
                    .then(function (response) {
                        let data = response.data.data;
                        _this.ticket = data;
                    })
                    .catch(function (error) {

                    })
                    .then(function () {
                        _this.isLoading = false;
                    }).finally(() => {
                        _this.goBottomOfChat()
                    })
            },
            goBottomOfChat() {
                var objDiv = document.getElementById("chatContainer");
                if (objDiv) {
                    // objDiv.scrollTop = objDiv.scrollHeight;
                    // objDiv.scrollTop = objDiv.scrollHeight - objDiv.clientHeight;
                    objDiv.scrollTo({
                        top: objDiv.scrollHeight - objDiv.clientHeight,
                        left: 100,
                        behavior: 'smooth'
                    });
                }
            },
            reply() {
                // TODO get replies for ticket api
                // TODO open ticket when customer reply
                this.isReplyLoading = true
                let _this = this;
                let data = {
                    message: this.replyMessage
                }
                instance
                    .post(`/customer/tickets/${_this.id}/reply`, data)
                    .then(function (response) {
                    })
                    .catch(function (error) {

                    })
                    .then(function () {
                        _this.getTicketReplies()
                        _this.isReplyLoading = false;
                        _this.replyMessage = null;
                    });
            },
            getTicketReplies() {
                if (!this.replyMessage) {
                    return false
                }
                let _this = this
                instance
                    .get(`/customer/tickets/${_this.id}`)
                    .then(function (response) {
                        let data = response.data.data;
                        _this.ticket.replies = data.replies;
                    })
                    .catch(function (error) {

                    })
                    .then(function () {
                        _this.isLoading = false;
                    }).finally(() => {
                        _this.goBottomOfChat()
                    })
            }
        },
        mounted() {

            if (!this.$route.params.id) {
                this.$router.push({ path: '/tickets' })
            }
            this.id = this.$route.params.id
            this.getTicketData()
        }
    }
</script>

<style>
    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        width: 5px;
        background: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
        width: 1em;
        background-color: #ddd;
        outline: 1px solid slategrey;
        border-radius: 1rem;
    }

    .text-small {
        font-size: 0.9rem;
    }

    .messages-box,
    .chat-box {
        min-height: 510px;
        max-height: 510px;
        overflow-y: scroll;
    }

    .rounded-lg {
        border-radius: 0.5rem;
    }

    input::placeholder {
        font-size: 0.9rem;
        color: #999;
    }
</style>