<template>
  <div class="card shadow">
    <div class="card-header card-header-primary">
      <h4 class="card-title">
        <span v-if="user && user.type == 1">الدعوات المرسلة</span>
        <span v-if="user && user.type == 2">الدعوات المستقبلة</span>
      </h4>
    </div>
    <div class="card-body">
      <div class="row justify-content-center">
        <div class="col-lg-12 col-md-12" v-if="rows && !isLoading">
          <div class="table-responsive">
            <vue-good-table :columns="columns" :rows="rows" :rtl="true" styleClass="vgt-table striped"
              :pagination-options="{
                enabled: true,
                mode: 'pages',
                perPageDropdownEnabled: false,
                perPage: 5,
                position: 'bottom',
                perPageDropdown: [3, 7, 9],
                dropdownAllowAll: true,
                setCurrentPage: 1,
                nextLabel: 'التالي',
                prevLabel: 'السابق',
                rowsPerPageLabel: 'عدد الصفوف في الصفحة',
                ofLabel: 'من',
                pageLabel: 'صفحة',
                allLabel: 'الكل',
              }" :search-options="{
                enabled: false,
                placeholder: 'ابحث من هنا...',
              }" :sort-options="{
                enabled: true,
              }">
              <template slot="table-row" slot-scope="props">
                <slot :props="props" />

                <div v-if="props.column.field == 'status'">
                  <span v-if="props.row.status == 'pending'" class="text-warning">بإنتظار الموافقة</span>
                  <span v-if="props.row.status == 'canceled'" class="text-danger">ملغية</span>
                  <span v-if="props.row.status == 'accepted'" class="text-success">تمت الموافقة</span>
                  <span v-if="props.row.status == 'rejected'" class="text-danger">مرفوض</span>
                </div>
                <div v-else-if="props.column.field == 'order'">
                  <small>{{props.row.originalIndex+1}}</small>
                </div>
                <div v-else-if="props.column.field == 'notes'">
                  <small>{{ props.row.notes }}</small>
                </div>
                <div v-else-if="props.column.field == 'inviter'">
                  <small>{{ props.row.inviter ? props.row.inviter.name : '' }}</small>
                </div>
                <div v-else-if="props.column.field == 'user'">
                  <small>{{ props.row.user ? props.row.user.name : '' }}</small>
                </div>
                <div v-else-if="props.column.field == 'store'">
                  <small>{{ props.row.store ? props.row.store.name : '' }}</small>
                </div>
                <div v-else-if="props.column.field == 'created_at'">
                  <custom-date :date="props.row.created_at"></custom-date>
                </div>
                <div v-else-if="props.column.field == 'settings'">
                  <div v-if="user && user.type ==1">
                    <Modal v-if="props.row.status=='pending'" :id="`canceled_invite_${props.row.id}`"
                      :title="$t('canceled_invite')" type="danger" size="small">
                      <a slot="btn"><span class="fa fa-trash"></span> </a>
                      <div slot="body">
                        <Button btn-type="danger" type="button" block spinner_size="20" :text="$t('confirm')"
                          :isLoading="isBtnInviteLoading" @click="canceledInvite(props.row.id)" />
                        <a class="btn btn-block btn-secondary btn-sm text-dark" data-dismiss="modal">{{
                          $t("close") }}</a>
                      </div>
                    </Modal>
                  </div>
                  <div v-if="user && user.type == 2">
                    <Modal v-if="props.row.status=='pending'" :id="`rejected_invite_${props.row.id}`"
                      :title="$t('reject_invite')" type="danger" size="small">
                      <a slot="btn"><span class="fa fa-trash"></span> </a>
                      <div slot="body">
                        <Button btn-type="danger" type="button" block spinner_size="20" :text="$t('confirm')"
                          :isLoading="isBtnInviteLoading" @click="rejectInvite(props.row.id)" />
                        <a class="btn btn-block btn-secondary btn-sm text-dark" data-dismiss="modal">{{
                          $t("close") }}</a>
                      </div>
                    </Modal>
                    <Modal v-if="props.row.status=='pending'" :id="`accepted_invite_${props.row.id}`"
                      :title="$t('accept_invite')" type="success" size="small">
                      <a slot="btn"><span class="fa fa-check"></span> </a>
                      <div slot="body">
                        <Button btn-type="success" type="button" block spinner_size="20" :text="$t('confirm')"
                          :isLoading="isBtnInviteLoading" @click="acceptInvite(props.row.id)" />
                        <a class="btn btn-block btn-secondary btn-sm text-dark" data-dismiss="modal">{{
                          $t("close") }}</a>
                      </div>
                    </Modal>
                  </div>
                </div>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <div slot="table-actions" v-if="user && user.type == 1">
                <Modal id="send_invite" :title="$t('new_invite')" type="primary" size="small">
                  <a slot="btn"><span class="fa fa-plus"></span> إرسال طلب الإضافة </a>
                  <div slot="body">
                    <Input :title="$t('email')" v-model="email" :error-message="errors" name="email" type="email" />
                    <Button btn-type="primary" type="button" block spinner_size="20" :text="$t('send')"
                      :isLoading="isBtnInviteLoading" @click="sendInvite()" />
                    <a class="btn btn-block btn-secondary btn-sm text-dark" data-dismiss="modal">{{
                      $t("close") }}</a>
                  </div>
                </Modal>
              </div>
            </vue-good-table>
          </div>
        </div>
        <div class="col-12" v-if="isLoading">
          <vanex-list-loading :rowcount="10" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Modal from "../components/public/Modal.vue";
  import Spinner from "../components/public/Spinner.vue";
  import Button from "../components/public/Button.vue";
  import Input from "../components/public/Input.vue";
  import { instance } from "../modules/instance";
import { cryptText, decryptText } from '../modules/crypt';
  export default {
    components: { Spinner, Modal, Button ,Input},
    data: function () {
      return {
        user: null,
        email: null,
        columns: [
          {
            label: "#",
            field: "order",
          },
          {
            label: "الداعي",
            field: "inviter",
          },
          {
            label: "المستخدم",
            field: "user",
          },
          {
            label: "المتجر",
            field: "store",
          },
          {
            label: "الحالة",
            field: "status",
          },
          {
            label: "تاريخ الإضافة",
            field: "created_at",
          },
          {
            label: "إعدادات",
            field: "settings",
          },
        ],
        rows: [],
        errors : {
          email:null,
        },
        isLoading: true,
        isBtnInviteLoading: false,
      };
    },
    methods: {
      sendInvite() {
        let errors = 0;
        if (!this.email) {
          errors++;
          this.errors["email"] = this.$t("required", {
            title: this.$t("email"),
          });
        } else {
          this.errors["email"] = "";
        }
        if (errors <= 0) {
          let _this = this;

          _this.isBtnInviteLoading = true;
          const fd = new FormData();
          fd.append("email", this.email);
          instance
            .post("/customer/invites", fd)
            .then(function (response) {
              if (!response.data.errors) {
                _this.$toasted.show(response.data.message, {
                  type: "success",
                });
                $("#send_invite").modal("hide");
                _this.email = null;
                document.getElementById('email').value = '';
                
                _this.getInvites();
              } else if (response.data.errors && response.data.message && response.data.errors == true) {
                _this.errors['email'] = response.data.message
              } else {
                for (const [key, value] of Object.entries(
                  response.data.errors
                )) {
                  _this.errors[key] = value[0];
                }
              }
            })
            .catch(function (error) {
              _this.errors['email'] = error.response.data.message
            })
            .then(function () {
              _this.isBtnInviteLoading = false;
              
            });
        }
      },
      canceledInvite(id) {
        let _this = this;
        this.isBtnInviteLoading = true;
        instance
          .post("/customer/invites/" + id + "/cancel")
          .then(function (response) {
            _this.$toasted.show(response.data.message, {
              type: "success",
            });
          })
          .catch(function (error) {
            _this.$toasted.show('توجد مشكلة عند إلغاء دعوة المستخدم', {
              type: "danger",
            });
          })
          .then(function () {
            _this.isBtnInviteLoading = false;
            $(`#canceled_invite_${id}`).modal("hide");
            _this.getInvites();
          });

      },
      rejectInvite(id) {
        let _this = this;
        this.isBtnInviteLoading = true;
        instance
          .post("/customer/invites/" + id + "/reject")
          .then(function (response) {
            _this.$toasted.show(response.data.message, {
              type: "success",
            });
          })
          .catch(function (error) {
            _this.$toasted.show('توجد مشكلة عند إلغاء دعوة المستخدم', {
              type: "danger",
            });
          })
          .then(function () {
            _this.isBtnInviteLoading = false;
            $(`#rejected_invite_${id}`).modal("hide");
            _this.getInvites();
          });

      },
      acceptInvite(id) {
        let _this = this;
        this.isBtnInviteLoading = true;
        instance
          .post("/customer/invites/" + id + "/accept")
          .then(function (response) {
            _this.$toasted.show(response.data.message, {
              type: "success",
            });
          })
          .catch(function (error) {
            _this.$toasted.show('توجد مشكلة عند إلغاء دعوة المستخدم', {
              type: "danger",
            });
          })
          .then(async function () {
            _this.isBtnInviteLoading = false;
            $(`#accepted_invite_${id}`).modal("hide");
            // _this.getInvites();
            let user = JSON.parse(decryptText(await localStorage.getItem("user")));
                                await localStorage.removeItem("user");
                                if(user){
                                 user.type = 1;  
                                 await localStorage.setItem("user",cryptText(JSON.stringify(user)));
                                }
                                _this.$router.push({path:"/success-accept-store"})
          });

      },
      getInvites() {
        let _this = this;
        if (this.user.type == 1) {
          instance
            .get("/customer/invites/sent")
            .then(function (response) {
              _this.rows = [];
              _this.rows = response.data.data;
            })
            .then(async function () {          
              _this.isLoading = false;
            });
        } else if (this.user.type == 2) {
          instance
            .get("/customer/invites/received")
            .then(function (response) {
              _this.rows = response.data.data;
            })
            .then(function () {
              _this.isLoading = false;
            });
        }


      }
    },
    async mounted() {
      let user = await this.$store.state.auth.user;
      this.user = user;
      if (this.user) {
        this.getInvites();
      }
    },
  };
</script>

<style>
</style>