<template>
    <div class="card shadow">
        <div class="card-header card-header-primary">
             <h4 class="card-title">{{$t('addresses')}}</h4>
        </div>
        <div class="card-body">
            <div class="row justify-content-center">
                <div class="col-lg-12 col-md-12" v-if="rows && !isLoading">
                    <div class="table-responsive">
                        <vue-good-table :columns="columns" :rows="rows" :rtl="true" styleClass="vgt-table striped"
                            :pagination-options="{
                                enabled: true,
                                mode: 'pages',
                                perPageDropdownEnabled: false,
                                perPage: 5,
                                position: 'bottom',
                                perPageDropdown: [3, 7, 9],
                                dropdownAllowAll: true,
                                setCurrentPage: 1,
                                nextLabel: 'التالي',
                                prevLabel: 'السابق',
                                rowsPerPageLabel: 'عدد الصفوف في الصفحة',
                                ofLabel: 'من',
                                pageLabel: 'صفحة',
                                allLabel: 'الكل',
                            }" :search-options="{
                                enabled: true,
                                placeholder: 'ابحث من هنا...',
                            }" :sort-options="{
                                enabled: true,
                            }"
                             
                            >
                            <template slot="table-row" slot-scope="props">
                                <slot :props="props" />
                                <div v-if="props.column.field == 'setting'" class="mb-0">
                                    <div class="btn-group" role="group" aria-label="">
                                        <Modal :id="`address_share_modal_${props.row.code}`"
                                            :title="$t('address_share')" type="link" size="small">
                                            <a slot="btn" class="text-info"><i class="material-icons">share</i> </a>
                                            <div slot="body">
                                                <div class="text-center">
                                                    <vue-qr :text="props.row.code"  :qid="`qr-${props.row.code}`"></vue-qr>
                                                    <h4 dir="ltr" class="mt-2 text-vanex">{{props.row.code}}</h4>
                                                </div>
                                                <button @click="shareAddress(props.row.code)" class="btn btn-vanex btn-sm btn-block"><i class="fa fa-send text-small"></i> {{$t('share')}} </button>
                                                <a class="btn btn-block btn-secondary btn-sm text-dark"
                                                    data-dismiss="modal">{{$t('close')}}</a>
                                            </div>
                                        </Modal>
                                        <router-link :to="`/addresses/${props.row.code}/edit`" ><button type="button"
                                                class="btn btn-link text-primary ">
                                                <i class="material-icons">border_color</i>
                                            </button>
                                        </router-link>
                                        <Modal :id="`delete_modal_${props.row.code}`"
                                            :title="$t('address_delete_confirm')" type="link" size="small">
                                            <a slot="btn" class="text-danger"><i class="material-icons">remove_circle_outline</i> </a>
                                            <div slot="body">
                                                <button @click="deleteAddress(props.row.code)" class="btn btn-danger btn-sm btn-block">{{$t('confirm')}}</button>
                                                <a class="btn btn-block btn-secondary btn-sm text-dark"
                                                    data-dismiss="modal">{{$t('close')}}</a>
                                            </div>
                                        </Modal>
                                        <Modal v-if="props.row.is_default!=1" :id="`default_modal_${props.row.code}`"
                                            :title="$t('address_checked_confirm')" type="link" size="small">
                                            <a slot="btn" class="text-success"><i class="material-icons">check</i> </a>
                                            <div slot="body">
                                                <button @click="setDefaultAddress(props.row.code)" class="btn btn-success btn-sm btn-block">{{$t('confirm')}}</button>
                                                <a class="btn btn-block btn-secondary btn-sm text-dark"
                                                    data-dismiss="modal">{{$t('close')}}</a>
                                            </div>
                                        </Modal>
                                        
                                    </div>
                                </div>

                                <div v-else-if="props.column.field == 'active'">
                                    <div v-if="props.row.active == 1" class="text-right">
                                        <i class="material-icons text-success">task_alt</i>
                                    </div>
                                    <div v-if="props.row.active == 0" class="text-right">
                                        <i class="material-icons text-danger">remove_circle_outline</i>
                                    </div>
                                </div>
                                <div v-else-if="props.column.field == 'map'">
                                    <div v-if="props.row.map !== null && props.row.map !== ''">
                                        <a :href="props.row.map" target="_blank" rel="noopener noreferrer"><i
                                                class="material-icons text-success">remove_red_eye</i></a>
                                    </div>
                                </div>
                                <div v-else-if="props.column.field == 'notes'">
                                    <small>{{ props.row.notes }}</small>
                                </div>
                                <div v-else-if="props.column.field == 'code'">
                                    <strong dir="ltr">{{ props.row.code }}</strong>
                                </div>
                                <span v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
                <div class="col-12" v-if="isLoading">
                    <vanex-list-loading  :rowcount="10" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Modal from '../../components/public/Modal.vue';
import Spinner from "../../components/public/Spinner.vue";
import QRCode from "qrcode";
import { instance } from "../../modules/instance";
import { shareAddress } from '../../modules/helpers';
    export default {
        components: { Spinner, Modal },
        data: function () {
            return {
                columns: [
                    {
                        label: "كود العنوان",
                        field: "code",
                    },
                    {
                        label: "الوصف",
                        field: "description",
                    },
                    {
                        label: "اسم المستلم",
                        field: "name",
                    },
                    {
                        label: "الهاتف",
                        field: "phone",
                    },
                    {
                        label: "الهاتف الإحتياطي",
                        field: "phone_b",
                    },
                    {
                        label: "المدينة",
                        field: "city",
                    },
                    {
                        label: "المنطقة",
                        field: "address",
                    },
                    {
                        label: "الخريطة",
                        field: "map",
                    },
                    {
                        label: "الحالة",
                        field: "active",
                    },
                    {
                        label: "الإعدادات",
                        field: "setting",
                    },
                ],
                rows:[],
                isLoading : true,
            }
        },
        methods:{
            getUserAddresses(){
                let _this = this;
                 _this.isLoading = true;
                 _this.rows = [];
                instance.get(`/user/info`).then(function (response) {
                    let addresses = response.data.data;
                    addresses.forEach(address => {
                        _this.rows.push({
                            code : address.code,
                            name : address.name,
                            phone : address.phone,
                            description : address.description,
                            phone_b : address.phone_b,
                            phone_b : address.phone_b,
                            city : address.city ? address.city.name : '',
                            address : address.address,
                            map : address.map,
                            active : address.active,
                            is_default : address.is_default,
                            setting : address.setting,
                        })
                    });
                }).then(()=>{
                    _this.isLoading = false;
                })
            },
            deleteAddress(code){
                $(`#delete_modal_${code}`).modal("hide");
                 let splitCode = code.split('-')
                 let id = splitCode[splitCode.length-1]
                 let _this = this
                  _this.isLoading = true;
                instance.delete(`/user/info/${code}`).then(function (response) {
                }).then(()=>{
                    _this.isLoading = false;
                    _this.getUserAddresses()
                    _this.$toasted.show(this.$t("address_delete_success_msg"), {
                     type: "success",
                    });
                }).catch((err)=>{
                    _this.isLoading = false;
                })
                
            },
            setDefaultAddress(code){
                $(`#default_modal_${code}`).modal("hide");
                 let splitCode = code.split('-')
                 let id = splitCode[splitCode.length-1]
                 let _this = this
                  _this.isLoading = true;
                instance.patch(`/user/info/${code}/set-default`).then(function (response) {
                     _this.$toasted.show(response.data.message, {
                     type: "success",
                    });
                }).then(()=>{
                    _this.isLoading = false;
                    _this.getUserAddresses()
                   
                }).catch((err)=>{
                    _this.isLoading = false;
                })
                
            },
            
            shareAddress:shareAddress
        },
        mounted(){
            this.getUserAddresses()
        }
    }
</script>

<style>

</style>